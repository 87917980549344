@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;600&family=Montserrat:wght@600&display=swap');

.inQuest-header-toolbar {
    background-color: #002554 !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    a {
        color: #fff !important;
    }
}

.horizontal-menu {
    background-color: transparent !important;
}

.rb-nav-main {
    background-color: #004c97 !important;
    
    span {
        color: #fff !important;
        font-family: 'Barlow', sans-serif;
    }
}

.rb-sub-menu {
    background-color: #999999 !important;
}

.rb-button {
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    background-color: #004c97 !important;
    color: #fff !important;
    border: none !important;
    cursor: pointer;

    /* Sizing */
}

.rb-session-info {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
        color: #ccc !important;
        display: block;
    }
}

.rb-icon {
    background: url("../img/icons/icon_user.png");
    background-position: center;
    background-size: contain;

    height: 20px;
    width: 20px;

    margin-right: 2px;
}

.rb-bg-image {
    background-image: url("../rebrand/bg-inquest-blue.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}

.rb-version-info {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    
    p {
        color: #ccc;
        margin: 0;
        text-align: end;
    }
}

.rb-center-card {
    position: relative;
    top: 15%;
}

.rb-rounded {
    border-radius: 5px;
}

/* Product Landing Pages */
.rb-product-landing {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Bootstrap/Component Restyles */
.card {
    background-color: #ECF0F3;
}

/* Inquest Navigation */
.rb-nav-item {
    min-height: 50px;
    width: 100%;
    background-color: #cccccc;

    /* Sizing */
    display: flex;
    justify-content: left;
    align-items: center;

    // Font
    font-size: 1.5rem;
    font-weight: bolder;

    // UX
    user-select: none;
}

.rb-nav-icon {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #002554;
}

.rb-nav-title {
    margin-left: 10px;
}

.rb-panel-override {
    padding: 0px !important;
    width: 100% !important;
}

.rb-side-bg {
    background-color: #cccccc;
}

/* Old */
.side-nav-base {
    border-radius: 0px !important;  
    width: 100% !important;

    
    box-shadow: none !important;
}

.side-nav-title {
    color: #002554;
    font-size: 16px;
    font-weight: 700;
}

.dont-open:active  {
    pointer-events: none;
    cursor: default;
}

.rb-button {
    outline: 1px solid #fff !important;
}

/* Test Chain/Suite Rebrand */
.rb-test-container {
    width: 100%;
}

.rb-test-ribbon {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.rb-button-list {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-self: flex-end;
}


.rb-text-device-request {
    color: #cccccc !important;
}