@use '~@angular/material' as mat;
.mat-red { background-color: mat.get-color-from-palette(mat.$red-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 500)!important;}
.mat-red-50 { background-color: mat.get-color-from-palette(mat.$red-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 50)!important;}
.mat-red-100 { background-color: mat.get-color-from-palette(mat.$red-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 100)!important;}
.mat-red-200 { background-color: mat.get-color-from-palette(mat.$red-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 200)!important;}
.mat-red-300 { background-color: mat.get-color-from-palette(mat.$red-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 300)!important;}
.mat-red-400 { background-color: mat.get-color-from-palette(mat.$red-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 400)!important;}
.mat-red-500 { background-color: mat.get-color-from-palette(mat.$red-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 500)!important;}
.mat-red-600 { background-color: mat.get-color-from-palette(mat.$red-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 600)!important;}
.mat-red-700 { background-color: mat.get-color-from-palette(mat.$red-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 700)!important;}
.mat-red-800 { background-color: mat.get-color-from-palette(mat.$red-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 800)!important;}
.mat-red-900 { background-color: mat.get-color-from-palette(mat.$red-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, 900)!important;}
.mat-red-A100 { background-color: mat.get-color-from-palette(mat.$red-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, A100)!important;}
.mat-red-A200 { background-color: mat.get-color-from-palette(mat.$red-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, A200)!important;}
.mat-red-A400 { background-color: mat.get-color-from-palette(mat.$red-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, A400)!important;}
.mat-red-A700 { background-color: mat.get-color-from-palette(mat.$red-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$red-palette, A700)!important;}

.mat-pink { background-color: mat.get-color-from-palette(mat.$pink-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 500)!important;}
.mat-pink-50 { background-color: mat.get-color-from-palette(mat.$pink-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 50)!important;}
.mat-pink-100 { background-color: mat.get-color-from-palette(mat.$pink-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 100)!important;}
.mat-pink-200 { background-color: mat.get-color-from-palette(mat.$pink-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 200)!important;}
.mat-pink-300 { background-color: mat.get-color-from-palette(mat.$pink-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 300)!important;}
.mat-pink-400 { background-color: mat.get-color-from-palette(mat.$pink-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 400)!important;}
.mat-pink-500 { background-color: mat.get-color-from-palette(mat.$pink-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 500)!important;}
.mat-pink-600 { background-color: mat.get-color-from-palette(mat.$pink-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 600)!important;}
.mat-pink-700 { background-color: mat.get-color-from-palette(mat.$pink-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 700)!important;}
.mat-pink-800 { background-color: mat.get-color-from-palette(mat.$pink-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 800)!important;}
.mat-pink-900 { background-color: mat.get-color-from-palette(mat.$pink-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, 900)!important;}
.mat-pink-A100 { background-color: mat.get-color-from-palette(mat.$pink-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, A100)!important;}
.mat-pink-A200 { background-color: mat.get-color-from-palette(mat.$pink-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, A200)!important;}
.mat-pink-A400 { background-color: mat.get-color-from-palette(mat.$pink-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, A400)!important;}
.mat-pink-A700 { background-color: mat.get-color-from-palette(mat.$pink-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$pink-palette, A700)!important;}

.mat-purple { background-color: mat.get-color-from-palette(mat.$purple-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 500)!important;}
.mat-purple-50 { background-color: mat.get-color-from-palette(mat.$purple-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 50)!important;}
.mat-purple-100 { background-color: mat.get-color-from-palette(mat.$purple-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 100)!important;}
.mat-purple-200 { background-color: mat.get-color-from-palette(mat.$purple-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 200)!important;}
.mat-purple-300 { background-color: mat.get-color-from-palette(mat.$purple-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 300)!important;}
.mat-purple-400 { background-color: mat.get-color-from-palette(mat.$purple-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 400)!important;}
.mat-purple-500 { background-color: mat.get-color-from-palette(mat.$purple-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 500)!important;}
.mat-purple-600 { background-color: mat.get-color-from-palette(mat.$purple-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 600)!important;}
.mat-purple-700 { background-color: mat.get-color-from-palette(mat.$purple-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 700)!important;}
.mat-purple-800 { background-color: mat.get-color-from-palette(mat.$purple-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 800)!important;}
.mat-purple-900 { background-color: mat.get-color-from-palette(mat.$purple-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, 900)!important;}
.mat-purple-A100 { background-color: mat.get-color-from-palette(mat.$purple-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, A100)!important;}
.mat-purple-A200 { background-color: mat.get-color-from-palette(mat.$purple-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, A200)!important;}
.mat-purple-A400 { background-color: mat.get-color-from-palette(mat.$purple-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, A400)!important;}
.mat-purple-A700 { background-color: mat.get-color-from-palette(mat.$purple-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$purple-palette, A700)!important;}

.mat-deep-purple { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 500)!important;}
.mat-deep-purple-50 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 50)!important;}
.mat-deep-purple-100 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 100)!important;}
.mat-deep-purple-200 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 200)!important;}
.mat-deep-purple-300 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 300)!important;}
.mat-deep-purple-400 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 400)!important;}
.mat-deep-purple-500 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 500)!important;}
.mat-deep-purple-600 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 600)!important;}
.mat-deep-purple-700 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 700)!important;}
.mat-deep-purple-800 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 800)!important;}
.mat-deep-purple-900 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, 900)!important;}
.mat-deep-purple-A100 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, A100)!important;}
.mat-deep-purple-A200 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, A200)!important;}
.mat-deep-purple-A400 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, A400)!important;}
.mat-deep-purple-A700 { background-color: mat.get-color-from-palette(mat.$deep-purple-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$deep-purple-palette, A700)!important;}

.mat-indigo { background-color: mat.get-color-from-palette(mat.$indigo-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 500)!important;}
.mat-indigo-50 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 50)!important;}
.mat-indigo-100 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 100)!important;}
.mat-indigo-200 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 200)!important;}
.mat-indigo-300 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 300)!important;}
.mat-indigo-400 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 400)!important;}
.mat-indigo-500 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 500)!important;}
.mat-indigo-600 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 600)!important;}
.mat-indigo-700 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 700)!important;}
.mat-indigo-800 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 800)!important;}
.mat-indigo-900 { background-color: mat.get-color-from-palette(mat.$indigo-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, 900)!important;}
.mat-indigo-A100 { background-color: mat.get-color-from-palette(mat.$indigo-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, A100)!important;}
.mat-indigo-A200 { background-color: mat.get-color-from-palette(mat.$indigo-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, A200)!important;}
.mat-indigo-A400 { background-color: mat.get-color-from-palette(mat.$indigo-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, A400)!important;}
.mat-indigo-A700 { background-color: mat.get-color-from-palette(mat.$indigo-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$indigo-palette, A700)!important;}

.mat-blue { background-color: mat.get-color-from-palette(mat.$blue-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 500)!important;}
.mat-blue-50 { background-color: mat.get-color-from-palette(mat.$blue-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 50)!important;}
.mat-blue-100 { background-color: mat.get-color-from-palette(mat.$blue-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 100)!important;}
.mat-blue-200 { background-color: mat.get-color-from-palette(mat.$blue-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 200)!important;}
.mat-blue-300 { background-color: mat.get-color-from-palette(mat.$blue-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 300)!important;}
.mat-blue-400 { background-color: mat.get-color-from-palette(mat.$blue-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 400)!important;}
.mat-blue-500 { background-color: mat.get-color-from-palette(mat.$blue-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 500)!important;}
.mat-blue-600 { background-color: mat.get-color-from-palette(mat.$blue-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 600)!important;}
.mat-blue-700 { background-color: mat.get-color-from-palette(mat.$blue-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 700)!important;}
.mat-blue-800 { background-color: mat.get-color-from-palette(mat.$blue-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 800)!important;}
.mat-blue-900 { background-color: mat.get-color-from-palette(mat.$blue-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, 900)!important;}
.mat-blue-A100 { background-color: mat.get-color-from-palette(mat.$blue-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, A100)!important;}
.mat-blue-A200 { background-color: mat.get-color-from-palette(mat.$blue-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, A200)!important;}
.mat-blue-A400 { background-color: mat.get-color-from-palette(mat.$blue-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, A400)!important;}
.mat-blue-A700 { background-color: mat.get-color-from-palette(mat.$blue-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$blue-palette, A700)!important;}

.mat-light-blue { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 500)!important;}
.mat-light-blue-50 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 50)!important;}
.mat-light-blue-100 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 100)!important;}
.mat-light-blue-200 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 200)!important;}
.mat-light-blue-300 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 300)!important;}
.mat-light-blue-400 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 400)!important;}
.mat-light-blue-500 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 500)!important;}
.mat-light-blue-600 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 600)!important;}
.mat-light-blue-700 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 700)!important;}
.mat-light-blue-800 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 800)!important;}
.mat-light-blue-900 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, 900)!important;}
.mat-light-blue-A100 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, A100)!important;}
.mat-light-blue-A200 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, A200)!important;}
.mat-light-blue-A400 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, A400)!important;}
.mat-light-blue-A700 { background-color: mat.get-color-from-palette(mat.$light-blue-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$light-blue-palette, A700)!important;}

.mat-cyan { background-color: mat.get-color-from-palette(mat.$cyan-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 500)!important;}
.mat-cyan-50 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 50)!important;}
.mat-cyan-100 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 100)!important;}
.mat-cyan-200 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 200)!important;}
.mat-cyan-300 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 300)!important;}
.mat-cyan-400 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 400)!important;}
.mat-cyan-500 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 500)!important;}
.mat-cyan-600 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 600)!important;}
.mat-cyan-700 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 700)!important;}
.mat-cyan-800 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 800)!important;}
.mat-cyan-900 { background-color: mat.get-color-from-palette(mat.$cyan-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, 900)!important;}
.mat-cyan-A100 { background-color: mat.get-color-from-palette(mat.$cyan-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, A100)!important;}
.mat-cyan-A200 { background-color: mat.get-color-from-palette(mat.$cyan-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, A200)!important;}
.mat-cyan-A400 { background-color: mat.get-color-from-palette(mat.$cyan-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, A400)!important;}
.mat-cyan-A700 { background-color: mat.get-color-from-palette(mat.$cyan-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$cyan-palette, A700)!important;}

.mat-teal { background-color: mat.get-color-from-palette(mat.$teal-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 500)!important;}
.mat-teal-50 { background-color: mat.get-color-from-palette(mat.$teal-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 50)!important;}
.mat-teal-100 { background-color: mat.get-color-from-palette(mat.$teal-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 100)!important;}
.mat-teal-200 { background-color: mat.get-color-from-palette(mat.$teal-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 200)!important;}
.mat-teal-300 { background-color: mat.get-color-from-palette(mat.$teal-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 300)!important;}
.mat-teal-400 { background-color: mat.get-color-from-palette(mat.$teal-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 400)!important;}
.mat-teal-500 { background-color: mat.get-color-from-palette(mat.$teal-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 500)!important;}
.mat-teal-600 { background-color: mat.get-color-from-palette(mat.$teal-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 600)!important;}
.mat-teal-700 { background-color: mat.get-color-from-palette(mat.$teal-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 700)!important;}
.mat-teal-800 { background-color: mat.get-color-from-palette(mat.$teal-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 800)!important;}
.mat-teal-900 { background-color: mat.get-color-from-palette(mat.$teal-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, 900)!important;}
.mat-teal-A100 { background-color: mat.get-color-from-palette(mat.$teal-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, A100)!important;}
.mat-teal-A200 { background-color: mat.get-color-from-palette(mat.$teal-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, A200)!important;}
.mat-teal-A400 { background-color: mat.get-color-from-palette(mat.$teal-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, A400)!important;}
.mat-teal-A700 { background-color: mat.get-color-from-palette(mat.$teal-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$teal-palette, A700)!important;}

.mat-green { background-color: mat.get-color-from-palette(mat.$green-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 500)!important;}
.mat-green-50 { background-color: mat.get-color-from-palette(mat.$green-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 50)!important;}
.mat-green-100 { background-color: mat.get-color-from-palette(mat.$green-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 100)!important;}
.mat-green-200 { background-color: mat.get-color-from-palette(mat.$green-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 200)!important;}
.mat-green-300 { background-color: mat.get-color-from-palette(mat.$green-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 300)!important;}
.mat-green-400 { background-color: mat.get-color-from-palette(mat.$green-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 400)!important;}
.mat-green-500 { background-color: mat.get-color-from-palette(mat.$green-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 500)!important;}
.mat-green-600 { background-color: mat.get-color-from-palette(mat.$green-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 600)!important;}
.mat-green-700 { background-color: mat.get-color-from-palette(mat.$green-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 700)!important;}
.mat-green-800 { background-color: mat.get-color-from-palette(mat.$green-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 800)!important;}
.mat-green-900 { background-color: mat.get-color-from-palette(mat.$green-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, 900)!important;}
.mat-green-A100 { background-color: mat.get-color-from-palette(mat.$green-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, A100)!important;}
.mat-green-A200 { background-color: mat.get-color-from-palette(mat.$green-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, A200)!important;}
.mat-green-A400 { background-color: mat.get-color-from-palette(mat.$green-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, A400)!important;}
.mat-green-A700 { background-color: mat.get-color-from-palette(mat.$green-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$green-palette, A700)!important;}

.mat-light-green { background-color: mat.get-color-from-palette(mat.$light-green-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 500)!important;}
.mat-light-green-50 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 50)!important;}
.mat-light-green-100 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 100)!important;}
.mat-light-green-200 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 200)!important;}
.mat-light-green-300 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 300)!important;}
.mat-light-green-400 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 400)!important;}
.mat-light-green-500 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 500)!important;}
.mat-light-green-600 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 600)!important;}
.mat-light-green-700 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 700)!important;}
.mat-light-green-800 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 800)!important;}
.mat-light-green-900 { background-color: mat.get-color-from-palette(mat.$light-green-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, 900)!important;}
.mat-light-green-A100 { background-color: mat.get-color-from-palette(mat.$light-green-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, A100)!important;}
.mat-light-green-A200 { background-color: mat.get-color-from-palette(mat.$light-green-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, A200)!important;}
.mat-light-green-A400 { background-color: mat.get-color-from-palette(mat.$light-green-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, A400)!important;}
.mat-light-green-A700 { background-color: mat.get-color-from-palette(mat.$light-green-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$light-green-palette, A700)!important;}

.mat-lime { background-color: mat.get-color-from-palette(mat.$lime-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 500)!important;}
.mat-lime-50 { background-color: mat.get-color-from-palette(mat.$lime-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 50)!important;}
.mat-lime-100 { background-color: mat.get-color-from-palette(mat.$lime-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 100)!important;}
.mat-lime-200 { background-color: mat.get-color-from-palette(mat.$lime-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 200)!important;}
.mat-lime-300 { background-color: mat.get-color-from-palette(mat.$lime-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 300)!important;}
.mat-lime-400 { background-color: mat.get-color-from-palette(mat.$lime-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 400)!important;}
.mat-lime-500 { background-color: mat.get-color-from-palette(mat.$lime-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 500)!important;}
.mat-lime-600 { background-color: mat.get-color-from-palette(mat.$lime-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 600)!important;}
.mat-lime-700 { background-color: mat.get-color-from-palette(mat.$lime-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 700)!important;}
.mat-lime-800 { background-color: mat.get-color-from-palette(mat.$lime-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 800)!important;}
.mat-lime-900 { background-color: mat.get-color-from-palette(mat.$lime-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, 900)!important;}
.mat-lime-A100 { background-color: mat.get-color-from-palette(mat.$lime-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, A100)!important;}
.mat-lime-A200 { background-color: mat.get-color-from-palette(mat.$lime-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, A200)!important;}
.mat-lime-A400 { background-color: mat.get-color-from-palette(mat.$lime-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, A400)!important;}
.mat-lime-A700 { background-color: mat.get-color-from-palette(mat.$lime-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$lime-palette, A700)!important;}

.mat-yellow { background-color: mat.get-color-from-palette(mat.$yellow-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 500)!important;}
.mat-yellow-50 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 50)!important;}
.mat-yellow-100 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 100)!important;}
.mat-yellow-200 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 200)!important;}
.mat-yellow-300 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 300)!important;}
.mat-yellow-400 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 400)!important;}
.mat-yellow-500 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 500)!important;}
.mat-yellow-600 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 600)!important;}
.mat-yellow-700 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 700)!important;}
.mat-yellow-800 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 800)!important;}
.mat-yellow-900 { background-color: mat.get-color-from-palette(mat.$yellow-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, 900)!important;}
.mat-yellow-A100 { background-color: mat.get-color-from-palette(mat.$yellow-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, A100)!important;}
.mat-yellow-A200 { background-color: mat.get-color-from-palette(mat.$yellow-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, A200)!important;}
.mat-yellow-A400 { background-color: mat.get-color-from-palette(mat.$yellow-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, A400)!important;}
.mat-yellow-A700 { background-color: mat.get-color-from-palette(mat.$yellow-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$yellow-palette, A700)!important;}

.mat-amber { background-color: mat.get-color-from-palette(mat.$amber-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 500)!important;}
.mat-amber-50 { background-color: mat.get-color-from-palette(mat.$amber-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 50)!important;}
.mat-amber-100 { background-color: mat.get-color-from-palette(mat.$amber-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 100)!important;}
.mat-amber-200 { background-color: mat.get-color-from-palette(mat.$amber-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 200)!important;}
.mat-amber-300 { background-color: mat.get-color-from-palette(mat.$amber-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 300)!important;}
.mat-amber-400 { background-color: mat.get-color-from-palette(mat.$amber-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 400)!important;}
.mat-amber-500 { background-color: mat.get-color-from-palette(mat.$amber-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 500)!important;}
.mat-amber-600 { background-color: mat.get-color-from-palette(mat.$amber-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 600)!important;}
.mat-amber-700 { background-color: mat.get-color-from-palette(mat.$amber-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 700)!important;}
.mat-amber-800 { background-color: mat.get-color-from-palette(mat.$amber-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 800)!important;}
.mat-amber-900 { background-color: mat.get-color-from-palette(mat.$amber-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, 900)!important;}
.mat-amber-A100 { background-color: mat.get-color-from-palette(mat.$amber-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, A100)!important;}
.mat-amber-A200 { background-color: mat.get-color-from-palette(mat.$amber-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, A200)!important;}
.mat-amber-A400 { background-color: mat.get-color-from-palette(mat.$amber-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, A400)!important;}
.mat-amber-A700 { background-color: mat.get-color-from-palette(mat.$amber-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$amber-palette, A700)!important;}

.mat-orange { background-color: mat.get-color-from-palette(mat.$orange-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 500)!important;}
.mat-orange-50 { background-color: mat.get-color-from-palette(mat.$orange-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 50)!important;}
.mat-orange-100 { background-color: mat.get-color-from-palette(mat.$orange-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 100)!important;}
.mat-orange-200 { background-color: mat.get-color-from-palette(mat.$orange-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 200)!important;}
.mat-orange-300 { background-color: mat.get-color-from-palette(mat.$orange-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 300)!important;}
.mat-orange-400 { background-color: mat.get-color-from-palette(mat.$orange-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 400)!important;}
.mat-orange-500 { background-color: mat.get-color-from-palette(mat.$orange-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 500)!important;}
.mat-orange-600 { background-color: mat.get-color-from-palette(mat.$orange-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 600)!important;}
.mat-orange-700 { background-color: mat.get-color-from-palette(mat.$orange-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 700)!important;}
.mat-orange-800 { background-color: mat.get-color-from-palette(mat.$orange-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 800)!important;}
.mat-orange-900 { background-color: mat.get-color-from-palette(mat.$orange-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, 900)!important;}
.mat-orange-A100 { background-color: mat.get-color-from-palette(mat.$orange-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, A100)!important;}
.mat-orange-A200 { background-color: mat.get-color-from-palette(mat.$orange-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, A200)!important;}
.mat-orange-A400 { background-color: mat.get-color-from-palette(mat.$orange-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, A400)!important;}
.mat-orange-A700 { background-color: mat.get-color-from-palette(mat.$orange-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$orange-palette, A700)!important;}

.mat-deep-orange { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 500)!important;}
.mat-deep-orange-50 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 50)!important;}
.mat-deep-orange-100 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 100)!important;}
.mat-deep-orange-200 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 200)!important;}
.mat-deep-orange-300 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 300)!important;}
.mat-deep-orange-400 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 400)!important;}
.mat-deep-orange-500 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 500)!important;}
.mat-deep-orange-600 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 600)!important;}
.mat-deep-orange-700 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 700)!important;}
.mat-deep-orange-800 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 800)!important;}
.mat-deep-orange-900 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, 900)!important;}
.mat-deep-orange-A100 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, A100)!important;}
.mat-deep-orange-A200 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, A200)!important;}
.mat-deep-orange-A400 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, A400)!important;}
.mat-deep-orange-A700 { background-color: mat.get-color-from-palette(mat.$deep-orange-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$deep-orange-palette, A700)!important;}

.mat-brown { background-color: mat.get-color-from-palette(mat.$brown-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 500)!important;}
.mat-brown-50 { background-color: mat.get-color-from-palette(mat.$brown-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 50)!important;}
.mat-brown-100 { background-color: mat.get-color-from-palette(mat.$brown-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 100)!important;}
.mat-brown-200 { background-color: mat.get-color-from-palette(mat.$brown-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 200)!important;}
.mat-brown-300 { background-color: mat.get-color-from-palette(mat.$brown-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 300)!important;}
.mat-brown-400 { background-color: mat.get-color-from-palette(mat.$brown-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 400)!important;}
.mat-brown-500 { background-color: mat.get-color-from-palette(mat.$brown-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 500)!important;}
.mat-brown-600 { background-color: mat.get-color-from-palette(mat.$brown-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 600)!important;}
.mat-brown-700 { background-color: mat.get-color-from-palette(mat.$brown-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 700)!important;}
.mat-brown-800 { background-color: mat.get-color-from-palette(mat.$brown-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 800)!important;}
.mat-brown-900 { background-color: mat.get-color-from-palette(mat.$brown-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, 900)!important;}
.mat-brown-A100 { background-color: mat.get-color-from-palette(mat.$brown-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, A100)!important;}
.mat-brown-A200 { background-color: mat.get-color-from-palette(mat.$brown-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, A200)!important;}
.mat-brown-A400 { background-color: mat.get-color-from-palette(mat.$brown-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, A400)!important;}
.mat-brown-A700 { background-color: mat.get-color-from-palette(mat.$brown-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$brown-palette, A700)!important;}

.mat-grey { background-color: mat.get-color-from-palette(mat.$grey-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 500)!important;}
.mat-grey-50 { background-color: mat.get-color-from-palette(mat.$grey-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 50)!important;}
.mat-grey-100 { background-color: mat.get-color-from-palette(mat.$grey-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 100)!important;}
.mat-grey-200 { background-color: mat.get-color-from-palette(mat.$grey-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 200)!important;}
.mat-grey-300 { background-color: mat.get-color-from-palette(mat.$grey-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 300)!important;}
.mat-grey-400 { background-color: mat.get-color-from-palette(mat.$grey-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 400)!important;}
.mat-grey-500 { background-color: mat.get-color-from-palette(mat.$grey-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 500)!important;}
.mat-grey-600 { background-color: mat.get-color-from-palette(mat.$grey-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 600)!important;}
.mat-grey-700 { background-color: mat.get-color-from-palette(mat.$grey-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 700)!important;}
.mat-grey-800 { background-color: mat.get-color-from-palette(mat.$grey-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 800)!important;}
.mat-grey-900 { background-color: mat.get-color-from-palette(mat.$grey-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, 900)!important;}
.mat-grey-A100 { background-color: mat.get-color-from-palette(mat.$grey-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, A100)!important;}
.mat-grey-A200 { background-color: mat.get-color-from-palette(mat.$grey-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, A200)!important;}
.mat-grey-A400 { background-color: mat.get-color-from-palette(mat.$grey-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, A400)!important;}
.mat-grey-A700 { background-color: mat.get-color-from-palette(mat.$grey-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$grey-palette, A700)!important;}

.mat-blue-grey { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 500)!important;}
.mat-blue-grey-50 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 50)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 50)!important;}
.mat-blue-grey-100 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 100)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 100)!important;}
.mat-blue-grey-200 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 200)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 200)!important;}
.mat-blue-grey-300 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 300)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 300)!important;}
.mat-blue-grey-400 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 400)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 400)!important;}
.mat-blue-grey-500 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 500)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 500)!important;}
.mat-blue-grey-600 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 600)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 600)!important;}
.mat-blue-grey-700 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 700)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 700)!important;}
.mat-blue-grey-800 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 800)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 800)!important;}
.mat-blue-grey-900 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, 900)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, 900)!important;}
.mat-blue-grey-A100 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, A100)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, A100)!important;}
.mat-blue-grey-A200 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, A200)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, A200)!important;}
.mat-blue-grey-A400 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, A400)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, A400)!important;}
.mat-blue-grey-A700 { background-color: mat.get-color-from-palette(mat.$blue-grey-palette, A700)!important; color: mat.get-contrast-color-from-palette(mat.$blue-grey-palette, A700)!important;}