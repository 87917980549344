
//Preloader

$preloader-bg-color:$brand-primary;

//Sidebar

$sidebar-icons:1.2rem;
$user-name-font-size:0.8rem;
$page-title-font-size:2.0rem;
$widget-title-font-size:1.9rem;
$widget-title-sm-font-size:1.2rem;
$card-span-font-size:0.8rem;
$widget-subtitle-font-size:1.5rem;
$sidebar-width: 15rem !default;
$sidebar-text-color: $mtn-color-2;
$sidebar-bg-color: #222d33;
$sidebar-logo-bg:#7860aa;
$compact-sidebar-width: 70px !default;
$sidebar-width-mobile: 18rem !default;
$sidebar-font-size:0.8rem;
//Header 
$header-bg-color:$brand-primary;

$header-menu-font-size:1rem;
$header-menu-text-color:#fff;
$header-menu-line-height:2.0rem;
//dropdown
$dropdown-menu-font-color:$gray-dark;
$dropdown-menu-header-color:$mtn-color-2;

$ham-font-size:1.3rem;
$ham-text-color:#fff;

//Container Padding
$container-padding:1.5rem;
$container-bg: rgb(248, 246, 246);

//inQuest Main Block

$inQuest-card-pad:1rem;
$inQuest-card-bg:#ffffff;
$box-shadow:0 0 3px rgba(0, 0, 0, 0.1);
$inQuest-card-border:1px solid rgba(0, 0, 0, 0.1);
$inQuest-card-title-font-size:1.1rem;
$inQuest-card-title-weight:600;
$test-suite-bg:#fffffe;
$test-suite-bg-NEGATIVE_TEST_CASE:#fcf6e8;
$test-suite-bg-POSITIVE_TEST_CASE:#f7fef7;
$test-suite-bg-TEST_CHAIN:#eefaff;

// reboot
$reboot-card-pad: 0.2rem;

//Md card

$mat-card-padding-top-bottom :1rem;
$mat-card-padding-left-right : 1rem;

// Typography
//

$font-color: $mtn-color-2;
//Meta post

$meta-post-color:#9c9fad;
$meta-post-font-weight:bold;

//Breadcrumb
$breadcrumb-font-size:0.875rem;
$breadcrumb-font-color:#ffffff;

//Mat list hover bg
$mat-list-hover-bg-color:rgba(0, 0, 0, 0.04);
$html-font-size: 16px;
$mat-font-family: Roboto,Helvetica Neue,sans-serif;

// Radius

$border-radius-base:8px;

.bg-primary{
	color:#fff !important;
}
.bg-success{
	color:#fff !important;
}
.bg-info{
	color:#fff !important;
}
.bg-warning{
	color:#fff !important;
}
.bg-danger{
	color:#fff !important;
}
.bg-inverse{
	color:#fff !important;
}
