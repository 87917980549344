    //Sidebar vars
@use '~@angular/material' as mat;
    body {
        .app {
            height: 100%;
            overflow: hidden;
        }
        .mat-sidenav {
            overflow-x: hidden;
            overflow-y: auto;
            min-width: 2rem;
        }
        .inQuest-container {
            height: 100%;
        }
        // .mat-sidenav-content {
        //     background-color: $container-bg;
        //     height: 100%;
        //     overflow: hidden;
        // }
        .inQuest-base-container {
            height: 100%;
        }
        .inner-container {
            padding: $container-padding;
            height: calc(100% - 150px);
            overflow-x: hidden;
            overflow-y: auto;
        }
        .sidebar-area.mat-sidenav {
            background-color: $sidebar-bg-color;
        }
        .sidebar-container {
            height: 100%;
            position: relative;
        }
        .user-info-w {
            background: #2c3b41;
            padding-bottom: 1.2rem;
        }
        .inQuest-user-name {
            color: $mtn-color-2;
            font-size: $user-name-font-size;
        }
        .inquest-page-title {
            color: $mtn-color-2;
            font-size: $page-title-font-size;
        }
        .inquest-widget-title {
            color: $mtn-color-5;
            font-size: $widget-title-font-size;
        }

        .inquest-widget-title-sm {
            color: $mtn-color-5;
            font-size: $widget-title-sm-font-size;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
        }        

        .inquest-footer {
            color: $mtn-color-1;
            font-size: $widget-title-sm-font-size;
            display: flex;
            justify-content: center;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
        }        

        .cetralise {
            display: flex;
            justify-content: center;
            margin-top: 0.5rem;
            margin-left: 0.5rem;
        }        
        
        .inquest-widget-subtitle {
            color: $mtn-color-2;
            font-size: $widget-subtitle-font-size;
        }
        .inQuest-user-thumb {
            padding: 0.9375rem 1rem;
            img {
                width: 45px;
            }
            p {
                color: $sidebar-text-color;
            }
        }
        mat-sidenav .sidebar-panel .mat-nav-list a {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 2.5rem;
            padding: 0 1.5rem;
            font-weight: 600;
            font-size: $sidebar-font-size;
            color: $sidebar-text-color;
        }
        mat-sidenav .sidebar-panel .sub-menu {
            padding-top: 0;
            overflow: hidden;
            max-height: 0;
            -webkit-transition: .5s cubic-bezier(.35, 0, .25, 1);
            -moz-transition: .5s cubic-bezier(.35, 0, .25, 1);
            transition: .5s cubic-bezier(.35, 0, .25, 1);
            -webkit-transition-property: max-height;
            -moz-transition-property: max-height;
            transition-property: max-height;
            background: #2c3b41;
            a {
                padding-left: 3.6rem;
                margin: 0;
                height: 1.9rem;
                color: #8aa4af;
                &:before {
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    border: 1px solid #fff;
                    color: transparent;
                    position: absolute;
                    left: 1.8rem;
                    top: 10px;
                    content: '';
                }
                &:hover {
                    color: #fff;
                    &:before {
                        background: #fff;
                    }
                }
            }
        }
        mat-sidenav .sidebar-panel .navigation mat-icon:not(.caret) {
            margin-right: 0.75rem;
        }
        .sidebar-panel {
            width: auto;
            height: 100%;
        }
        .sidebar-container .mat-nav-list .mat-list-item .mat-list-item-content {
            height: auto;
            display: block;
            margin-bottom: 0;
            padding: 0;
        }
        .mat-nav-list .mat-list-item-content:hover,
        .mat-nav-list .mat-list-item-content.mat-list-item-focus {
            background-color: $mat-list-hover-bg-color;
        }
        .sidebar-panel .material-icons {
            font-size: $sidebar-icons;
        }
         .sidebar-panel .mat-list-item {
           height: auto;
        }
        
        .mat-list-item .menu-caret {
            transition: all 0.3s ease-in-out 0s;
            margin-right: 0 !important;
            position: absolute;
            top: 10px;
            right: 1.2rem;
        }
        .mat-list-item.open {
            .menu-caret {
                transform: rotate(-90deg);
            }
        }
        //Breadcrumbs
        .inQuest-breadcrumbs {
            font-size: $breadcrumb-font-size;
            color: $breadcrumb-font-color;
            margin-left: 2rem;
            margin-right: 2rem;
        }
        .mat-card {
            margin-top: 1rem;
            margin-bottom: 1rem;
            border-radius: $border-radius-base;
            padding: $mat-card-padding-top-bottom $mat-card-padding-left-right;
            overflow: hidden;
        }
        .mat-mini-fab .mat-icon,
        .mat-mini-fab i {
            padding: 0;
            line-height: 3.3rem;
        }
        .mat-mini-fab i {
            padding: 0.8125rem 0;
        }
        .mat-button {
            font-size: 1rem;
        }
        .mat-icon {
            height: auto;
        }
    }
    
    .app-dark {
        .mat-sidenav-content {
            background: rgba(54, 54, 54, 1);
        }
        .sidebar-area.mat-sidenav {
            background-color: $brand-danger;
            color: #fff;
        }
        mat-sidenav .sidebar-panel .mat-nav-list a,
        .inQuest-user-name {
            color: #fff;
        }
        .inQuest-breadcrumbs {
            color: mat.get-color-from-palette($dark-foreground, base);
        }
        .inQuest-card {
            background: #424242;
            color: mat.get-color-from-palette($dark-foreground, base);
        }
    }
    
    .boxed {
        .dash-card {
            .pad-wrap {
                padding-left: 0.2rem;
                padding-right: 0.2rem;
            }
        }
    }
    
    .breadcrumb-item+.breadcrumb-item::before,
    .navbar-brand {
        color: $breadcrumb-font-color;
    }
    
    .breadcrumb-item.active {
        color: rgba(255, 255, 255, 0.6);
    }
    
    body {
        [dir="rtl"] {
            mat-sidenav .sidebar-panel .navigation mat-icon:not(.caret) {
                margin-left: 0.75rem;
            }
            .mat-list-item .menu-caret {
                transition: all 0.3s ease-in-out 0s;
                margin-left: 0 !important;
            }
            mat-sidenav .sidebar-panel .sub-menu {
                a {
                    padding-right: 4rem;
                }
            }
        }
        .collapsed-sidebar[dir="rtl"] mat-sidenav .sidebar-panel .navigation mat-icon:not(.caret) {
            margin-left: 0.75rem;
            margin-right: 0;
        }
    }
    
    @media(max-width:1525px) {
        .inQuest-mail-wrapper .mat-list .mat-list-item .mat-list-item-content,
        .inQuest-mail-wrapper .mat-nav-list .mat-list-item .mat-list-item-content {
            height: 30px;
        }
    }
    
    @media(max-width:1199px) {
        .boxed-layout-md {
            display: none;
        }
    }
    
    @media(max-width:991px) {
        .inQuest-breadcrumbs {
            display: none;
        }
        .inQuest-header-toolbar {
            .search-bar,
            .mat-icon-button {
                display: none;
            }
        }
    }
    
    @media(max-width:959px) {
        .logo-container.d-flex.justify-content-between {
            justify-content: start !important;
            width: auto !important;
            .logo-sign {
                display: none !important;
            }
        }
    }
    
    @media(max-width:767px) {
        .inQuest-breadcrumbs {
            display: none;
        }
    }
    
    .inQuest-breadcrumbs {
        display: flex;
        .breadcrumb {
            background: transparent;
            align-self: center;
            margin: 0;
            padding: 0;
        }
    }