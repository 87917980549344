@import "material.variables";
@import "mixins/bi-app/bi-app-ltr";
// Core Variables and Mixins
@import "bootstrap";
@import "app.variables";
@import "mixins";
// Core Styles
@import "core";
@import "material";
@import "components";

@import "utilities/utilities";

@import 'rebrand.scss';

* {
  box-sizing: border-box !important;
}

button {
  font-family: 'Barlow', sans-serif !important;
  font-weight: 600 !important;
}

button:focus {
  outline: transparent;
}

a:focus {
  text-decoration: none;
}

.no-link:hover, .no-link:visited {
  color: inherit;
  text-decoration: none;
}

/* INQUEST STYLES */
.inquest-component-title {
  font-size: 1.5rem;
  color: #004c97;
  font-family: 'Montserrat', sans-serif;
}

.inquest-component-subtitle {
  font-size: 1rem;
  color: #999;
  font-family: 'Montserrat', sans-serif;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #999 !important;
  color: #fff !important; 
}

.selected {
  background-color: #004c97 !important;
  border: 1px solid var(--brand-red);
  span {
    color: #fff !important;
  }
}

.inquest-text-bold {
  color: #004c97;
  font-size: 1rem;
  font-weight: 900;
  font-family: 'Barlow', sans-serif;
}

.inquest-text {
  color: #004c97;
  font-size: 1rem;
  font-family: 'Barlow', sans-serif;
}