@import '~@angular/material/theming';
@include mat-core();
/* ======== Custom Colors ======== */  
$primary-palette: (
  50: #002554,
  100: #002554,
  200: #002554,
  300: #002554,
  400: #002554,
  500: #002554,
  600: #002554,
  700: #002554,
  800: #002554,
  900: #002554,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$accent-palette: (
  50: #004c97,
  100: #004c97,
  200: #004c97,
  300: #004c97,
  400: #004c97,
  500: #004c97,
  600: #004c97,
  700: #004c97,
  800: #004c97,
  900: #004c97,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$warn-palette: (
  50: #e10600,
  100: #e10600,
  200: #e10600,
  300: #e10600,
  400: #e10600,
  500: #e10600,
  600: #e10600,
  700: #e10600,
  800: #e10600,
  900: #e10600,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

/* ======== Angular material custom themes ======== */  
$my-custom-primary: mat-palette($primary-palette);
$my-custom-accent: mat-palette($accent-palette);
$my-custom-warn: mat-palette($warn-palette);
$my-custom-theme: mat-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);
@include angular-material-theme($my-custom-theme);