// Basics tags 
$basic-border:1px solid rgba(0, 0, 0, 0.1);
code {
    padding: 8px;
    background: $container-bg;
}
.border-primary{
    border-top:2px solid $brand-primary !important;
}
.border-info{
    border-top:2px solid $brand-info !important;
}
.border-success{
    border-top:2px solid $brand-success !important;
}
.border-warning{
    border-top:2px solid $brand-warning !important;
}
.border-danger{
    border-top:2px solid $brand-danger !important;
}
.border-fail{
    border-top:2px solid $brand-fail !important;
}
.bg-white {
    background: #fff none repeat scroll 0 0;
}

pre {
    background-color: $container-bg;
    padding: 15px;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.label {
    color: #ffffff;
    display: inline-block;
    padding: 2px 14px;
}

.box-shadow-none {
    box-shadow: none !important;
}

.lead {
    font-size: 1.375rem;
}

.sq-50 {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 100%;
    font-size: 1.4rem;
}

// inQuest Mat Card style
.inQuest-card-title {
   // border-bottom: $basic-border;
    .mat-icon-button {
        height: 2.188rem;
        line-height: 1.1;
        width: 2.188rem;
        margin: 0;
        color: #868E96;
        font-size: 0.875rem;
    }
    h4 {
        margin-bottom: 0.8rem;
        font-size: $inQuest-card-title-font-size;
        font-weight: $inQuest-card-title-weight;
    }
    .inQuest-head,
    .inQuest-flex {
        align-items: center;
        display: flex;
        .inQuest-pre {
            flex: 1 1 auto;
        }
    }

}

//reboot
.reboot-fixed-button {
    width: 10rem;
}
.reboot-modal-header {
    color: white;
    font-size: $card-span-font-size;    
    background-color: $mtn-color-5;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 5px;
    height: 3rem;
}

.reboot-modal-body {
    color: $mtn-color-2;
    font-size: $card-span-font-size;    
    background-color: white;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 5px;
    h5 {
        color: $mtn-color-5;
    }
}

.reboot-card {
    background: $inQuest-card-bg;
    border: $inQuest-card-border;
    box-shadow: $box-shadow;
    padding: $reboot-card-pad;
    border-radius: 5px;
}

.reboot-card-span {
    color: $mtn-color-5;
    font-size: $card-span-font-size;
    // background-color: white;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.reboot-card-span-text-2-bold {
    color: $mtn-color-2;
    font-size: $card-span-font-size;
    font-weight: bold;
}

.reboot-card-span-text-2 {
    color: $mtn-color-2;
    font-size: $card-span-font-size;
    padding-right: 0.8rem;
}

.reboot-card-span-text-4 {
    color: $mtn-color-1;
    font-size: $card-span-font-size;
    padding-right: 0.8rem;
}

.reboot-card-span-text-success {
    color: $brand-success;
    font-size: $card-span-font-size;
}

.reboot-card-span-text-Passed {
    color: $brand-success;
    font-size: $card-span-font-size;
}

.reboot-card-span-text-Processing {
    color: $brand-info;
    font-size: $card-span-font-size;
}

.reboot-card-span-text-Failed {
    color: $brand-danger;
    font-size: $card-span-font-size;
}

.mat-default > .mat-content {
    justify-content: space-between;
}

.mat-content > mat-panel-title, .mat-content > mat-panel-description {
  flex: 1;
}

.single-device {
    width: 15rem;
}

.drag-and-drop-container {
    width: 400px;
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;    
  }

  .drag-and-drop-box {
    padding: 0.9rem 0.9rem;
    border-bottom: solid 1px #ccc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    color: $mtn-color-2;
    font-size: $card-span-font-size;
  }

  .drag-and-drop-box-danger {
    padding: 0.9rem 0.9rem;
    border-bottom: solid 1px #ccc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: $gray-lightest;
    color: $brand-danger;
    font-size: $card-span-font-size;
  }

  .drag-and-drop-box-success {
    padding: 0.9rem 0.9rem;
    border-bottom: solid 1px #ccc;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    color: $brand-success;
    font-size: $card-span-font-size;
    font-weight: bold;
  }

  .test-run-card {
    max-width: 100%;
    background-color: white;
    background: white;
  }

  .device-detail-card {
    max-width: 100%;
    background-color: white;
    background: white;
  }

  .icon-color-success {
    color: $brand-success;
    padding-right: 0.8rem;
}

.icon-color-danger {
    color: $brand-danger;
    padding-right: 0.8rem;
}

.icon-color-primary {
    color: $brand-primary;
    padding-right: 0.8rem;
}

.icon-color-info {
    color: $brand-info;
    padding-right: 0.8rem;
}

.text-color-success-bold {
    color: $brand-success;
    font-size: $card-span-font-size;
    font-weight: bold;
}

.text-color-success {
    color: $brand-success;
    font-size: $card-span-font-size;
    font-weight: medium;
}

.text-color-danger-bold {
    color: $brand-danger;
    font-size: $card-span-font-size;
    font-weight: bold;
}

.text-color-danger {
    color: $brand-danger;
    font-size: $card-span-font-size;
    font-weight: medium;
}

.text-color-primary-bold {
    color: $brand-primary;
    font-size: $card-span-font-size;
    font-weight: bold;
}

.text-color-2-bold {
    color: $mtn-color-2;
    font-size: $card-span-font-size;
    font-weight: bold;
}

.text-color-5 {
    color: $mtn-color-5;
    font-size: $card-span-font-size;
    font-weight: bold;
}

.widget-title-success {
    color: $brand-success;
    font-size: $widget-title-sm-font-size;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.widget-title-danger {
    color: $brand-danger;
    font-size: $widget-title-sm-font-size;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.widget-title-info {
    color: $brand-info;
    font-size: $widget-title-sm-font-size;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

//End Reboot
.inQuest-card {
    background: $inQuest-card-bg;
    border: $inQuest-card-border;
    box-shadow: $box-shadow;
    padding: $inQuest-card-pad;
    border-radius: 5px;
}

.test-suite-panel {
    background: $test-suite-bg;
    border: $inQuest-card-border;
    box-shadow: $box-shadow;
    border-radius: 5px;
}

.test-suite-panel-POSITIVE_TEST_CASE {
    background: $test-suite-bg-POSITIVE_TEST_CASE;
    border: $inQuest-card-border;
    box-shadow: $box-shadow;
    border-radius: 5px;
}

.test-suite-panel-NEGATIVE_TEST_CASE {
    background: $test-suite-bg-NEGATIVE_TEST_CASE;
    border: $inQuest-card-border;
    box-shadow: $box-shadow;
    border-radius: 5px;
}

.test-suite-panel-TEST_CHAIN {
    background: $test-suite-bg-TEST_CHAIN;
    border: $inQuest-card-border;
    box-shadow: $box-shadow;
    border-radius: 5px;
}

.inQuest-card-ribbon {
    background: $inQuest-card-bg;
    border: $inQuest-card-border;
    box-shadow: $box-shadow;
    padding: 0.2rem;
    border-radius: 5px;
}

.card-controls a {
  color: gray;
  margin: 0 0.2rem;
}
.inQuest-card-content-sm {
    padding: 0.5rem 0 0;
}

.inQuest-card-content {
    padding: 1.3rem 0 1rem 0;
}

.inQuest-card-footer {
    .mat-divider {
        padding: 0.3em
    }
}

.no-gutter {
    margin-left: -$mat-card-padding-left-right;
    margin-right: -$mat-card-padding-left-right;
}

//Radius classes
.radius-none {
    border-radius: 0;
}

.radius-circle {
    border-radius: 50%;
}

.border-none {
    border: 0!important;
}

.border-rad-base {
    border-radius: $border-radius-base;
}

//Overflow hidden
.overflow-visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

// Position
.inQuest-relative {
    position: relative;
}

.inQuest-fixed {
    position: fixed;
}

.inQuest-absolute {
    position: absolute;
}

// List 
.list-inline {
    li {
        display: inline-block;
    }
}

.list-style-none {
    list-style: none;
}

.inQuest-list {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    >li {
        margin-bottom: 1rem;
    }
}

.list-style-none {
    list-style: none;
    padding-left: 0;
}

// Helper classes
.box-inset {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
}

.full-wid {
    width: 100%;
}

.inQuest-span {
    display: inline-block;
    vertical-align: top;
}

.inQuest-block {
    display: block;
}

.button-block {
    display: block;
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.img-circle {
    border-radius: 50%;
}

.shadow-none {
    box-shadow: none!important;
}

.background-none {
    background-color: transparent!important;
}

.stats-link {
    a {
        color: #fff;
    }
}

//Forms
.send-text {
    width: 80%;
    float: left;
}

.send-btn {
    width: 20%;
    float: left;
}

.registered-users-list>li {
    float: left;
    padding: 7.5px;
    width: 25%;
    p {
        margin: 0;
    }
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.latest-post img {
    width: 250px;
}

.cursor:hover {
    pointer: cursor;
}

.form-bg {
    background: url(../../img/6.jpg);
    background-size: cover;
}

.error-bg {
    background: url(../../img/7.jpg);
    background-size: cover;
    height: 100vh;
}

.tab-content {
    padding: 1rem 0;
}

.message .media {
    margin-bottom: 1rem;
    img {
        width: 80px;
    }
}

.inQuest-mail-wrapper {
    .mail-nav-list {
        li.active {
            background: $brand-danger;
            color: #fff;
        }
    }
}

//
.ngx-charts-outer {
    @include clearfix;
}

.h-300px {
    height: 300px;
}

.font-sm {
    font-size: 1rem !important;
}

//custom cols
@media(max-width:1800px) and (min-width:769px) {
    .col-full {
        -moz-box-flex: 0 !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
     .col-half {
        -moz-box-flex: 0 !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .hide-down{
        display: none;
    }
}
@media(max-width:1199px){
   .slider .carousel-caption{
        left:4%;
       right: 4%;
    }
}
@media(max-width:1199px) and (min-width:992px) {
    .res-md-6 {
        -moz-box-flex: 0 !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
       .res-md-12 {
        -moz-box-flex: 0 !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
.inQuest-sorted-drag-drop{
    mat-list-item{
        height: auto !important;
    }
}