@use "~@angular/material" as mat;
body {
  .mat-select-panel {
    background-color: mat.get-color-from-palette($background, card);
  }
  .mat-select-trigger,
  .mat-option {
    font-size: $font-size-base;
  }
  .mat-select-trigger {
    height: 22px;
    padding-bottom: 6px;
  }
  .mat-select-placeholder {
    padding-left: 0;
  }
  .app-dark .mat-select-panel {
    background-color: mat.get-color-from-palette($dark-background, card);
  }

  .mat-form-field.select-override {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}
