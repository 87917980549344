@use '~@angular/material' as mat;


body{
    

 .mat-card, button, input, optgroup, p, select, textarea,.mat-list .mat-list-item .mat-list-item-content, .mat-nav-list .mat-list-item .mat-list-item-content {
  font-family: $font-family-sans-serif;
}
}
.mat-white {
    background: #fff;
}
.text-white{
	color:#fff;
}
@include text-emphasis-variant('.mat-text-muted', mat.get-color-from-palette($foreground, secondary-text));
@include text-emphasis-variant('.mat-text-default', mat.get-color-from-palette($foreground, base));
@include text-emphasis-variant('.mat-text-primary', mat.get-color-from-palette($primary));
@include text-emphasis-variant('.mat-text-warn', mat.get-color-from-palette($warn));
@include text-emphasis-variant('.mat-text-accent', mat.get-color-from-palette($accent));
.app-dark {
    @include text-emphasis-variant('.mat-text-muted', mat.get-color-from-palette($dark-foreground, secondary-text));
    @include text-emphasis-variant('.mat-text-default', mat.get-color-from-palette($dark-foreground, base));
}