/* VARIABLES */
svg {
  float: left;
  shape-rendering: geometric-precision;
}

.climacon_component-stroke {
  fill: white;
  stroke-width: 0px;
  stroke: white;
}

.climacon_component-fill {
  fill: #aedef4;
  stroke-width: 0px;
  stroke: white;
}


/* SVG GLOBAL */
svg {
  float: left;
  shape-rendering: geometricPrecision;
}
      
g, path, circle, rect{

  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;

  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;

  -o-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;

  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -moz-animation-duration: 12s;
  -webkit-animation-duration: 12s;
  -o-animation-duration: 12s;
  animation-duration: 12s;
}

/* SUN */
.climacon_componentWrap-sun{

    -webkit-animation-name: rotate;
    -moz-animation-name: rotate;
    -o-animation-name: rotate;
    animation-name: rotate;
}

.climacon_iconWrap-sun .climacon_component-stroke_sunSpoke,
.climacon_iconWrap-sunFill .climacon_component-stroke_sunSpoke  {
    fill-opacity: 1;
    
    -webkit-animation-name: scale;
    -moz-animation-name: scale;
    -o-animation-name: scale;
    animation-name: scale;

    -webkit-animation-direction: alternate;
    -moz-animation-direction: alternate;
    -o-animation-direction: alternate;
    animation-direction: alternate;

    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;

}

.climacon_iconWrap .climacon_component-stroke_sunSpoke:nth-child(even) {

    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -o-animation-delay: 3s;
    animation-delay: 3s;

}

/* MOON */
.climacon_componentWrap-moon{
    -webkit-animation-name: wobble;
    -moz-animation-name: wobble;
    -o-animation-name: wobble;
    animation-name: wobble;
}

/* SNOWFLAKE */
.climacon_componentWrap-snowflake {
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;

  -webkit-animation-duration: 36s;
  -moz-animation-duration: 36s;
  -o-animation-duration: 36s;
  animation-duration: 36s;

  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  -o-animation-direction: normal;
  animation-direction: normal;

}


/* WIND */
.climacon_componentWrap-wind{
  -webkit-animation-name: translateWind;
  -moz-animation-name: translateWind;
  -o-animation-name: translateWind;
  animation-name: translateWind;

  -webkit-animation-duration: 4s;
  -moz-animation-duration: 4s;
  -o-animation-duration: 4s;
  animation-duration: 4s;

  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  animation-direction: alternate;

  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}


/* CLOUD SUN */
.climacon_componentWrap-sun_cloud {

  -webkit-animation-name: behindCloudMove, rotate;
  -moz-animation-name: behindCloudMove, rotate;
  -o-animation-name: behindCloudMove, rotate;
  animation-name: behindCloudMove, rotate;

  -webkit-animation-iteration-count: 1, infinite;
  -moz-animation-iteration-count: 1, infinite;
  -o-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;

  -webkit-animation-timing-function: ease-out, linear;
  -moz-animation-timing-function: ease-out, linear;
  -o-animation-timing-function: ease-out, linear;
  animation-timing-function: ease-out, linear;

  -webkit-animation-delay: 0, 3s;
  -moz-animation-delay: 0, 3s;
  -o-animation-delay: 0, 3s;
  animation-delay: 0, 3s;

  -webkit-animation-duration: 3s, 12s;
  -moz-animation-duration: 3s, 12s;
  -o-animation-duration: 3s, 12s;
  animation-duration: 3s, 12s;

}

.climacon_component-stroke_sunSpoke {
  fill-opacity: 0;
  -webkit-animation-name: fillOpacity, scale;
  -moz-animation-name: fillOpacity, scale;
  -o-animation-name: fillOpacity, scale;
  animation-name: fillOpacity, scale;

  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  animation-direction: alternate;

  -webkit-animation-iteration-count: 1, infinite;
  -moz-animation-iteration-count: 1, infinite;
  -o-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;

  -webkit-animation-delay: 3s, 0;
  -moz-animation-delay: 3s, 0;
  -o-animation-delay: 3s, 0;
  animation-delay: 3s, 0;

  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;

  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;

}

.climacon_component-stroke_sunSpoke:nth-child(even) {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;

}

/* CLOUD MOON */
.climacon_componentWrap-moon_cloud {
  -webkit-animation-name: behindCloudMove, wobble;
  -moz-animation-name: behindCloudMove, wobble;
  -o-animation-name: behindCloudMove, wobble;
  animation-name: behindCloudMove, wobble;

  -webkit-animation-iteration-count: 1, infinite;
  -moz-animation-iteration-count: 1, infinite;
  -o-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;

  -webkit-animation-timing-function: ease-out, linear;
  -moz-animation-timing-function: ease-out, linear;
  -o-animation-timing-function: ease-out, linear;
  animation-timing-function: ease-out, linear;

  -webkit-animation-delay: 0, 3s;
  -moz-animation-delay: 0, 3s;
  -o-animation-delay: 0, 3s;
  animation-delay: 0, 3s;

  -webkit-animation-duration: 3s, 12s;
  -moz-animation-duration: 3s, 12s;
  -o-animation-duration: 3s, 12s;
  animation-duration: 3s, 12s;
}

/* DRIZZLE */
.climacon_component-stroke_drizzle {
  fill-opacity: 0;

  -webkit-animation-name: drizzleFall, fillOpacity2;
  -moz-animation-name: drizzleFall, fillOpacity2;
  -o-animation-name: drizzleFall, fillOpacity2;
  animation-name: drizzleFall, fillOpacity2;

  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

}

.climacon_component-stroke_drizzle:nth-child(1) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.climacon_component-stroke_drizzle:nth-child(2) {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.climacon_component-stroke_drizzle:nth-child(3) {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.climacon_component-stroke_sunBody,
.climacon_component-stroke_sunSpoke {
  fill: #fffb8b;
}

.climacon_component-stroke_cloud {
  fill: #eaeaea;
}

/* RAIN */
.climacon_component-stroke_rain {
  fill: #89e7ff;
  fill-opacity: 0;
  -webkit-animation-name: rainFall, fillOpacity2;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-duration: 2s;

  -moz-animation-name: rainFall, fillOpacity2;
  -moz-animation-timing-function: ease-in;
  -moz-animation-duration: 2s;

  -o-animation-name: rainFall, fillOpacity2;
  -o-animation-timing-function: ease-in;
  -o-animation-duration: 2s;

  animation-name: rainFall, fillOpacity2;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

.climacon_component-stroke_rain:nth-child(n+4) {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;

}

.climacon_component-stroke_rain_alt:nth-child(2) {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;

}

/* HAIL */
/* HAIL ALT */
.climacon_component-stroke_hailAlt {

  fill-opacity: 0;

  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;

  -webkit-animation-duration: 0.66667s;
  -moz-animation-duration: 0.66667s;
  -o-animation-duration: 0.66667s;
  animation-duration: 0.66667s;

}

.climacon_component-stroke_hailAlt-left {
  -webkit-animation-name: hailLeft, fillOpacity2;
  -moz-animation-name: hailLeft, fillOpacity2;
  -o-animation-name: hailLeft, fillOpacity2;
  animation-name: hailLeft, fillOpacity2;

}

.climacon_component-stroke_hailAlt-middle {
  -webkit-animation-name: hailMiddle, fillOpacity2;
  -moz-animation-name: hailMiddle, fillOpacity2;
  -o-animation-name: hailMiddle, fillOpacity2;
  animation-name: hailMiddle, fillOpacity2;

}

.climacon_component-stroke_hailAlt-middle:nth-child(2) {
  -webkit-animation-name: hailMiddle2, fillOpacity2;
  -moz-animation-name: hailMiddle2, fillOpacity2;
  -o-animation-name: hailMiddle2, fillOpacity2;
  animation-name: hailMiddle2, fillOpacity2;

}

.climacon_component-stroke_hailAlt-right {
  -webkit-animation-name: hailRight, fillOpacity2;
  -moz-animation-name: hailRight, fillOpacity2;
  -o-animation-name: hailRight, fillOpacity2;
  animation-name: hailRight, fillOpacity2;

}

.climacon_component-stroke_hailAlt:nth-child(1) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;

}

.climacon_component-stroke_hailAlt:nth-child(2) {
  -webkit-animation-delay: 0.11111s;
  -moz-animation-delay: 0.11111s;
  -o-animation-delay: 0.11111s;
  animation-delay: 0.11111s;

}

.climacon_component-stroke_hailAlt:nth-child(3) {
  -webkit-animation-delay: 0.22222s;
  -moz-animation-delay: 0.22222s;
  -o-animation-delay: 0.22222s;
  animation-delay: 0.22222s;

}

.climacon_component-stroke_hailAlt:nth-child(4) {
  -webkit-animation-delay: 0.33333s;
  -moz-animation-delay: 0.33333s;
  -o-animation-delay: 0.33333s;
  animation-delay: 0.33333s;

}

.climacon_component-stroke_hailAlt:nth-child(5) {
  -webkit-animation-delay: 0.44444s;
  -moz-animation-delay: 0.44444s;
  -o-animation-delay: 0.44444s;
  animation-delay: 0.44444s;

}

.climacon_component-stroke_hailAlt:nth-child(6) {
  -webkit-animation-delay: 0.55556s;
  -moz-animation-delay: 0.55556s;
  -o-animation-delay: 0.55556s;
  animation-delay: 0.55556s;

}

/* SNOW */
.climacon_component-stroke_snow {
  fill-opacity: 0;

  -webkit-animation-name: snowFall, fillOpacity2;
  -moz-animation-name: snowFall, fillOpacity2;
  -o-animation-name: snowFall, fillOpacity2;
  animation-name: snowFall, fillOpacity2;

  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;

  -webkit-animation-duration: 6s;
  -moz-animation-duration: 6s;
  -o-animation-duration: 6s;
  animation-duration: 6s;
}

.climacon_component-stroke_snow:nth-child(3) {
  -webkit-animation-name: snowFall2, fillOpacity2;
  -moz-animation-name: snowFall2, fillOpacity2;
  -o-animation-name: snowFall2, fillOpacity2;
  animation-name: snowFall2, fillOpacity2;
}

.climacon_component-stroke_snow:nth-child(1) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.climacon_component-stroke_snow:nth-child(2) {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

.climacon_component-stroke_snow:nth-child(3) {
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}

/* SNOW ALT */

.climacon_component-snowAlt {
  fill-opacity: 0;

  -webkit-animation-name: snowFall, fillOpacity2;
  -moz-animation-name: snowFall, fillOpacity2;
  -o-animation-name: snowFall, fillOpacity2;
  animation-name: snowFall, fillOpacity2;

  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;

  -webkit-animation-duration: 6s;
  -moz-animation-duration: 6s;
  -o-animation-duration: 6s;
  animation-duration: 6s;
}

/* FOG */
.climacon_component-stroke_fogLine {

  fill-opacity: 0.5;

  -webkit-animation-name: translateFog, fillOpacityFog;
  -moz-animation-name: translateFog, fillOpacityFog;
  -o-animation-name: translateFog, fillOpacityFog;
  animation-name: translateFog, fillOpacityFog;

  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  -o-animation-direction: normal;
  animation-direction: normal;

  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;

  -webkit-animation-duration: 12s;
  -moz-animation-duration: 12s;
  -o-animation-duration: 12s;
  animation-duration: 12s;

}

.climacon_component-stroke_fogLine:nth-child(even) {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  animation-delay: 6s;
}


/* LIGHTNING */
.climacon_component-stroke_lightning {
  fill-opacity: 0;
  -webkit-animation-name: lightningFlash;
  -moz-animation-name: lightningFlash;
  -o-animation-name: lightningFlash;
  animation-name: lightningFlash;

  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  -o-animation-direction: normal;
  animation-direction: normal;

  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;

  -webkit-animation-duration: 12s;
  -moz-animation-duration: 12s;
  -o-animation-duration: 12s;
  animation-duration: 12s;

}

/* TORNADO */
.climacon_component-stroke_tornadoLine{
  -webkit-animation-name:  translateTornado1;
  -moz-animation-name:  translateTornado1;
  -o-animation-name:  translateTornado1;
  animation-name:  translateTornado1;

  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  animation-direction: alternate;

  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;

  -webkit-animation-duration: 12s;
  -moz-animation-duration: 12s;
  -o-animation-duration: 12s;
  animation-duration: 12s;
}

.climacon_component-stroke_tornadoLine:nth-child(1){
  -webkit-animation-name:  translateTornado1;
  -moz-animation-name:  translateTornado1;
  -o-animation-name:  translateTornado1;
  animation-name:  translateTornado1;
}

.climacon_component-stroke_tornadoLine:nth-child(2){
  -webkit-animation-name:  translateTornado2;
  -moz-animation-name:  translateTornado2;
  -o-animation-name:  translateTornado2;
  animation-name:  translateTornado2;
}

.climacon_component-stroke_tornadoLine:nth-child(3){
  -webkit-animation-name:  translateTornado3;
  -moz-animation-name:  translateTornado3;
  -o-animation-name:  translateTornado3;
  animation-name:  translateTornado3;
}

.climacon_component-stroke_tornadoLine:nth-child(4){
  -webkit-animation-name:  translateTornado4;
  -moz-animation-name:  translateTornado4;
  -o-animation-name:  translateTornado4;
  animation-name:  translateTornado4;
}

.climacon_component-stroke_tornadoLine:nth-child(5){
  -webkit-animation-name:  translateTornado5;
  -moz-animation-name:  translateTornado5;
  -o-animation-name:  translateTornado5;
  animation-name:  translateTornado5;
}

.climacon_component-stroke_tornadoLine:nth-child(6){
  -webkit-animation-name:  translateTornado6;
  -moz-animation-name:  translateTornado6;
  -o-animation-name:  translateTornado6;
  animation-name:  translateTornado6;
}

.climacon_component-stroke_arrow-up {

  fill-opacity: 0;

  -webkit-animation-name: fillOpacity2, translateArrowUp;
  -moz-animation-name: fillOpacity2, translateArrowUp;
  -o-animation-name: fillOpacity2, translateArrowUp;
  animation-name: fillOpacity2, translateArrowUp;

  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;

}

.climacon_component-stroke_arrow-down {

  fill-opacity: 0;

  -webkit-animation-name: fillOpacity2, translateArrowDown;
  -moz-animation-name: fillOpacity2, translateArrowDown;
  -o-animation-name: fillOpacity2, translateArrowDown;
  animation-name: fillOpacity2, translateArrowDown;

  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s;

}

.climacon_componentWrap-sunriseAlt {
  -webkit-animation-name: translateSunrise, fillOpacity;
  -moz-animation-name: translateSunrise, fillOpacity;
  -o-animation-name: translateSunrise, fillOpacity;
  animation-name: translateSunrise, fillOpacity;

  -webkit-animation-duration: 12s, 6s;
  -moz-animation-duration: 12s, 6s;
  -o-animation-duration: 12s, 6s;
  animation-duration: 12s, 6s;

  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  -o-animation-direction: normal;
  animation-direction: normal;

  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.climacon_componentWrap-sunsetAlt {
  -webkit-animation-name: translateSunset;
  -moz-animation-name: translateSunset;
  -o-animation-name: translateSunset;
  animation-name: translateSunset;

  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;

  -webkit-animation-duration: 12s;
  -moz-animation-duration: 12s;
  -o-animation-duration: 12s;
  animation-duration: 12s;

  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  -o-animation-direction: normal;
  animation-direction: normal;

  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* ANIMATIONS */
@-webkit-keyframes rotate{
  0%{
    -webkit-transform: rotate(0);
  }
  100%{
    -webkit-transform: rotate(360deg);
  }   
}

@-moz-keyframes rotate{
  0%{
    -moz-transform: rotate(0);
  }
  100%{
    -moz-transform: rotate(360deg);
  }   
}

@-o-keyframes rotate{
  0%{
    -o-transform: rotate(0);
  }
  100%{
    -o-transform: rotate(360deg);
  }   
}

@keyframes rotate{
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }   
}

@-webkit-keyframes wobble{
  0%{
    -webkit-transform: rotate(0);
  }
  25%{
    -webkit-transform: rotate(-15deg);
  }
  50%{
    -webkit-transform: rotate(0);
  }
  75%{
    -webkit-transform: rotate(15deg);
  }
  100%{
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes wobble{
  0%{
    -moz-transform: rotate(0);
  }
  25%{
    -moz-transform: rotate(-15deg);
  }
  50%{
    -moz-transform: rotate(0);
  }
  75%{
    -moz-transform: rotate(15deg);
  }
  100%{
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes wobble{
  0%{
    -o-transform: rotate(0);
  }
  25%{
    -o-transform: rotate(-15deg);
  }
  50%{
    -o-transform: rotate(0);
  }
  75%{
    -o-transform: rotate(15deg);
  }
  100%{
    -o-transform: rotate(0deg);
  }
}

@keyframes wobble{
  0%{
    transform: rotate(0);
  }
  25%{
    transform: rotate(-15deg);
  }
  50%{
    transform: rotate(0);
  }
  75%{
    transform: rotate(15deg);
  }
  100%{
    transform: rotate(0deg);
  }
}

@-webkit-keyframes scale{
  0%{
    -webkit-transform: scale(1,1);
  }
  100%{
    -webkit-transform: scale(.5,.5);
  }   
}

@-moz-keyframes scale{
  0%{
    -moz-transform: scale(1,1);
  }
  100%{
    -moz-transform: scale(.5,.5);
  }   
}

@-o-keyframes scale{
  0%{
    -o-transform: scale(1,1);
  }
  100%{
    -o-transform: scale(.5,.5);
  }   
}

@keyframes scale{
  0%{
    transform: scale(1,1);
  }
  100%{
    transform: scale(.5,.5);
  }   
}

@-webkit-keyframes behindCloudMove {
  0% {
    -webkit-transform: translateX(-1.75px) translateY(1.75px);
  }

  100% {
    -webkit-transform: translateX(0) translateY(0);
  }
}

@-moz-keyframes behindCloudMove {
  0% {
    -moz-transform: translateX(-1.75px) translateY(1.75px);
  }

  100% {
    -moz-transform: translateX(0) translateY(0);
  }
}

@-o-keyframes behindCloudMove {
  0% {
    -o-transform: translateX(-1.75px) translateY(1.75px);
  }

  100% {
    -o-transform: translateX(0) translateY(0);
  }
}

@keyframes behindCloudMove {
  0% {
    transform: translateX(-1.75px) translateY(1.75px);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
}

@-webkit-keyframes drizzleFall {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(21px);
  }
}

@-moz-keyframes drizzleFall {
  0% {
    -moz-transform: translateY(0);
  }

  100% {
    -moz-transform: translateY(21px);
  }
}        

@-o-keyframes drizzleFall {
  0% {
    -o-transform: translateY(0);
  }

  100% {
    -o-transform: translateY(21px);
  }
}       

@keyframes drizzleFall {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(21px);
  }
}

@-webkit-keyframes rainFall {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(14px);
  }
}

@-moz-keyframes rainFall {
  0% {
    -moz-transform: translateY(0);
  }

  100% {
    -moz-transform: translateY(14px);
  }
}

@-o-keyframes rainFall {
  0% {
    -o-transform: translateY(0);
  }

  100% {
    -o-transform: translateY(14px);
  }
}

@keyframes rainFall {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(14px);
  }
}

@-webkit-keyframes rainFall2 {
  0% {
    -webkit-transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(14px);
  }

  100% {
    -webkit-transform: translateX(14px) translateY(14px);
  }
}

@-moz-keyframes rainFall2 {
  0% {
    -moz-transform: translateY(0);
  }

  50% {
    -moz-transform: translateY(14px);
  }

  100% {
    -moz-transform: translateX(14px) translateY(14px);
  }
}

@-o-keyframes rainFall2 {
  0% {
    -o-transform: translateY(0);
  }

  50% {
    -o-transform: translateY(14px);
  }

  100% {
    -o-transform: translateX(14px) translateY(14px);
  }
}

@keyframes rainFall2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(14px);
  }

  100% {
    transform: translateX(14px) translateY(14px);
  }
}

@-webkit-keyframes hailLeft {
  50% {
    -webkit-transform: translateY(17.5px);
  }

  51% {
    -webkit-transform: translateY(17.5px) translateX(-0.3px);
  }

  52% {
    -webkit-transform: translateY(16.95058px) translateX(-0.6px);
  }

  53% {
    -webkit-transform: translateY(16.40333px) translateX(-0.9px);
  }

  54% {
    -webkit-transform: translateY(15.86041px) translateX(-1.2px);
  }

  55% {
    -webkit-transform: translateY(15.32396px) translateX(-1.5px);
  }

  56% {
    -webkit-transform: translateY(14.7961px) translateX(-1.8px);
  }

  57% {
    -webkit-transform: translateY(14.27891px) translateX(-2.1px);
  }

  58% {
    -webkit-transform: translateY(13.77443px) translateX(-2.4px);
  }

  59% {
    -webkit-transform: translateY(13.28466px) translateX(-2.7px);
  }

  60% {
    -webkit-transform: translateY(12.81152px) translateX(-3px);
  }

  61% {
    -webkit-transform: translateY(12.35688px) translateX(-3.3px);
  }

  62% {
    -webkit-transform: translateY(11.92254px) translateX(-3.6px);
  }

  63% {
    -webkit-transform: translateY(11.51021px) translateX(-3.9px);
  }

  64% {
    -webkit-transform: translateY(11.12152px) translateX(-4.2px);
  }

  65% {
    -webkit-transform: translateY(10.75801px) translateX(-4.5px);
  }

  66% {
    -webkit-transform: translateY(10.4211px) translateX(-4.8px);
  }

  67% {
    -webkit-transform: translateY(10.11213px) translateX(-5.1px);
  }

  68% {
    -webkit-transform: translateY(9.83232px) translateX(-5.4px);
  }

  69% {
    -webkit-transform: translateY(9.58276px) translateX(-5.7px);
  }

  70% {
    -webkit-transform: translateY(9.36446px) translateX(-6px);
  }

  71% {
    -webkit-transform: translateY(9.17826px) translateX(-6.3px);
  }

  72% {
    -webkit-transform: translateY(9.0249px) translateX(-6.6px);
  }

  73% {
    -webkit-transform: translateY(8.90499px) translateX(-6.9px);
  }

  74% {
    -webkit-transform: translateY(8.819px) translateX(-7.2px);
  }

  75% {
    -webkit-transform: translateY(8.76727px) translateX(-7.5px);
  }

  76% {
    -webkit-transform: translateY(8.75px) translateX(-7.8px);
  }

  77% {
    -webkit-transform: translateY(8.76727px) translateX(-8.1px);
  }

  78% {
    -webkit-transform: translateY(8.819px) translateX(-8.4px);
  }

  79% {
    -webkit-transform: translateY(8.90499px) translateX(-8.7px);
  }

  80% {
    -webkit-transform: translateY(9.0249px) translateX(-9px);
  }

  81% {
    -webkit-transform: translateY(9.17826px) translateX(-9.3px);
  }

  82% {
    -webkit-transform: translateY(9.36446px) translateX(-9.6px);
  }

  83% {
    -webkit-transform: translateY(9.58276px) translateX(-9.9px);
  }

  84% {
    -webkit-transform: translateY(9.83232px) translateX(-10.2px);
  }

  85% {
    -webkit-transform: translateY(10.11213px) translateX(-10.5px);
  }

  86% {
    -webkit-transform: translateY(10.4211px) translateX(-10.8px);
  }

  87% {
    -webkit-transform: translateY(10.75801px) translateX(-11.1px);
  }

  88% {
    -webkit-transform: translateY(11.12152px) translateX(-11.4px);
  }

  89% {
    -webkit-transform: translateY(11.51021px) translateX(-11.7px);
  }

  90% {
    -webkit-transform: translateY(11.92254px) translateX(-12px);
  }

  91% {
    -webkit-transform: translateY(12.35688px) translateX(-12.3px);
  }

  92% {
    -webkit-transform: translateY(12.81152px) translateX(-12.6px);
  }

  93% {
    -webkit-transform: translateY(13.28466px) translateX(-12.9px);
  }

  94% {
    -webkit-transform: translateY(13.77443px) translateX(-13.2px);
  }

  95% {
    -webkit-transform: translateY(14.27891px) translateX(-13.5px);
  }

  96% {
    -webkit-transform: translateY(14.7961px) translateX(-13.8px);
  }

  97% {
    -webkit-transform: translateY(15.32396px) translateX(-14.1px);
  }

  98% {
    -webkit-transform: translateY(15.86041px) translateX(-14.4px);
  }

  99% {
    -webkit-transform: translateY(16.40333px) translateX(-14.7px);
  }

  100% {
    -webkit-transform: translateY(16.95058px) translateX(-15px);
  }
}

@-webkit-keyframes hailMiddle {
  50% {
    -webkit-transform: translateY(17.5px);
  }

  51% {
    -webkit-transform: translateY(17.5px) translateX(-0.15px);
  }

  52% {
    -webkit-transform: translateY(16.95058px) translateX(-0.3px);
  }

  53% {
    -webkit-transform: translateY(16.40333px) translateX(-0.45px);
  }

  54% {
    -webkit-transform: translateY(15.86041px) translateX(-0.6px);
  }

  55% {
    -webkit-transform: translateY(15.32396px) translateX(-0.75px);
  }

  56% {
    -webkit-transform: translateY(14.7961px) translateX(-0.9px);
  }

  57% {
    -webkit-transform: translateY(14.27891px) translateX(-1.05px);
  }

  58% {
    -webkit-transform: translateY(13.77443px) translateX(-1.2px);
  }

  59% {
    -webkit-transform: translateY(13.28466px) translateX(-1.35px);
  }

  60% {
    -webkit-transform: translateY(12.81152px) translateX(-1.5px);
  }

  61% {
    -webkit-transform: translateY(12.35688px) translateX(-1.65px);
  }

  62% {
    -webkit-transform: translateY(11.92254px) translateX(-1.8px);
  }

  63% {
    -webkit-transform: translateY(11.51021px) translateX(-1.95px);
  }

  64% {
    -webkit-transform: translateY(11.12152px) translateX(-2.1px);
  }

  65% {
    -webkit-transform: translateY(10.75801px) translateX(-2.25px);
  }

  66% {
    -webkit-transform: translateY(10.4211px) translateX(-2.4px);
  }

  67% {
    -webkit-transform: translateY(10.11213px) translateX(-2.55px);
  }

  68% {
    -webkit-transform: translateY(9.83232px) translateX(-2.7px);
  }

  69% {
    -webkit-transform: translateY(9.58276px) translateX(-2.85px);
  }

  70% {
    -webkit-transform: translateY(9.36446px) translateX(-3px);
  }

  71% {
    -webkit-transform: translateY(9.17826px) translateX(-3.15px);
  }

  72% {
    -webkit-transform: translateY(9.0249px) translateX(-3.3px);
  }

  73% {
    -webkit-transform: translateY(8.90499px) translateX(-3.45px);
  }

  74% {
    -webkit-transform: translateY(8.819px) translateX(-3.6px);
  }

  75% {
    -webkit-transform: translateY(8.76727px) translateX(-3.75px);
  }

  76% {
    -webkit-transform: translateY(8.75px) translateX(-3.9px);
  }

  77% {
    -webkit-transform: translateY(8.76727px) translateX(-4.05px);
  }

  78% {
    -webkit-transform: translateY(8.819px) translateX(-4.2px);
  }

  79% {
    -webkit-transform: translateY(8.90499px) translateX(-4.35px);
  }

  80% {
    -webkit-transform: translateY(9.0249px) translateX(-4.5px);
  }

  81% {
    -webkit-transform: translateY(9.17826px) translateX(-4.65px);
  }

  82% {
    -webkit-transform: translateY(9.36446px) translateX(-4.8px);
  }

  83% {
    -webkit-transform: translateY(9.58276px) translateX(-4.95px);
  }

  84% {
    -webkit-transform: translateY(9.83232px) translateX(-5.1px);
  }

  85% {
    -webkit-transform: translateY(10.11213px) translateX(-5.25px);
  }

  86% {
    -webkit-transform: translateY(10.4211px) translateX(-5.4px);
  }

  87% {
    -webkit-transform: translateY(10.75801px) translateX(-5.55px);
  }

  88% {
    -webkit-transform: translateY(11.12152px) translateX(-5.7px);
  }

  89% {
    -webkit-transform: translateY(11.51021px) translateX(-5.85px);
  }

  90% {
    -webkit-transform: translateY(11.92254px) translateX(-6px);
  }

  91% {
    -webkit-transform: translateY(12.35688px) translateX(-6.15px);
  }

  92% {
    -webkit-transform: translateY(12.81152px) translateX(-6.3px);
  }

  93% {
    -webkit-transform: translateY(13.28466px) translateX(-6.45px);
  }

  94% {
    -webkit-transform: translateY(13.77443px) translateX(-6.6px);
  }

  95% {
    -webkit-transform: translateY(14.27891px) translateX(-6.75px);
  }

  96% {
    -webkit-transform: translateY(14.7961px) translateX(-6.9px);
  }

  97% {
    -webkit-transform: translateY(15.32396px) translateX(-7.05px);
  }

  98% {
    -webkit-transform: translateY(15.86041px) translateX(-7.2px);
  }

  99% {
    -webkit-transform: translateY(16.40333px) translateX(-7.35px);
  }

  100% {
    -webkit-transform: translateY(16.95058px) translateX(-7.5px);
  }
}

@-webkit-keyframes hailMiddle2 {
  50% {
    -webkit-transform: translateY(17.5px);
  }

  51% {
    -webkit-transform: translateY(17.5px) translateX(0.15px);
  }

  52% {
    -webkit-transform: translateY(16.95058px) translateX(0.3px);
  }

  53% {
    -webkit-transform: translateY(16.40333px) translateX(0.45px);
  }

  54% {
    -webkit-transform: translateY(15.86041px) translateX(0.6px);
  }

  55% {
    -webkit-transform: translateY(15.32396px) translateX(0.75px);
  }

  56% {
    -webkit-transform: translateY(14.7961px) translateX(0.9px);
  }

  57% {
    -webkit-transform: translateY(14.27891px) translateX(1.05px);
  }

  58% {
    -webkit-transform: translateY(13.77443px) translateX(1.2px);
  }

  59% {
    -webkit-transform: translateY(13.28466px) translateX(1.35px);
  }

  60% {
    -webkit-transform: translateY(12.81152px) translateX(1.5px);
  }

  61% {
    -webkit-transform: translateY(12.35688px) translateX(1.65px);
  }

  62% {
    -webkit-transform: translateY(11.92254px) translateX(1.8px);
  }

  63% {
    -webkit-transform: translateY(11.51021px) translateX(1.95px);
  }

  64% {
    -webkit-transform: translateY(11.12152px) translateX(2.1px);
  }

  65% {
    -webkit-transform: translateY(10.75801px) translateX(2.25px);
  }

  66% {
    -webkit-transform: translateY(10.4211px) translateX(2.4px);
  }

  67% {
    -webkit-transform: translateY(10.11213px) translateX(2.55px);
  }

  68% {
    -webkit-transform: translateY(9.83232px) translateX(2.7px);
  }

  69% {
    -webkit-transform: translateY(9.58276px) translateX(2.85px);
  }

  70% {
    -webkit-transform: translateY(9.36446px) translateX(3px);
  }

  71% {
    -webkit-transform: translateY(9.17826px) translateX(3.15px);
  }

  72% {
    -webkit-transform: translateY(9.0249px) translateX(3.3px);
  }

  73% {
    -webkit-transform: translateY(8.90499px) translateX(3.45px);
  }

  74% {
    -webkit-transform: translateY(8.819px) translateX(3.6px);
  }

  75% {
    -webkit-transform: translateY(8.76727px) translateX(3.75px);
  }

  76% {
    -webkit-transform: translateY(8.75px) translateX(3.9px);
  }

  77% {
    -webkit-transform: translateY(8.76727px) translateX(4.05px);
  }

  78% {
    -webkit-transform: translateY(8.819px) translateX(4.2px);
  }

  79% {
    -webkit-transform: translateY(8.90499px) translateX(4.35px);
  }

  80% {
    -webkit-transform: translateY(9.0249px) translateX(4.5px);
  }

  81% {
    -webkit-transform: translateY(9.17826px) translateX(4.65px);
  }

  82% {
    -webkit-transform: translateY(9.36446px) translateX(4.8px);
  }

  83% {
    -webkit-transform: translateY(9.58276px) translateX(4.95px);
  }

  84% {
    -webkit-transform: translateY(9.83232px) translateX(5.1px);
  }

  85% {
    -webkit-transform: translateY(10.11213px) translateX(5.25px);
  }

  86% {
    -webkit-transform: translateY(10.4211px) translateX(5.4px);
  }

  87% {
    -webkit-transform: translateY(10.75801px) translateX(5.55px);
  }

  88% {
    -webkit-transform: translateY(11.12152px) translateX(5.7px);
  }

  89% {
    -webkit-transform: translateY(11.51021px) translateX(5.85px);
  }

  90% {
    -webkit-transform: translateY(11.92254px) translateX(6px);
  }

  91% {
    -webkit-transform: translateY(12.35688px) translateX(6.15px);
  }

  92% {
    -webkit-transform: translateY(12.81152px) translateX(6.3px);
  }

  93% {
    -webkit-transform: translateY(13.28466px) translateX(6.45px);
  }

  94% {
    -webkit-transform: translateY(13.77443px) translateX(6.6px);
  }

  95% {
    -webkit-transform: translateY(14.27891px) translateX(6.75px);
  }

  96% {
    -webkit-transform: translateY(14.7961px) translateX(6.9px);
  }

  97% {
    -webkit-transform: translateY(15.32396px) translateX(7.05px);
  }

  98% {
    -webkit-transform: translateY(15.86041px) translateX(7.2px);
  }

  99% {
    -webkit-transform: translateY(16.40333px) translateX(7.35px);
  }

  100% {
    -webkit-transform: translateY(16.95058px) translateX(7.5px);
  }
}

@-webkit-keyframes hailRight {
  50% {
    -webkit-transform: translateY(17.5px);
  }

  51% {
    -webkit-transform: translateY(17.5px) translateX(0.3px);
  }

  52% {
    -webkit-transform: translateY(16.95058px) translateX(0.6px);
  }

  53% {
    -webkit-transform: translateY(16.40333px) translateX(0.9px);
  }

  54% {
    -webkit-transform: translateY(15.86041px) translateX(1.2px);
  }

  55% {
    -webkit-transform: translateY(15.32396px) translateX(1.5px);
  }

  56% {
    -webkit-transform: translateY(14.7961px) translateX(1.8px);
  }

  57% {
    -webkit-transform: translateY(14.27891px) translateX(2.1px);
  }

  58% {
    -webkit-transform: translateY(13.77443px) translateX(2.4px);
  }

  59% {
    -webkit-transform: translateY(13.28466px) translateX(2.7px);
  }

  60% {
    -webkit-transform: translateY(12.81152px) translateX(3px);
  }

  61% {
    -webkit-transform: translateY(12.35688px) translateX(3.3px);
  }

  62% {
    -webkit-transform: translateY(11.92254px) translateX(3.6px);
  }

  63% {
    -webkit-transform: translateY(11.51021px) translateX(3.9px);
  }

  64% {
    -webkit-transform: translateY(11.12152px) translateX(4.2px);
  }

  65% {
    -webkit-transform: translateY(10.75801px) translateX(4.5px);
  }

  66% {
    -webkit-transform: translateY(10.4211px) translateX(4.8px);
  }

  67% {
    -webkit-transform: translateY(10.11213px) translateX(5.1px);
  }

  68% {
    -webkit-transform: translateY(9.83232px) translateX(5.4px);
  }

  69% {
    -webkit-transform: translateY(9.58276px) translateX(5.7px);
  }

  70% {
    -webkit-transform: translateY(9.36446px) translateX(6px);
  }

  71% {
    -webkit-transform: translateY(9.17826px) translateX(6.3px);
  }

  72% {
    -webkit-transform: translateY(9.0249px) translateX(6.6px);
  }

  73% {
    -webkit-transform: translateY(8.90499px) translateX(6.9px);
  }

  74% {
    -webkit-transform: translateY(8.819px) translateX(7.2px);
  }

  75% {
    -webkit-transform: translateY(8.76727px) translateX(7.5px);
  }

  76% {
    -webkit-transform: translateY(8.75px) translateX(7.8px);
  }

  77% {
    -webkit-transform: translateY(8.76727px) translateX(8.1px);
  }

  78% {
    -webkit-transform: translateY(8.819px) translateX(8.4px);
  }

  79% {
    -webkit-transform: translateY(8.90499px) translateX(8.7px);
  }

  80% {
    -webkit-transform: translateY(9.0249px) translateX(9px);
  }

  81% {
    -webkit-transform: translateY(9.17826px) translateX(9.3px);
  }

  82% {
    -webkit-transform: translateY(9.36446px) translateX(9.6px);
  }

  83% {
    -webkit-transform: translateY(9.58276px) translateX(9.9px);
  }

  84% {
    -webkit-transform: translateY(9.83232px) translateX(10.2px);
  }

  85% {
    -webkit-transform: translateY(10.11213px) translateX(10.5px);
  }

  86% {
    -webkit-transform: translateY(10.4211px) translateX(10.8px);
  }

  87% {
    -webkit-transform: translateY(10.75801px) translateX(11.1px);
  }

  88% {
    -webkit-transform: translateY(11.12152px) translateX(11.4px);
  }

  89% {
    -webkit-transform: translateY(11.51021px) translateX(11.7px);
  }

  90% {
    -webkit-transform: translateY(11.92254px) translateX(12px);
  }

  91% {
    -webkit-transform: translateY(12.35688px) translateX(12.3px);
  }

  92% {
    -webkit-transform: translateY(12.81152px) translateX(12.6px);
  }

  93% {
    -webkit-transform: translateY(13.28466px) translateX(12.9px);
  }

  94% {
    -webkit-transform: translateY(13.77443px) translateX(13.2px);
  }

  95% {
    -webkit-transform: translateY(14.27891px) translateX(13.5px);
  }

  96% {
    -webkit-transform: translateY(14.7961px) translateX(13.8px);
  }

  97% {
    -webkit-transform: translateY(15.32396px) translateX(14.1px);
  }

  98% {
    -webkit-transform: translateY(15.86041px) translateX(14.4px);
  }

  99% {
    -webkit-transform: translateY(16.40333px) translateX(14.7px);
  }

  100% {
    -webkit-transform: translateY(16.95058px) translateX(15px);
  }
}

@-moz-keyframes hailLeft {
  50% {
    -moz-transform: translateY(17.5px);
  }

  51% {
    -moz-transform: translateY(17.5px) translateX(-0.3px);
  }

  52% {
    -moz-transform: translateY(16.95058px) translateX(-0.6px);
  }

  53% {
    -moz-transform: translateY(16.40333px) translateX(-0.9px);
  }

  54% {
    -moz-transform: translateY(15.86041px) translateX(-1.2px);
  }

  55% {
    -moz-transform: translateY(15.32396px) translateX(-1.5px);
  }

  56% {
    -moz-transform: translateY(14.7961px) translateX(-1.8px);
  }

  57% {
    -moz-transform: translateY(14.27891px) translateX(-2.1px);
  }

  58% {
    -moz-transform: translateY(13.77443px) translateX(-2.4px);
  }

  59% {
    -moz-transform: translateY(13.28466px) translateX(-2.7px);
  }

  60% {
    -moz-transform: translateY(12.81152px) translateX(-3px);
  }

  61% {
    -moz-transform: translateY(12.35688px) translateX(-3.3px);
  }

  62% {
    -moz-transform: translateY(11.92254px) translateX(-3.6px);
  }

  63% {
    -moz-transform: translateY(11.51021px) translateX(-3.9px);
  }

  64% {
    -moz-transform: translateY(11.12152px) translateX(-4.2px);
  }

  65% {
    -moz-transform: translateY(10.75801px) translateX(-4.5px);
  }

  66% {
    -moz-transform: translateY(10.4211px) translateX(-4.8px);
  }

  67% {
    -moz-transform: translateY(10.11213px) translateX(-5.1px);
  }

  68% {
    -moz-transform: translateY(9.83232px) translateX(-5.4px);
  }

  69% {
    -moz-transform: translateY(9.58276px) translateX(-5.7px);
  }

  70% {
    -moz-transform: translateY(9.36446px) translateX(-6px);
  }

  71% {
    -moz-transform: translateY(9.17826px) translateX(-6.3px);
  }

  72% {
    -moz-transform: translateY(9.0249px) translateX(-6.6px);
  }

  73% {
    -moz-transform: translateY(8.90499px) translateX(-6.9px);
  }

  74% {
    -moz-transform: translateY(8.819px) translateX(-7.2px);
  }

  75% {
    -moz-transform: translateY(8.76727px) translateX(-7.5px);
  }

  76% {
    -moz-transform: translateY(8.75px) translateX(-7.8px);
  }

  77% {
    -moz-transform: translateY(8.76727px) translateX(-8.1px);
  }

  78% {
    -moz-transform: translateY(8.819px) translateX(-8.4px);
  }

  79% {
    -moz-transform: translateY(8.90499px) translateX(-8.7px);
  }

  80% {
    -moz-transform: translateY(9.0249px) translateX(-9px);
  }

  81% {
    -moz-transform: translateY(9.17826px) translateX(-9.3px);
  }

  82% {
    -moz-transform: translateY(9.36446px) translateX(-9.6px);
  }

  83% {
    -moz-transform: translateY(9.58276px) translateX(-9.9px);
  }

  84% {
    -moz-transform: translateY(9.83232px) translateX(-10.2px);
  }

  85% {
    -moz-transform: translateY(10.11213px) translateX(-10.5px);
  }

  86% {
    -moz-transform: translateY(10.4211px) translateX(-10.8px);
  }

  87% {
    -moz-transform: translateY(10.75801px) translateX(-11.1px);
  }

  88% {
    -moz-transform: translateY(11.12152px) translateX(-11.4px);
  }

  89% {
    -moz-transform: translateY(11.51021px) translateX(-11.7px);
  }

  90% {
    -moz-transform: translateY(11.92254px) translateX(-12px);
  }

  91% {
    -moz-transform: translateY(12.35688px) translateX(-12.3px);
  }

  92% {
    -moz-transform: translateY(12.81152px) translateX(-12.6px);
  }

  93% {
    -moz-transform: translateY(13.28466px) translateX(-12.9px);
  }

  94% {
    -moz-transform: translateY(13.77443px) translateX(-13.2px);
  }

  95% {
    -moz-transform: translateY(14.27891px) translateX(-13.5px);
  }

  96% {
    -moz-transform: translateY(14.7961px) translateX(-13.8px);
  }

  97% {
    -moz-transform: translateY(15.32396px) translateX(-14.1px);
  }

  98% {
    -moz-transform: translateY(15.86041px) translateX(-14.4px);
  }

  99% {
    -moz-transform: translateY(16.40333px) translateX(-14.7px);
  }

  100% {
    -moz-transform: translateY(16.95058px) translateX(-15px);
  }
}

@-moz-keyframes hailMiddle {
  50% {
    -moz-transform: translateY(17.5px);
  }

  51% {
    -moz-transform: translateY(17.5px) translateX(-0.15px);
  }

  52% {
    -moz-transform: translateY(16.95058px) translateX(-0.3px);
  }

  53% {
    -moz-transform: translateY(16.40333px) translateX(-0.45px);
  }

  54% {
    -moz-transform: translateY(15.86041px) translateX(-0.6px);
  }

  55% {
    -moz-transform: translateY(15.32396px) translateX(-0.75px);
  }

  56% {
    -moz-transform: translateY(14.7961px) translateX(-0.9px);
  }

  57% {
    -moz-transform: translateY(14.27891px) translateX(-1.05px);
  }

  58% {
    -moz-transform: translateY(13.77443px) translateX(-1.2px);
  }

  59% {
    -moz-transform: translateY(13.28466px) translateX(-1.35px);
  }

  60% {
    -moz-transform: translateY(12.81152px) translateX(-1.5px);
  }

  61% {
    -moz-transform: translateY(12.35688px) translateX(-1.65px);
  }

  62% {
    -moz-transform: translateY(11.92254px) translateX(-1.8px);
  }

  63% {
    -moz-transform: translateY(11.51021px) translateX(-1.95px);
  }

  64% {
    -moz-transform: translateY(11.12152px) translateX(-2.1px);
  }

  65% {
    -moz-transform: translateY(10.75801px) translateX(-2.25px);
  }

  66% {
    -moz-transform: translateY(10.4211px) translateX(-2.4px);
  }

  67% {
    -moz-transform: translateY(10.11213px) translateX(-2.55px);
  }

  68% {
    -moz-transform: translateY(9.83232px) translateX(-2.7px);
  }

  69% {
    -moz-transform: translateY(9.58276px) translateX(-2.85px);
  }

  70% {
    -moz-transform: translateY(9.36446px) translateX(-3px);
  }

  71% {
    -moz-transform: translateY(9.17826px) translateX(-3.15px);
  }

  72% {
    -moz-transform: translateY(9.0249px) translateX(-3.3px);
  }

  73% {
    -moz-transform: translateY(8.90499px) translateX(-3.45px);
  }

  74% {
    -moz-transform: translateY(8.819px) translateX(-3.6px);
  }

  75% {
    -moz-transform: translateY(8.76727px) translateX(-3.75px);
  }

  76% {
    -moz-transform: translateY(8.75px) translateX(-3.9px);
  }

  77% {
    -moz-transform: translateY(8.76727px) translateX(-4.05px);
  }

  78% {
    -moz-transform: translateY(8.819px) translateX(-4.2px);
  }

  79% {
    -moz-transform: translateY(8.90499px) translateX(-4.35px);
  }

  80% {
    -moz-transform: translateY(9.0249px) translateX(-4.5px);
  }

  81% {
    -moz-transform: translateY(9.17826px) translateX(-4.65px);
  }

  82% {
    -moz-transform: translateY(9.36446px) translateX(-4.8px);
  }

  83% {
    -moz-transform: translateY(9.58276px) translateX(-4.95px);
  }

  84% {
    -moz-transform: translateY(9.83232px) translateX(-5.1px);
  }

  85% {
    -moz-transform: translateY(10.11213px) translateX(-5.25px);
  }

  86% {
    -moz-transform: translateY(10.4211px) translateX(-5.4px);
  }

  87% {
    -moz-transform: translateY(10.75801px) translateX(-5.55px);
  }

  88% {
    -moz-transform: translateY(11.12152px) translateX(-5.7px);
  }

  89% {
    -moz-transform: translateY(11.51021px) translateX(-5.85px);
  }

  90% {
    -moz-transform: translateY(11.92254px) translateX(-6px);
  }

  91% {
    -moz-transform: translateY(12.35688px) translateX(-6.15px);
  }

  92% {
    -moz-transform: translateY(12.81152px) translateX(-6.3px);
  }

  93% {
    -moz-transform: translateY(13.28466px) translateX(-6.45px);
  }

  94% {
    -moz-transform: translateY(13.77443px) translateX(-6.6px);
  }

  95% {
    -moz-transform: translateY(14.27891px) translateX(-6.75px);
  }

  96% {
    -moz-transform: translateY(14.7961px) translateX(-6.9px);
  }

  97% {
    -moz-transform: translateY(15.32396px) translateX(-7.05px);
  }

  98% {
    -moz-transform: translateY(15.86041px) translateX(-7.2px);
  }

  99% {
    -moz-transform: translateY(16.40333px) translateX(-7.35px);
  }

  100% {
    -moz-transform: translateY(16.95058px) translateX(-7.5px);
  }
}

@-moz-keyframes hailMiddle2 {
  50% {
    -moz-transform: translateY(17.5px);
  }

  51% {
    -moz-transform: translateY(17.5px) translateX(0.15px);
  }

  52% {
    -moz-transform: translateY(16.95058px) translateX(0.3px);
  }

  53% {
    -moz-transform: translateY(16.40333px) translateX(0.45px);
  }

  54% {
    -moz-transform: translateY(15.86041px) translateX(0.6px);
  }

  55% {
    -moz-transform: translateY(15.32396px) translateX(0.75px);
  }

  56% {
    -moz-transform: translateY(14.7961px) translateX(0.9px);
  }

  57% {
    -moz-transform: translateY(14.27891px) translateX(1.05px);
  }

  58% {
    -moz-transform: translateY(13.77443px) translateX(1.2px);
  }

  59% {
    -moz-transform: translateY(13.28466px) translateX(1.35px);
  }

  60% {
    -moz-transform: translateY(12.81152px) translateX(1.5px);
  }

  61% {
    -moz-transform: translateY(12.35688px) translateX(1.65px);
  }

  62% {
    -moz-transform: translateY(11.92254px) translateX(1.8px);
  }

  63% {
    -moz-transform: translateY(11.51021px) translateX(1.95px);
  }

  64% {
    -moz-transform: translateY(11.12152px) translateX(2.1px);
  }

  65% {
    -moz-transform: translateY(10.75801px) translateX(2.25px);
  }

  66% {
    -moz-transform: translateY(10.4211px) translateX(2.4px);
  }

  67% {
    -moz-transform: translateY(10.11213px) translateX(2.55px);
  }

  68% {
    -moz-transform: translateY(9.83232px) translateX(2.7px);
  }

  69% {
    -moz-transform: translateY(9.58276px) translateX(2.85px);
  }

  70% {
    -moz-transform: translateY(9.36446px) translateX(3px);
  }

  71% {
    -moz-transform: translateY(9.17826px) translateX(3.15px);
  }

  72% {
    -moz-transform: translateY(9.0249px) translateX(3.3px);
  }

  73% {
    -moz-transform: translateY(8.90499px) translateX(3.45px);
  }

  74% {
    -moz-transform: translateY(8.819px) translateX(3.6px);
  }

  75% {
    -moz-transform: translateY(8.76727px) translateX(3.75px);
  }

  76% {
    -moz-transform: translateY(8.75px) translateX(3.9px);
  }

  77% {
    -moz-transform: translateY(8.76727px) translateX(4.05px);
  }

  78% {
    -moz-transform: translateY(8.819px) translateX(4.2px);
  }

  79% {
    -moz-transform: translateY(8.90499px) translateX(4.35px);
  }

  80% {
    -moz-transform: translateY(9.0249px) translateX(4.5px);
  }

  81% {
    -moz-transform: translateY(9.17826px) translateX(4.65px);
  }

  82% {
    -moz-transform: translateY(9.36446px) translateX(4.8px);
  }

  83% {
    -moz-transform: translateY(9.58276px) translateX(4.95px);
  }

  84% {
    -moz-transform: translateY(9.83232px) translateX(5.1px);
  }

  85% {
    -moz-transform: translateY(10.11213px) translateX(5.25px);
  }

  86% {
    -moz-transform: translateY(10.4211px) translateX(5.4px);
  }

  87% {
    -moz-transform: translateY(10.75801px) translateX(5.55px);
  }

  88% {
    -moz-transform: translateY(11.12152px) translateX(5.7px);
  }

  89% {
    -moz-transform: translateY(11.51021px) translateX(5.85px);
  }

  90% {
    -moz-transform: translateY(11.92254px) translateX(6px);
  }

  91% {
    -moz-transform: translateY(12.35688px) translateX(6.15px);
  }

  92% {
    -moz-transform: translateY(12.81152px) translateX(6.3px);
  }

  93% {
    -moz-transform: translateY(13.28466px) translateX(6.45px);
  }

  94% {
    -moz-transform: translateY(13.77443px) translateX(6.6px);
  }

  95% {
    -moz-transform: translateY(14.27891px) translateX(6.75px);
  }

  96% {
    -moz-transform: translateY(14.7961px) translateX(6.9px);
  }

  97% {
    -moz-transform: translateY(15.32396px) translateX(7.05px);
  }

  98% {
    -moz-transform: translateY(15.86041px) translateX(7.2px);
  }

  99% {
    -moz-transform: translateY(16.40333px) translateX(7.35px);
  }

  100% {
    -moz-transform: translateY(16.95058px) translateX(7.5px);
  }
}

@-moz-keyframes hailRight {
  50% {
    -moz-transform: translateY(17.5px);
  }

  51% {
    -moz-transform: translateY(17.5px) translateX(0.3px);
  }

  52% {
    -moz-transform: translateY(16.95058px) translateX(0.6px);
  }

  53% {
    -moz-transform: translateY(16.40333px) translateX(0.9px);
  }

  54% {
    -moz-transform: translateY(15.86041px) translateX(1.2px);
  }

  55% {
    -moz-transform: translateY(15.32396px) translateX(1.5px);
  }

  56% {
    -moz-transform: translateY(14.7961px) translateX(1.8px);
  }

  57% {
    -moz-transform: translateY(14.27891px) translateX(2.1px);
  }

  58% {
    -moz-transform: translateY(13.77443px) translateX(2.4px);
  }

  59% {
    -moz-transform: translateY(13.28466px) translateX(2.7px);
  }

  60% {
    -moz-transform: translateY(12.81152px) translateX(3px);
  }

  61% {
    -moz-transform: translateY(12.35688px) translateX(3.3px);
  }

  62% {
    -moz-transform: translateY(11.92254px) translateX(3.6px);
  }

  63% {
    -moz-transform: translateY(11.51021px) translateX(3.9px);
  }

  64% {
    -moz-transform: translateY(11.12152px) translateX(4.2px);
  }

  65% {
    -moz-transform: translateY(10.75801px) translateX(4.5px);
  }

  66% {
    -moz-transform: translateY(10.4211px) translateX(4.8px);
  }

  67% {
    -moz-transform: translateY(10.11213px) translateX(5.1px);
  }

  68% {
    -moz-transform: translateY(9.83232px) translateX(5.4px);
  }

  69% {
    -moz-transform: translateY(9.58276px) translateX(5.7px);
  }

  70% {
    -moz-transform: translateY(9.36446px) translateX(6px);
  }

  71% {
    -moz-transform: translateY(9.17826px) translateX(6.3px);
  }

  72% {
    -moz-transform: translateY(9.0249px) translateX(6.6px);
  }

  73% {
    -moz-transform: translateY(8.90499px) translateX(6.9px);
  }

  74% {
    -moz-transform: translateY(8.819px) translateX(7.2px);
  }

  75% {
    -moz-transform: translateY(8.76727px) translateX(7.5px);
  }

  76% {
    -moz-transform: translateY(8.75px) translateX(7.8px);
  }

  77% {
    -moz-transform: translateY(8.76727px) translateX(8.1px);
  }

  78% {
    -moz-transform: translateY(8.819px) translateX(8.4px);
  }

  79% {
    -moz-transform: translateY(8.90499px) translateX(8.7px);
  }

  80% {
    -moz-transform: translateY(9.0249px) translateX(9px);
  }

  81% {
    -moz-transform: translateY(9.17826px) translateX(9.3px);
  }

  82% {
    -moz-transform: translateY(9.36446px) translateX(9.6px);
  }

  83% {
    -moz-transform: translateY(9.58276px) translateX(9.9px);
  }

  84% {
    -moz-transform: translateY(9.83232px) translateX(10.2px);
  }

  85% {
    -moz-transform: translateY(10.11213px) translateX(10.5px);
  }

  86% {
    -moz-transform: translateY(10.4211px) translateX(10.8px);
  }

  87% {
    -moz-transform: translateY(10.75801px) translateX(11.1px);
  }

  88% {
    -moz-transform: translateY(11.12152px) translateX(11.4px);
  }

  89% {
    -moz-transform: translateY(11.51021px) translateX(11.7px);
  }

  90% {
    -moz-transform: translateY(11.92254px) translateX(12px);
  }

  91% {
    -moz-transform: translateY(12.35688px) translateX(12.3px);
  }

  92% {
    -moz-transform: translateY(12.81152px) translateX(12.6px);
  }

  93% {
    -moz-transform: translateY(13.28466px) translateX(12.9px);
  }

  94% {
    -moz-transform: translateY(13.77443px) translateX(13.2px);
  }

  95% {
    -moz-transform: translateY(14.27891px) translateX(13.5px);
  }

  96% {
    -moz-transform: translateY(14.7961px) translateX(13.8px);
  }

  97% {
    -moz-transform: translateY(15.32396px) translateX(14.1px);
  }

  98% {
    -moz-transform: translateY(15.86041px) translateX(14.4px);
  }

  99% {
    -moz-transform: translateY(16.40333px) translateX(14.7px);
  }

  100% {
    -moz-transform: translateY(16.95058px) translateX(15px);
  }
}

@-o-keyframes hailLeft {
  50% {
    -o-transform: translateY(17.5px);
  }

  51% {
    -o-transform: translateY(17.5px) translateX(-0.3px);
  }

  52% {
    -o-transform: translateY(16.95058px) translateX(-0.6px);
  }

  53% {
    -o-transform: translateY(16.40333px) translateX(-0.9px);
  }

  54% {
    -o-transform: translateY(15.86041px) translateX(-1.2px);
  }

  55% {
    -o-transform: translateY(15.32396px) translateX(-1.5px);
  }

  56% {
    -o-transform: translateY(14.7961px) translateX(-1.8px);
  }

  57% {
    -o-transform: translateY(14.27891px) translateX(-2.1px);
  }

  58% {
    -o-transform: translateY(13.77443px) translateX(-2.4px);
  }

  59% {
    -o-transform: translateY(13.28466px) translateX(-2.7px);
  }

  60% {
    -o-transform: translateY(12.81152px) translateX(-3px);
  }

  61% {
    -o-transform: translateY(12.35688px) translateX(-3.3px);
  }

  62% {
    -o-transform: translateY(11.92254px) translateX(-3.6px);
  }

  63% {
    -o-transform: translateY(11.51021px) translateX(-3.9px);
  }

  64% {
    -o-transform: translateY(11.12152px) translateX(-4.2px);
  }

  65% {
    -o-transform: translateY(10.75801px) translateX(-4.5px);
  }

  66% {
    -o-transform: translateY(10.4211px) translateX(-4.8px);
  }

  67% {
    -o-transform: translateY(10.11213px) translateX(-5.1px);
  }

  68% {
    -o-transform: translateY(9.83232px) translateX(-5.4px);
  }

  69% {
    -o-transform: translateY(9.58276px) translateX(-5.7px);
  }

  70% {
    -o-transform: translateY(9.36446px) translateX(-6px);
  }

  71% {
    -o-transform: translateY(9.17826px) translateX(-6.3px);
  }

  72% {
    -o-transform: translateY(9.0249px) translateX(-6.6px);
  }

  73% {
    -o-transform: translateY(8.90499px) translateX(-6.9px);
  }

  74% {
    -o-transform: translateY(8.819px) translateX(-7.2px);
  }

  75% {
    -o-transform: translateY(8.76727px) translateX(-7.5px);
  }

  76% {
    -o-transform: translateY(8.75px) translateX(-7.8px);
  }

  77% {
    -o-transform: translateY(8.76727px) translateX(-8.1px);
  }

  78% {
    -o-transform: translateY(8.819px) translateX(-8.4px);
  }

  79% {
    -o-transform: translateY(8.90499px) translateX(-8.7px);
  }

  80% {
    -o-transform: translateY(9.0249px) translateX(-9px);
  }

  81% {
    -o-transform: translateY(9.17826px) translateX(-9.3px);
  }

  82% {
    -o-transform: translateY(9.36446px) translateX(-9.6px);
  }

  83% {
    -o-transform: translateY(9.58276px) translateX(-9.9px);
  }

  84% {
    -o-transform: translateY(9.83232px) translateX(-10.2px);
  }

  85% {
    -o-transform: translateY(10.11213px) translateX(-10.5px);
  }

  86% {
    -o-transform: translateY(10.4211px) translateX(-10.8px);
  }

  87% {
    -o-transform: translateY(10.75801px) translateX(-11.1px);
  }

  88% {
    -o-transform: translateY(11.12152px) translateX(-11.4px);
  }

  89% {
    -o-transform: translateY(11.51021px) translateX(-11.7px);
  }

  90% {
    -o-transform: translateY(11.92254px) translateX(-12px);
  }

  91% {
    -o-transform: translateY(12.35688px) translateX(-12.3px);
  }

  92% {
    -o-transform: translateY(12.81152px) translateX(-12.6px);
  }

  93% {
    -o-transform: translateY(13.28466px) translateX(-12.9px);
  }

  94% {
    -o-transform: translateY(13.77443px) translateX(-13.2px);
  }

  95% {
    -o-transform: translateY(14.27891px) translateX(-13.5px);
  }

  96% {
    -o-transform: translateY(14.7961px) translateX(-13.8px);
  }

  97% {
    -o-transform: translateY(15.32396px) translateX(-14.1px);
  }

  98% {
    -o-transform: translateY(15.86041px) translateX(-14.4px);
  }

  99% {
    -o-transform: translateY(16.40333px) translateX(-14.7px);
  }

  100% {
    -o-transform: translateY(16.95058px) translateX(-15px);
  }
}

@-o-keyframes hailMiddle {
  50% {
    -o-transform: translateY(17.5px);
  }

  51% {
    -o-transform: translateY(17.5px) translateX(-0.15px);
  }

  52% {
    -o-transform: translateY(16.95058px) translateX(-0.3px);
  }

  53% {
    -o-transform: translateY(16.40333px) translateX(-0.45px);
  }

  54% {
    -o-transform: translateY(15.86041px) translateX(-0.6px);
  }

  55% {
    -o-transform: translateY(15.32396px) translateX(-0.75px);
  }

  56% {
    -o-transform: translateY(14.7961px) translateX(-0.9px);
  }

  57% {
    -o-transform: translateY(14.27891px) translateX(-1.05px);
  }

  58% {
    -o-transform: translateY(13.77443px) translateX(-1.2px);
  }

  59% {
    -o-transform: translateY(13.28466px) translateX(-1.35px);
  }

  60% {
    -o-transform: translateY(12.81152px) translateX(-1.5px);
  }

  61% {
    -o-transform: translateY(12.35688px) translateX(-1.65px);
  }

  62% {
    -o-transform: translateY(11.92254px) translateX(-1.8px);
  }

  63% {
    -o-transform: translateY(11.51021px) translateX(-1.95px);
  }

  64% {
    -o-transform: translateY(11.12152px) translateX(-2.1px);
  }

  65% {
    -o-transform: translateY(10.75801px) translateX(-2.25px);
  }

  66% {
    -o-transform: translateY(10.4211px) translateX(-2.4px);
  }

  67% {
    -o-transform: translateY(10.11213px) translateX(-2.55px);
  }

  68% {
    -o-transform: translateY(9.83232px) translateX(-2.7px);
  }

  69% {
    -o-transform: translateY(9.58276px) translateX(-2.85px);
  }

  70% {
    -o-transform: translateY(9.36446px) translateX(-3px);
  }

  71% {
    -o-transform: translateY(9.17826px) translateX(-3.15px);
  }

  72% {
    -o-transform: translateY(9.0249px) translateX(-3.3px);
  }

  73% {
    -o-transform: translateY(8.90499px) translateX(-3.45px);
  }

  74% {
    -o-transform: translateY(8.819px) translateX(-3.6px);
  }

  75% {
    -o-transform: translateY(8.76727px) translateX(-3.75px);
  }

  76% {
    -o-transform: translateY(8.75px) translateX(-3.9px);
  }

  77% {
    -o-transform: translateY(8.76727px) translateX(-4.05px);
  }

  78% {
    -o-transform: translateY(8.819px) translateX(-4.2px);
  }

  79% {
    -o-transform: translateY(8.90499px) translateX(-4.35px);
  }

  80% {
    -o-transform: translateY(9.0249px) translateX(-4.5px);
  }

  81% {
    -o-transform: translateY(9.17826px) translateX(-4.65px);
  }

  82% {
    -o-transform: translateY(9.36446px) translateX(-4.8px);
  }

  83% {
    -o-transform: translateY(9.58276px) translateX(-4.95px);
  }

  84% {
    -o-transform: translateY(9.83232px) translateX(-5.1px);
  }

  85% {
    -o-transform: translateY(10.11213px) translateX(-5.25px);
  }

  86% {
    -o-transform: translateY(10.4211px) translateX(-5.4px);
  }

  87% {
    -o-transform: translateY(10.75801px) translateX(-5.55px);
  }

  88% {
    -o-transform: translateY(11.12152px) translateX(-5.7px);
  }

  89% {
    -o-transform: translateY(11.51021px) translateX(-5.85px);
  }

  90% {
    -o-transform: translateY(11.92254px) translateX(-6px);
  }

  91% {
    -o-transform: translateY(12.35688px) translateX(-6.15px);
  }

  92% {
    -o-transform: translateY(12.81152px) translateX(-6.3px);
  }

  93% {
    -o-transform: translateY(13.28466px) translateX(-6.45px);
  }

  94% {
    -o-transform: translateY(13.77443px) translateX(-6.6px);
  }

  95% {
    -o-transform: translateY(14.27891px) translateX(-6.75px);
  }

  96% {
    -o-transform: translateY(14.7961px) translateX(-6.9px);
  }

  97% {
    -o-transform: translateY(15.32396px) translateX(-7.05px);
  }

  98% {
    -o-transform: translateY(15.86041px) translateX(-7.2px);
  }

  99% {
    -o-transform: translateY(16.40333px) translateX(-7.35px);
  }

  100% {
    -o-transform: translateY(16.95058px) translateX(-7.5px);
  }
}

@-o-keyframes hailMiddle2 {
  50% {
    -o-transform: translateY(17.5px);
  }

  51% {
    -o-transform: translateY(17.5px) translateX(0.15px);
  }

  52% {
    -o-transform: translateY(16.95058px) translateX(0.3px);
  }

  53% {
    -o-transform: translateY(16.40333px) translateX(0.45px);
  }

  54% {
    -o-transform: translateY(15.86041px) translateX(0.6px);
  }

  55% {
    -o-transform: translateY(15.32396px) translateX(0.75px);
  }

  56% {
    -o-transform: translateY(14.7961px) translateX(0.9px);
  }

  57% {
    -o-transform: translateY(14.27891px) translateX(1.05px);
  }

  58% {
    -o-transform: translateY(13.77443px) translateX(1.2px);
  }

  59% {
    -o-transform: translateY(13.28466px) translateX(1.35px);
  }

  60% {
    -o-transform: translateY(12.81152px) translateX(1.5px);
  }

  61% {
    -o-transform: translateY(12.35688px) translateX(1.65px);
  }

  62% {
    -o-transform: translateY(11.92254px) translateX(1.8px);
  }

  63% {
    -o-transform: translateY(11.51021px) translateX(1.95px);
  }

  64% {
    -o-transform: translateY(11.12152px) translateX(2.1px);
  }

  65% {
    -o-transform: translateY(10.75801px) translateX(2.25px);
  }

  66% {
    -o-transform: translateY(10.4211px) translateX(2.4px);
  }

  67% {
    -o-transform: translateY(10.11213px) translateX(2.55px);
  }

  68% {
    -o-transform: translateY(9.83232px) translateX(2.7px);
  }

  69% {
    -o-transform: translateY(9.58276px) translateX(2.85px);
  }

  70% {
    -o-transform: translateY(9.36446px) translateX(3px);
  }

  71% {
    -o-transform: translateY(9.17826px) translateX(3.15px);
  }

  72% {
    -o-transform: translateY(9.0249px) translateX(3.3px);
  }

  73% {
    -o-transform: translateY(8.90499px) translateX(3.45px);
  }

  74% {
    -o-transform: translateY(8.819px) translateX(3.6px);
  }

  75% {
    -o-transform: translateY(8.76727px) translateX(3.75px);
  }

  76% {
    -o-transform: translateY(8.75px) translateX(3.9px);
  }

  77% {
    -o-transform: translateY(8.76727px) translateX(4.05px);
  }

  78% {
    -o-transform: translateY(8.819px) translateX(4.2px);
  }

  79% {
    -o-transform: translateY(8.90499px) translateX(4.35px);
  }

  80% {
    -o-transform: translateY(9.0249px) translateX(4.5px);
  }

  81% {
    -o-transform: translateY(9.17826px) translateX(4.65px);
  }

  82% {
    -o-transform: translateY(9.36446px) translateX(4.8px);
  }

  83% {
    -o-transform: translateY(9.58276px) translateX(4.95px);
  }

  84% {
    -o-transform: translateY(9.83232px) translateX(5.1px);
  }

  85% {
    -o-transform: translateY(10.11213px) translateX(5.25px);
  }

  86% {
    -o-transform: translateY(10.4211px) translateX(5.4px);
  }

  87% {
    -o-transform: translateY(10.75801px) translateX(5.55px);
  }

  88% {
    -o-transform: translateY(11.12152px) translateX(5.7px);
  }

  89% {
    -o-transform: translateY(11.51021px) translateX(5.85px);
  }

  90% {
    -o-transform: translateY(11.92254px) translateX(6px);
  }

  91% {
    -o-transform: translateY(12.35688px) translateX(6.15px);
  }

  92% {
    -o-transform: translateY(12.81152px) translateX(6.3px);
  }

  93% {
    -o-transform: translateY(13.28466px) translateX(6.45px);
  }

  94% {
    -o-transform: translateY(13.77443px) translateX(6.6px);
  }

  95% {
    -o-transform: translateY(14.27891px) translateX(6.75px);
  }

  96% {
    -o-transform: translateY(14.7961px) translateX(6.9px);
  }

  97% {
    -o-transform: translateY(15.32396px) translateX(7.05px);
  }

  98% {
    -o-transform: translateY(15.86041px) translateX(7.2px);
  }

  99% {
    -o-transform: translateY(16.40333px) translateX(7.35px);
  }

  100% {
    -o-transform: translateY(16.95058px) translateX(7.5px);
  }
}

@-o-keyframes hailRight {
  50% {
    -o-transform: translateY(17.5px);
  }

  51% {
    -o-transform: translateY(17.5px) translateX(0.3px);
  }

  52% {
    -o-transform: translateY(16.95058px) translateX(0.6px);
  }

  53% {
    -o-transform: translateY(16.40333px) translateX(0.9px);
  }

  54% {
    -o-transform: translateY(15.86041px) translateX(1.2px);
  }

  55% {
    -o-transform: translateY(15.32396px) translateX(1.5px);
  }

  56% {
    -o-transform: translateY(14.7961px) translateX(1.8px);
  }

  57% {
    -o-transform: translateY(14.27891px) translateX(2.1px);
  }

  58% {
    -o-transform: translateY(13.77443px) translateX(2.4px);
  }

  59% {
    -o-transform: translateY(13.28466px) translateX(2.7px);
  }

  60% {
    -o-transform: translateY(12.81152px) translateX(3px);
  }

  61% {
    -o-transform: translateY(12.35688px) translateX(3.3px);
  }

  62% {
    -o-transform: translateY(11.92254px) translateX(3.6px);
  }

  63% {
    -o-transform: translateY(11.51021px) translateX(3.9px);
  }

  64% {
    -o-transform: translateY(11.12152px) translateX(4.2px);
  }

  65% {
    -o-transform: translateY(10.75801px) translateX(4.5px);
  }

  66% {
    -o-transform: translateY(10.4211px) translateX(4.8px);
  }

  67% {
    -o-transform: translateY(10.11213px) translateX(5.1px);
  }

  68% {
    -o-transform: translateY(9.83232px) translateX(5.4px);
  }

  69% {
    -o-transform: translateY(9.58276px) translateX(5.7px);
  }

  70% {
    -o-transform: translateY(9.36446px) translateX(6px);
  }

  71% {
    -o-transform: translateY(9.17826px) translateX(6.3px);
  }

  72% {
    -o-transform: translateY(9.0249px) translateX(6.6px);
  }

  73% {
    -o-transform: translateY(8.90499px) translateX(6.9px);
  }

  74% {
    -o-transform: translateY(8.819px) translateX(7.2px);
  }

  75% {
    -o-transform: translateY(8.76727px) translateX(7.5px);
  }

  76% {
    -o-transform: translateY(8.75px) translateX(7.8px);
  }

  77% {
    -o-transform: translateY(8.76727px) translateX(8.1px);
  }

  78% {
    -o-transform: translateY(8.819px) translateX(8.4px);
  }

  79% {
    -o-transform: translateY(8.90499px) translateX(8.7px);
  }

  80% {
    -o-transform: translateY(9.0249px) translateX(9px);
  }

  81% {
    -o-transform: translateY(9.17826px) translateX(9.3px);
  }

  82% {
    -o-transform: translateY(9.36446px) translateX(9.6px);
  }

  83% {
    -o-transform: translateY(9.58276px) translateX(9.9px);
  }

  84% {
    -o-transform: translateY(9.83232px) translateX(10.2px);
  }

  85% {
    -o-transform: translateY(10.11213px) translateX(10.5px);
  }

  86% {
    -o-transform: translateY(10.4211px) translateX(10.8px);
  }

  87% {
    -o-transform: translateY(10.75801px) translateX(11.1px);
  }

  88% {
    -o-transform: translateY(11.12152px) translateX(11.4px);
  }

  89% {
    -o-transform: translateY(11.51021px) translateX(11.7px);
  }

  90% {
    -o-transform: translateY(11.92254px) translateX(12px);
  }

  91% {
    -o-transform: translateY(12.35688px) translateX(12.3px);
  }

  92% {
    -o-transform: translateY(12.81152px) translateX(12.6px);
  }

  93% {
    -o-transform: translateY(13.28466px) translateX(12.9px);
  }

  94% {
    -o-transform: translateY(13.77443px) translateX(13.2px);
  }

  95% {
    -o-transform: translateY(14.27891px) translateX(13.5px);
  }

  96% {
    -o-transform: translateY(14.7961px) translateX(13.8px);
  }

  97% {
    -o-transform: translateY(15.32396px) translateX(14.1px);
  }

  98% {
    -o-transform: translateY(15.86041px) translateX(14.4px);
  }

  99% {
    -o-transform: translateY(16.40333px) translateX(14.7px);
  }

  100% {
    -o-transform: translateY(16.95058px) translateX(15px);
  }
}

@keyframes hailLeft {
  50% {
    transform: translateY(17.5px);
  }

  51% {
    transform: translateY(17.5px) translateX(-0.3px);
  }

  52% {
    transform: translateY(16.95058px) translateX(-0.6px);
  }

  53% {
    transform: translateY(16.40333px) translateX(-0.9px);
  }

  54% {
    transform: translateY(15.86041px) translateX(-1.2px);
  }

  55% {
    transform: translateY(15.32396px) translateX(-1.5px);
  }

  56% {
    transform: translateY(14.7961px) translateX(-1.8px);
  }

  57% {
    transform: translateY(14.27891px) translateX(-2.1px);
  }

  58% {
    transform: translateY(13.77443px) translateX(-2.4px);
  }

  59% {
    transform: translateY(13.28466px) translateX(-2.7px);
  }

  60% {
    transform: translateY(12.81152px) translateX(-3px);
  }

  61% {
    transform: translateY(12.35688px) translateX(-3.3px);
  }

  62% {
    transform: translateY(11.92254px) translateX(-3.6px);
  }

  63% {
    transform: translateY(11.51021px) translateX(-3.9px);
  }

  64% {
    transform: translateY(11.12152px) translateX(-4.2px);
  }

  65% {
    transform: translateY(10.75801px) translateX(-4.5px);
  }

  66% {
    transform: translateY(10.4211px) translateX(-4.8px);
  }

  67% {
    transform: translateY(10.11213px) translateX(-5.1px);
  }

  68% {
    transform: translateY(9.83232px) translateX(-5.4px);
  }

  69% {
    transform: translateY(9.58276px) translateX(-5.7px);
  }

  70% {
    transform: translateY(9.36446px) translateX(-6px);
  }

  71% {
    transform: translateY(9.17826px) translateX(-6.3px);
  }

  72% {
    transform: translateY(9.0249px) translateX(-6.6px);
  }

  73% {
    transform: translateY(8.90499px) translateX(-6.9px);
  }

  74% {
    transform: translateY(8.819px) translateX(-7.2px);
  }

  75% {
    transform: translateY(8.76727px) translateX(-7.5px);
  }

  76% {
    transform: translateY(8.75px) translateX(-7.8px);
  }

  77% {
    transform: translateY(8.76727px) translateX(-8.1px);
  }

  78% {
    transform: translateY(8.819px) translateX(-8.4px);
  }

  79% {
    transform: translateY(8.90499px) translateX(-8.7px);
  }

  80% {
    transform: translateY(9.0249px) translateX(-9px);
  }

  81% {
    transform: translateY(9.17826px) translateX(-9.3px);
  }

  82% {
    transform: translateY(9.36446px) translateX(-9.6px);
  }

  83% {
    transform: translateY(9.58276px) translateX(-9.9px);
  }

  84% {
    transform: translateY(9.83232px) translateX(-10.2px);
  }

  85% {
    transform: translateY(10.11213px) translateX(-10.5px);
  }

  86% {
    transform: translateY(10.4211px) translateX(-10.8px);
  }

  87% {
    transform: translateY(10.75801px) translateX(-11.1px);
  }

  88% {
    transform: translateY(11.12152px) translateX(-11.4px);
  }

  89% {
    transform: translateY(11.51021px) translateX(-11.7px);
  }

  90% {
    transform: translateY(11.92254px) translateX(-12px);
  }

  91% {
    transform: translateY(12.35688px) translateX(-12.3px);
  }

  92% {
    transform: translateY(12.81152px) translateX(-12.6px);
  }

  93% {
    transform: translateY(13.28466px) translateX(-12.9px);
  }

  94% {
    transform: translateY(13.77443px) translateX(-13.2px);
  }

  95% {
    transform: translateY(14.27891px) translateX(-13.5px);
  }

  96% {
    transform: translateY(14.7961px) translateX(-13.8px);
  }

  97% {
    transform: translateY(15.32396px) translateX(-14.1px);
  }

  98% {
    transform: translateY(15.86041px) translateX(-14.4px);
  }

  99% {
    transform: translateY(16.40333px) translateX(-14.7px);
  }

  100% {
    transform: translateY(16.95058px) translateX(-15px);
  }
}

@keyframes hailMiddle {
  50% {
    transform: translateY(17.5px);
  }

  51% {
    transform: translateY(17.5px) translateX(-0.15px);
  }

  52% {
    transform: translateY(16.95058px) translateX(-0.3px);
  }

  53% {
    transform: translateY(16.40333px) translateX(-0.45px);
  }

  54% {
    transform: translateY(15.86041px) translateX(-0.6px);
  }

  55% {
    transform: translateY(15.32396px) translateX(-0.75px);
  }

  56% {
    transform: translateY(14.7961px) translateX(-0.9px);
  }

  57% {
    transform: translateY(14.27891px) translateX(-1.05px);
  }

  58% {
    transform: translateY(13.77443px) translateX(-1.2px);
  }

  59% {
    transform: translateY(13.28466px) translateX(-1.35px);
  }

  60% {
    transform: translateY(12.81152px) translateX(-1.5px);
  }

  61% {
    transform: translateY(12.35688px) translateX(-1.65px);
  }

  62% {
    transform: translateY(11.92254px) translateX(-1.8px);
  }

  63% {
    transform: translateY(11.51021px) translateX(-1.95px);
  }

  64% {
    transform: translateY(11.12152px) translateX(-2.1px);
  }

  65% {
    transform: translateY(10.75801px) translateX(-2.25px);
  }

  66% {
    transform: translateY(10.4211px) translateX(-2.4px);
  }

  67% {
    transform: translateY(10.11213px) translateX(-2.55px);
  }

  68% {
    transform: translateY(9.83232px) translateX(-2.7px);
  }

  69% {
    transform: translateY(9.58276px) translateX(-2.85px);
  }

  70% {
    transform: translateY(9.36446px) translateX(-3px);
  }

  71% {
    transform: translateY(9.17826px) translateX(-3.15px);
  }

  72% {
    transform: translateY(9.0249px) translateX(-3.3px);
  }

  73% {
    transform: translateY(8.90499px) translateX(-3.45px);
  }

  74% {
    transform: translateY(8.819px) translateX(-3.6px);
  }

  75% {
    transform: translateY(8.76727px) translateX(-3.75px);
  }

  76% {
    transform: translateY(8.75px) translateX(-3.9px);
  }

  77% {
    transform: translateY(8.76727px) translateX(-4.05px);
  }

  78% {
    transform: translateY(8.819px) translateX(-4.2px);
  }

  79% {
    transform: translateY(8.90499px) translateX(-4.35px);
  }

  80% {
    transform: translateY(9.0249px) translateX(-4.5px);
  }

  81% {
    transform: translateY(9.17826px) translateX(-4.65px);
  }

  82% {
    transform: translateY(9.36446px) translateX(-4.8px);
  }

  83% {
    transform: translateY(9.58276px) translateX(-4.95px);
  }

  84% {
    transform: translateY(9.83232px) translateX(-5.1px);
  }

  85% {
    transform: translateY(10.11213px) translateX(-5.25px);
  }

  86% {
    transform: translateY(10.4211px) translateX(-5.4px);
  }

  87% {
    transform: translateY(10.75801px) translateX(-5.55px);
  }

  88% {
    transform: translateY(11.12152px) translateX(-5.7px);
  }

  89% {
    transform: translateY(11.51021px) translateX(-5.85px);
  }

  90% {
    transform: translateY(11.92254px) translateX(-6px);
  }

  91% {
    transform: translateY(12.35688px) translateX(-6.15px);
  }

  92% {
    transform: translateY(12.81152px) translateX(-6.3px);
  }

  93% {
    transform: translateY(13.28466px) translateX(-6.45px);
  }

  94% {
    transform: translateY(13.77443px) translateX(-6.6px);
  }

  95% {
    transform: translateY(14.27891px) translateX(-6.75px);
  }

  96% {
    transform: translateY(14.7961px) translateX(-6.9px);
  }

  97% {
    transform: translateY(15.32396px) translateX(-7.05px);
  }

  98% {
    transform: translateY(15.86041px) translateX(-7.2px);
  }

  99% {
    transform: translateY(16.40333px) translateX(-7.35px);
  }

  100% {
    transform: translateY(16.95058px) translateX(-7.5px);
  }
}

@keyframes hailMiddle2 {
  50% {
    transform: translateY(17.5px);
  }

  51% {
    transform: translateY(17.5px) translateX(0.15px);
  }

  52% {
    transform: translateY(16.95058px) translateX(0.3px);
  }

  53% {
    transform: translateY(16.40333px) translateX(0.45px);
  }

  54% {
    transform: translateY(15.86041px) translateX(0.6px);
  }

  55% {
    transform: translateY(15.32396px) translateX(0.75px);
  }

  56% {
    transform: translateY(14.7961px) translateX(0.9px);
  }

  57% {
    transform: translateY(14.27891px) translateX(1.05px);
  }

  58% {
    transform: translateY(13.77443px) translateX(1.2px);
  }

  59% {
    transform: translateY(13.28466px) translateX(1.35px);
  }

  60% {
    transform: translateY(12.81152px) translateX(1.5px);
  }

  61% {
    transform: translateY(12.35688px) translateX(1.65px);
  }

  62% {
    transform: translateY(11.92254px) translateX(1.8px);
  }

  63% {
    transform: translateY(11.51021px) translateX(1.95px);
  }

  64% {
    transform: translateY(11.12152px) translateX(2.1px);
  }

  65% {
    transform: translateY(10.75801px) translateX(2.25px);
  }

  66% {
    transform: translateY(10.4211px) translateX(2.4px);
  }

  67% {
    transform: translateY(10.11213px) translateX(2.55px);
  }

  68% {
    transform: translateY(9.83232px) translateX(2.7px);
  }

  69% {
    transform: translateY(9.58276px) translateX(2.85px);
  }

  70% {
    transform: translateY(9.36446px) translateX(3px);
  }

  71% {
    transform: translateY(9.17826px) translateX(3.15px);
  }

  72% {
    transform: translateY(9.0249px) translateX(3.3px);
  }

  73% {
    transform: translateY(8.90499px) translateX(3.45px);
  }

  74% {
    transform: translateY(8.819px) translateX(3.6px);
  }

  75% {
    transform: translateY(8.76727px) translateX(3.75px);
  }

  76% {
    transform: translateY(8.75px) translateX(3.9px);
  }

  77% {
    transform: translateY(8.76727px) translateX(4.05px);
  }

  78% {
    transform: translateY(8.819px) translateX(4.2px);
  }

  79% {
    transform: translateY(8.90499px) translateX(4.35px);
  }

  80% {
    transform: translateY(9.0249px) translateX(4.5px);
  }

  81% {
    transform: translateY(9.17826px) translateX(4.65px);
  }

  82% {
    transform: translateY(9.36446px) translateX(4.8px);
  }

  83% {
    transform: translateY(9.58276px) translateX(4.95px);
  }

  84% {
    transform: translateY(9.83232px) translateX(5.1px);
  }

  85% {
    transform: translateY(10.11213px) translateX(5.25px);
  }

  86% {
    transform: translateY(10.4211px) translateX(5.4px);
  }

  87% {
    transform: translateY(10.75801px) translateX(5.55px);
  }

  88% {
    transform: translateY(11.12152px) translateX(5.7px);
  }

  89% {
    transform: translateY(11.51021px) translateX(5.85px);
  }

  90% {
    transform: translateY(11.92254px) translateX(6px);
  }

  91% {
    transform: translateY(12.35688px) translateX(6.15px);
  }

  92% {
    transform: translateY(12.81152px) translateX(6.3px);
  }

  93% {
    transform: translateY(13.28466px) translateX(6.45px);
  }

  94% {
    transform: translateY(13.77443px) translateX(6.6px);
  }

  95% {
    transform: translateY(14.27891px) translateX(6.75px);
  }

  96% {
    transform: translateY(14.7961px) translateX(6.9px);
  }

  97% {
    transform: translateY(15.32396px) translateX(7.05px);
  }

  98% {
    transform: translateY(15.86041px) translateX(7.2px);
  }

  99% {
    transform: translateY(16.40333px) translateX(7.35px);
  }

  100% {
    transform: translateY(16.95058px) translateX(7.5px);
  }
}

@keyframes hailRight {
  50% {
    transform: translateY(17.5px);
  }

  51% {
    transform: translateY(17.5px) translateX(0.3px);
  }

  52% {
    transform: translateY(16.95058px) translateX(0.6px);
  }

  53% {
    transform: translateY(16.40333px) translateX(0.9px);
  }

  54% {
    transform: translateY(15.86041px) translateX(1.2px);
  }

  55% {
    transform: translateY(15.32396px) translateX(1.5px);
  }

  56% {
    transform: translateY(14.7961px) translateX(1.8px);
  }

  57% {
    transform: translateY(14.27891px) translateX(2.1px);
  }

  58% {
    transform: translateY(13.77443px) translateX(2.4px);
  }

  59% {
    transform: translateY(13.28466px) translateX(2.7px);
  }

  60% {
    transform: translateY(12.81152px) translateX(3px);
  }

  61% {
    transform: translateY(12.35688px) translateX(3.3px);
  }

  62% {
    transform: translateY(11.92254px) translateX(3.6px);
  }

  63% {
    transform: translateY(11.51021px) translateX(3.9px);
  }

  64% {
    transform: translateY(11.12152px) translateX(4.2px);
  }

  65% {
    transform: translateY(10.75801px) translateX(4.5px);
  }

  66% {
    transform: translateY(10.4211px) translateX(4.8px);
  }

  67% {
    transform: translateY(10.11213px) translateX(5.1px);
  }

  68% {
    transform: translateY(9.83232px) translateX(5.4px);
  }

  69% {
    transform: translateY(9.58276px) translateX(5.7px);
  }

  70% {
    transform: translateY(9.36446px) translateX(6px);
  }

  71% {
    transform: translateY(9.17826px) translateX(6.3px);
  }

  72% {
    transform: translateY(9.0249px) translateX(6.6px);
  }

  73% {
    transform: translateY(8.90499px) translateX(6.9px);
  }

  74% {
    transform: translateY(8.819px) translateX(7.2px);
  }

  75% {
    transform: translateY(8.76727px) translateX(7.5px);
  }

  76% {
    transform: translateY(8.75px) translateX(7.8px);
  }

  77% {
    transform: translateY(8.76727px) translateX(8.1px);
  }

  78% {
    transform: translateY(8.819px) translateX(8.4px);
  }

  79% {
    transform: translateY(8.90499px) translateX(8.7px);
  }

  80% {
    transform: translateY(9.0249px) translateX(9px);
  }

  81% {
    transform: translateY(9.17826px) translateX(9.3px);
  }

  82% {
    transform: translateY(9.36446px) translateX(9.6px);
  }

  83% {
    transform: translateY(9.58276px) translateX(9.9px);
  }

  84% {
    transform: translateY(9.83232px) translateX(10.2px);
  }

  85% {
    transform: translateY(10.11213px) translateX(10.5px);
  }

  86% {
    transform: translateY(10.4211px) translateX(10.8px);
  }

  87% {
    transform: translateY(10.75801px) translateX(11.1px);
  }

  88% {
    transform: translateY(11.12152px) translateX(11.4px);
  }

  89% {
    transform: translateY(11.51021px) translateX(11.7px);
  }

  90% {
    transform: translateY(11.92254px) translateX(12px);
  }

  91% {
    transform: translateY(12.35688px) translateX(12.3px);
  }

  92% {
    transform: translateY(12.81152px) translateX(12.6px);
  }

  93% {
    transform: translateY(13.28466px) translateX(12.9px);
  }

  94% {
    transform: translateY(13.77443px) translateX(13.2px);
  }

  95% {
    transform: translateY(14.27891px) translateX(13.5px);
  }

  96% {
    transform: translateY(14.7961px) translateX(13.8px);
  }

  97% {
    transform: translateY(15.32396px) translateX(14.1px);
  }

  98% {
    transform: translateY(15.86041px) translateX(14.4px);
  }

  99% {
    transform: translateY(16.40333px) translateX(14.7px);
  }

  100% {
    transform: translateY(16.95058px) translateX(15px);
  }
}

@-webkit-keyframes fillOpacity {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}

@-moz-keyframes fillOpacity {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}

@-o-keyframes fillOpacity {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}

@keyframes fillOpacity {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}

@-webkit-keyframes fillOpacity2 {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@-moz-keyframes fillOpacity2 {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@-o-keyframes fillOpacity2 {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@keyframes fillOpacity2 {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@-webkit-keyframes lightningFlash {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  1% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  7% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  51% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  53% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  54% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  60% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@-moz-keyframes lightningFlash {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  1% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  7% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  51% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  53% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  54% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  60% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@-o-keyframes lightningFlash {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  1% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  7% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  51% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  53% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  54% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  60% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@keyframes lightningFlash {
  0% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  1% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  7% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  50% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  51% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  53% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  54% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  60% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 0;
    stroke-opacity: 0;
  }
}

@-webkit-keyframes snowFall {
  0% {
    -webkit-transform: translateY(0px) translateX(0px);
  }

  1% {
    -webkit-transform: translateY(0.175px) translateX(0.38478px);
  }

  2% {
    -webkit-transform: translateY(0.35px) translateX(0.75349px);
  }

  3% {
    -webkit-transform: translateY(0.525px) translateX(1.10577px);
  }

  4% {
    -webkit-transform: translateY(0.7px) translateX(1.44133px);
  }

  5% {
    -webkit-transform: translateY(0.875px) translateX(1.75989px);
  }

  6% {
    -webkit-transform: translateY(1.05px) translateX(2.06119px);
  }

  7% {
    -webkit-transform: translateY(1.225px) translateX(2.34504px);
  }

  8% {
    -webkit-transform: translateY(1.4px) translateX(2.61124px);
  }

  9% {
    -webkit-transform: translateY(1.575px) translateX(2.85966px);
  }

  10% {
    -webkit-transform: translateY(1.75px) translateX(3.09017px);
  }

  11% {
    -webkit-transform: translateY(1.925px) translateX(3.30269px);
  }

  12% {
    -webkit-transform: translateY(2.1px) translateX(3.49718px);
  }

  13% {
    -webkit-transform: translateY(2.275px) translateX(3.67362px);
  }

  14% {
    -webkit-transform: translateY(2.45px) translateX(3.83201px);
  }

  15% {
    -webkit-transform: translateY(2.625px) translateX(3.97242px);
  }

  16% {
    -webkit-transform: translateY(2.8px) translateX(4.09491px);
  }

  17% {
    -webkit-transform: translateY(2.975px) translateX(4.19959px);
  }

  18% {
    -webkit-transform: translateY(3.15px) translateX(4.28661px);
  }

  19% {
    -webkit-transform: translateY(3.325px) translateX(4.35615px);
  }

  20% {
    -webkit-transform: translateY(3.5px) translateX(4.40839px);
  }

  21% {
    -webkit-transform: translateY(3.675px) translateX(4.44358px);
  }

  22% {
    -webkit-transform: translateY(3.85px) translateX(4.46197px);
  }

  23% {
    -webkit-transform: translateY(4.025px) translateX(4.46386px);
  }

  24% {
    -webkit-transform: translateY(4.2px) translateX(4.44956px);
  }

  25% {
    -webkit-transform: translateY(4.375px) translateX(4.41942px);
  }

  26% {
    -webkit-transform: translateY(4.55px) translateX(4.37381px);
  }

  27% {
    -webkit-transform: translateY(4.725px) translateX(4.31314px);
  }

  28% {
    -webkit-transform: translateY(4.9px) translateX(4.23782px);
  }

  29% {
    -webkit-transform: translateY(5.075px) translateX(4.14831px);
  }

  30% {
    -webkit-transform: translateY(5.25px) translateX(4.04508px);
  }

  31% {
    -webkit-transform: translateY(5.425px) translateX(3.92863px);
  }

  32% {
    -webkit-transform: translateY(5.6px) translateX(3.79948px);
  }

  33% {
    -webkit-transform: translateY(5.775px) translateX(3.65815px);
  }

  34% {
    -webkit-transform: translateY(5.95px) translateX(3.50523px);
  }

  35% {
    -webkit-transform: translateY(6.125px) translateX(3.34127px);
  }

  36% {
    -webkit-transform: translateY(6.3px) translateX(3.16689px);
  }

  37% {
    -webkit-transform: translateY(6.475px) translateX(2.9827px);
  }

  38% {
    -webkit-transform: translateY(6.65px) translateX(2.78933px);
  }

  39% {
    -webkit-transform: translateY(6.825px) translateX(2.58742px);
  }

  40% {
    -webkit-transform: translateY(7px) translateX(2.37764px);
  }

  41% {
    -webkit-transform: translateY(7.175px) translateX(2.16066px);
  }

  42% {
    -webkit-transform: translateY(7.35px) translateX(1.93717px);
  }

  43% {
    -webkit-transform: translateY(7.525px) translateX(1.70785px);
  }

  44% {
    -webkit-transform: translateY(7.7px) translateX(1.47343px);
  }

  45% {
    -webkit-transform: translateY(7.875px) translateX(1.23461px);
  }

  46% {
    -webkit-transform: translateY(8.05px) translateX(0.99211px);
  }

  47% {
    -webkit-transform: translateY(8.225px) translateX(0.74667px);
  }

  48% {
    -webkit-transform: translateY(8.4px) translateX(0.49901px);
  }

  49% {
    -webkit-transform: translateY(8.575px) translateX(0.24988px);
  }

  50% {
    -webkit-transform: translateY(8.75px) translateX(0px);
  }

  51% {
    -webkit-transform: translateY(8.925px) translateX(-0.24988px);
  }

  52% {
    -webkit-transform: translateY(9.1px) translateX(-0.49901px);
  }

  53% {
    -webkit-transform: translateY(9.275px) translateX(-0.74667px);
  }

  54% {
    -webkit-transform: translateY(9.45px) translateX(-0.99211px);
  }

  55% {
    -webkit-transform: translateY(9.625px) translateX(-1.23461px);
  }

  56% {
    -webkit-transform: translateY(9.8px) translateX(-1.47343px);
  }

  57% {
    -webkit-transform: translateY(9.975px) translateX(-1.70785px);
  }

  58% {
    -webkit-transform: translateY(10.15px) translateX(-1.93717px);
  }

  59% {
    -webkit-transform: translateY(10.325px) translateX(-2.16066px);
  }

  60% {
    -webkit-transform: translateY(10.5px) translateX(-2.37764px);
  }

  61% {
    -webkit-transform: translateY(10.675px) translateX(-2.58742px);
  }

  62% {
    -webkit-transform: translateY(10.85px) translateX(-2.78933px);
  }

  63% {
    -webkit-transform: translateY(11.025px) translateX(-2.9827px);
  }

  64% {
    -webkit-transform: translateY(11.2px) translateX(-3.16689px);
  }

  65% {
    -webkit-transform: translateY(11.375px) translateX(-3.34127px);
  }

  66% {
    -webkit-transform: translateY(11.55px) translateX(-3.50523px);
  }

  67% {
    -webkit-transform: translateY(11.725px) translateX(-3.65815px);
  }

  68% {
    -webkit-transform: translateY(11.9px) translateX(-3.79948px);
  }

  69% {
    -webkit-transform: translateY(12.075px) translateX(-3.92863px);
  }

  70% {
    -webkit-transform: translateY(12.25px) translateX(-4.04508px);
  }

  71% {
    -webkit-transform: translateY(12.425px) translateX(-4.14831px);
  }

  72% {
    -webkit-transform: translateY(12.6px) translateX(-4.23782px);
  }

  73% {
    -webkit-transform: translateY(12.775px) translateX(-4.31314px);
  }

  74% {
    -webkit-transform: translateY(12.95px) translateX(-4.37381px);
  }

  75% {
    -webkit-transform: translateY(13.125px) translateX(-4.41942px);
  }

  76% {
    -webkit-transform: translateY(13.3px) translateX(-4.44956px);
  }

  77% {
    -webkit-transform: translateY(13.475px) translateX(-4.46386px);
  }

  78% {
    -webkit-transform: translateY(13.65px) translateX(-4.46197px);
  }

  79% {
    -webkit-transform: translateY(13.825px) translateX(-4.44358px);
  }

  80% {
    -webkit-transform: translateY(14px) translateX(-4.40839px);
  }

  81% {
    -webkit-transform: translateY(14.175px) translateX(-4.35615px);
  }

  82% {
    -webkit-transform: translateY(14.35px) translateX(-4.28661px);
  }

  83% {
    -webkit-transform: translateY(14.525px) translateX(-4.19959px);
  }

  84% {
    -webkit-transform: translateY(14.7px) translateX(-4.09491px);
  }

  85% {
    -webkit-transform: translateY(14.875px) translateX(-3.97242px);
  }

  86% {
    -webkit-transform: translateY(15.05px) translateX(-3.83201px);
  }

  87% {
    -webkit-transform: translateY(15.225px) translateX(-3.67362px);
  }

  88% {
    -webkit-transform: translateY(15.4px) translateX(-3.49718px);
  }

  89% {
    -webkit-transform: translateY(15.575px) translateX(-3.30269px);
  }

  90% {
    -webkit-transform: translateY(15.75px) translateX(-3.09017px);
  }

  91% {
    -webkit-transform: translateY(15.925px) translateX(-2.85966px);
  }

  92% {
    -webkit-transform: translateY(16.1px) translateX(-2.61124px);
  }

  93% {
    -webkit-transform: translateY(16.275px) translateX(-2.34504px);
  }

  94% {
    -webkit-transform: translateY(16.45px) translateX(-2.06119px);
  }

  95% {
    -webkit-transform: translateY(16.625px) translateX(-1.75989px);
  }

  96% {
    -webkit-transform: translateY(16.8px) translateX(-1.44133px);
  }

  97% {
    -webkit-transform: translateY(16.975px) translateX(-1.10577px);
  }

  98% {
    -webkit-transform: translateY(17.15px) translateX(-0.75349px);
  }

  99% {
    -webkit-transform: translateY(17.325px) translateX(-0.38478px);
  }

  100% {
    -webkit-transform: translateY(17.5px) translateX(0.0px);
  }
}

@-moz-keyframes snowFall {
  0% {
    -moz-transform: translateY(0px) translateX(0px);
  }

  1% {
    -moz-transform: translateY(0.175px) translateX(0.38478px);
  }

  2% {
    -moz-transform: translateY(0.35px) translateX(0.75349px);
  }

  3% {
    -moz-transform: translateY(0.525px) translateX(1.10577px);
  }

  4% {
    -moz-transform: translateY(0.7px) translateX(1.44133px);
  }

  5% {
    -moz-transform: translateY(0.875px) translateX(1.75989px);
  }

  6% {
    -moz-transform: translateY(1.05px) translateX(2.06119px);
  }

  7% {
    -moz-transform: translateY(1.225px) translateX(2.34504px);
  }

  8% {
    -moz-transform: translateY(1.4px) translateX(2.61124px);
  }

  9% {
    -moz-transform: translateY(1.575px) translateX(2.85966px);
  }

  10% {
    -moz-transform: translateY(1.75px) translateX(3.09017px);
  }

  11% {
    -moz-transform: translateY(1.925px) translateX(3.30269px);
  }

  12% {
    -moz-transform: translateY(2.1px) translateX(3.49718px);
  }

  13% {
    -moz-transform: translateY(2.275px) translateX(3.67362px);
  }

  14% {
    -moz-transform: translateY(2.45px) translateX(3.83201px);
  }

  15% {
    -moz-transform: translateY(2.625px) translateX(3.97242px);
  }

  16% {
    -moz-transform: translateY(2.8px) translateX(4.09491px);
  }

  17% {
    -moz-transform: translateY(2.975px) translateX(4.19959px);
  }

  18% {
    -moz-transform: translateY(3.15px) translateX(4.28661px);
  }

  19% {
    -moz-transform: translateY(3.325px) translateX(4.35615px);
  }

  20% {
    -moz-transform: translateY(3.5px) translateX(4.40839px);
  }

  21% {
    -moz-transform: translateY(3.675px) translateX(4.44358px);
  }

  22% {
    -moz-transform: translateY(3.85px) translateX(4.46197px);
  }

  23% {
    -moz-transform: translateY(4.025px) translateX(4.46386px);
  }

  24% {
    -moz-transform: translateY(4.2px) translateX(4.44956px);
  }

  25% {
    -moz-transform: translateY(4.375px) translateX(4.41942px);
  }

  26% {
    -moz-transform: translateY(4.55px) translateX(4.37381px);
  }

  27% {
    -moz-transform: translateY(4.725px) translateX(4.31314px);
  }

  28% {
    -moz-transform: translateY(4.9px) translateX(4.23782px);
  }

  29% {
    -moz-transform: translateY(5.075px) translateX(4.14831px);
  }

  30% {
    -moz-transform: translateY(5.25px) translateX(4.04508px);
  }

  31% {
    -moz-transform: translateY(5.425px) translateX(3.92863px);
  }

  32% {
    -moz-transform: translateY(5.6px) translateX(3.79948px);
  }

  33% {
    -moz-transform: translateY(5.775px) translateX(3.65815px);
  }

  34% {
    -moz-transform: translateY(5.95px) translateX(3.50523px);
  }

  35% {
    -moz-transform: translateY(6.125px) translateX(3.34127px);
  }

  36% {
    -moz-transform: translateY(6.3px) translateX(3.16689px);
  }

  37% {
    -moz-transform: translateY(6.475px) translateX(2.9827px);
  }

  38% {
    -moz-transform: translateY(6.65px) translateX(2.78933px);
  }

  39% {
    -moz-transform: translateY(6.825px) translateX(2.58742px);
  }

  40% {
    -moz-transform: translateY(7px) translateX(2.37764px);
  }

  41% {
    -moz-transform: translateY(7.175px) translateX(2.16066px);
  }

  42% {
    -moz-transform: translateY(7.35px) translateX(1.93717px);
  }

  43% {
    -moz-transform: translateY(7.525px) translateX(1.70785px);
  }

  44% {
    -moz-transform: translateY(7.7px) translateX(1.47343px);
  }

  45% {
    -moz-transform: translateY(7.875px) translateX(1.23461px);
  }

  46% {
    -moz-transform: translateY(8.05px) translateX(0.99211px);
  }

  47% {
    -moz-transform: translateY(8.225px) translateX(0.74667px);
  }

  48% {
    -moz-transform: translateY(8.4px) translateX(0.49901px);
  }

  49% {
    -moz-transform: translateY(8.575px) translateX(0.24988px);
  }

  50% {
    -moz-transform: translateY(8.75px) translateX(0px);
  }

  51% {
    -moz-transform: translateY(8.925px) translateX(-0.24988px);
  }

  52% {
    -moz-transform: translateY(9.1px) translateX(-0.49901px);
  }

  53% {
    -moz-transform: translateY(9.275px) translateX(-0.74667px);
  }

  54% {
    -moz-transform: translateY(9.45px) translateX(-0.99211px);
  }

  55% {
    -moz-transform: translateY(9.625px) translateX(-1.23461px);
  }

  56% {
    -moz-transform: translateY(9.8px) translateX(-1.47343px);
  }

  57% {
    -moz-transform: translateY(9.975px) translateX(-1.70785px);
  }

  58% {
    -moz-transform: translateY(10.15px) translateX(-1.93717px);
  }

  59% {
    -moz-transform: translateY(10.325px) translateX(-2.16066px);
  }

  60% {
    -moz-transform: translateY(10.5px) translateX(-2.37764px);
  }

  61% {
    -moz-transform: translateY(10.675px) translateX(-2.58742px);
  }

  62% {
    -moz-transform: translateY(10.85px) translateX(-2.78933px);
  }

  63% {
    -moz-transform: translateY(11.025px) translateX(-2.9827px);
  }

  64% {
    -moz-transform: translateY(11.2px) translateX(-3.16689px);
  }

  65% {
    -moz-transform: translateY(11.375px) translateX(-3.34127px);
  }

  66% {
    -moz-transform: translateY(11.55px) translateX(-3.50523px);
  }

  67% {
    -moz-transform: translateY(11.725px) translateX(-3.65815px);
  }

  68% {
    -moz-transform: translateY(11.9px) translateX(-3.79948px);
  }

  69% {
    -moz-transform: translateY(12.075px) translateX(-3.92863px);
  }

  70% {
    -moz-transform: translateY(12.25px) translateX(-4.04508px);
  }

  71% {
    -moz-transform: translateY(12.425px) translateX(-4.14831px);
  }

  72% {
    -moz-transform: translateY(12.6px) translateX(-4.23782px);
  }

  73% {
    -moz-transform: translateY(12.775px) translateX(-4.31314px);
  }

  74% {
    -moz-transform: translateY(12.95px) translateX(-4.37381px);
  }

  75% {
    -moz-transform: translateY(13.125px) translateX(-4.41942px);
  }

  76% {
    -moz-transform: translateY(13.3px) translateX(-4.44956px);
  }

  77% {
    -moz-transform: translateY(13.475px) translateX(-4.46386px);
  }

  78% {
    -moz-transform: translateY(13.65px) translateX(-4.46197px);
  }

  79% {
    -moz-transform: translateY(13.825px) translateX(-4.44358px);
  }

  80% {
    -moz-transform: translateY(14px) translateX(-4.40839px);
  }

  81% {
    -moz-transform: translateY(14.175px) translateX(-4.35615px);
  }

  82% {
    -moz-transform: translateY(14.35px) translateX(-4.28661px);
  }

  83% {
    -moz-transform: translateY(14.525px) translateX(-4.19959px);
  }

  84% {
    -moz-transform: translateY(14.7px) translateX(-4.09491px);
  }

  85% {
    -moz-transform: translateY(14.875px) translateX(-3.97242px);
  }

  86% {
    -moz-transform: translateY(15.05px) translateX(-3.83201px);
  }

  87% {
    -moz-transform: translateY(15.225px) translateX(-3.67362px);
  }

  88% {
    -moz-transform: translateY(15.4px) translateX(-3.49718px);
  }

  89% {
    -moz-transform: translateY(15.575px) translateX(-3.30269px);
  }

  90% {
    -moz-transform: translateY(15.75px) translateX(-3.09017px);
  }

  91% {
    -moz-transform: translateY(15.925px) translateX(-2.85966px);
  }

  92% {
    -moz-transform: translateY(16.1px) translateX(-2.61124px);
  }

  93% {
    -moz-transform: translateY(16.275px) translateX(-2.34504px);
  }

  94% {
    -moz-transform: translateY(16.45px) translateX(-2.06119px);
  }

  95% {
    -moz-transform: translateY(16.625px) translateX(-1.75989px);
  }

  96% {
    -moz-transform: translateY(16.8px) translateX(-1.44133px);
  }

  97% {
    -moz-transform: translateY(16.975px) translateX(-1.10577px);
  }

  98% {
    -moz-transform: translateY(17.15px) translateX(-0.75349px);
  }

  99% {
    -moz-transform: translateY(17.325px) translateX(-0.38478px);
  }

  100% {
    -moz-transform: translateY(17.5px) translateX(0.0px);
  }
}

@-o-keyframes snowFall {
  0% {
    -o-transform: translateY(0px) translateX(0px);
  }

  1% {
    -o-transform: translateY(0.175px) translateX(0.38478px);
  }

  2% {
    -o-transform: translateY(0.35px) translateX(0.75349px);
  }

  3% {
    -o-transform: translateY(0.525px) translateX(1.10577px);
  }

  4% {
    -o-transform: translateY(0.7px) translateX(1.44133px);
  }

  5% {
    -o-transform: translateY(0.875px) translateX(1.75989px);
  }

  6% {
    -o-transform: translateY(1.05px) translateX(2.06119px);
  }

  7% {
    -o-transform: translateY(1.225px) translateX(2.34504px);
  }

  8% {
    -o-transform: translateY(1.4px) translateX(2.61124px);
  }

  9% {
    -o-transform: translateY(1.575px) translateX(2.85966px);
  }

  10% {
    -o-transform: translateY(1.75px) translateX(3.09017px);
  }

  11% {
    -o-transform: translateY(1.925px) translateX(3.30269px);
  }

  12% {
    -o-transform: translateY(2.1px) translateX(3.49718px);
  }

  13% {
    -o-transform: translateY(2.275px) translateX(3.67362px);
  }

  14% {
    -o-transform: translateY(2.45px) translateX(3.83201px);
  }

  15% {
    -o-transform: translateY(2.625px) translateX(3.97242px);
  }

  16% {
    -o-transform: translateY(2.8px) translateX(4.09491px);
  }

  17% {
    -o-transform: translateY(2.975px) translateX(4.19959px);
  }

  18% {
    -o-transform: translateY(3.15px) translateX(4.28661px);
  }

  19% {
    -o-transform: translateY(3.325px) translateX(4.35615px);
  }

  20% {
    -o-transform: translateY(3.5px) translateX(4.40839px);
  }

  21% {
    -o-transform: translateY(3.675px) translateX(4.44358px);
  }

  22% {
    -o-transform: translateY(3.85px) translateX(4.46197px);
  }

  23% {
    -o-transform: translateY(4.025px) translateX(4.46386px);
  }

  24% {
    -o-transform: translateY(4.2px) translateX(4.44956px);
  }

  25% {
    -o-transform: translateY(4.375px) translateX(4.41942px);
  }

  26% {
    -o-transform: translateY(4.55px) translateX(4.37381px);
  }

  27% {
    -o-transform: translateY(4.725px) translateX(4.31314px);
  }

  28% {
    -o-transform: translateY(4.9px) translateX(4.23782px);
  }

  29% {
    -o-transform: translateY(5.075px) translateX(4.14831px);
  }

  30% {
    -o-transform: translateY(5.25px) translateX(4.04508px);
  }

  31% {
    -o-transform: translateY(5.425px) translateX(3.92863px);
  }

  32% {
    -o-transform: translateY(5.6px) translateX(3.79948px);
  }

  33% {
    -o-transform: translateY(5.775px) translateX(3.65815px);
  }

  34% {
    -o-transform: translateY(5.95px) translateX(3.50523px);
  }

  35% {
    -o-transform: translateY(6.125px) translateX(3.34127px);
  }

  36% {
    -o-transform: translateY(6.3px) translateX(3.16689px);
  }

  37% {
    -o-transform: translateY(6.475px) translateX(2.9827px);
  }

  38% {
    -o-transform: translateY(6.65px) translateX(2.78933px);
  }

  39% {
    -o-transform: translateY(6.825px) translateX(2.58742px);
  }

  40% {
    -o-transform: translateY(7px) translateX(2.37764px);
  }

  41% {
    -o-transform: translateY(7.175px) translateX(2.16066px);
  }

  42% {
    -o-transform: translateY(7.35px) translateX(1.93717px);
  }

  43% {
    -o-transform: translateY(7.525px) translateX(1.70785px);
  }

  44% {
    -o-transform: translateY(7.7px) translateX(1.47343px);
  }

  45% {
    -o-transform: translateY(7.875px) translateX(1.23461px);
  }

  46% {
    -o-transform: translateY(8.05px) translateX(0.99211px);
  }

  47% {
    -o-transform: translateY(8.225px) translateX(0.74667px);
  }

  48% {
    -o-transform: translateY(8.4px) translateX(0.49901px);
  }

  49% {
    -o-transform: translateY(8.575px) translateX(0.24988px);
  }

  50% {
    -o-transform: translateY(8.75px) translateX(0px);
  }

  51% {
    -o-transform: translateY(8.925px) translateX(-0.24988px);
  }

  52% {
    -o-transform: translateY(9.1px) translateX(-0.49901px);
  }

  53% {
    -o-transform: translateY(9.275px) translateX(-0.74667px);
  }

  54% {
    -o-transform: translateY(9.45px) translateX(-0.99211px);
  }

  55% {
    -o-transform: translateY(9.625px) translateX(-1.23461px);
  }

  56% {
    -o-transform: translateY(9.8px) translateX(-1.47343px);
  }

  57% {
    -o-transform: translateY(9.975px) translateX(-1.70785px);
  }

  58% {
    -o-transform: translateY(10.15px) translateX(-1.93717px);
  }

  59% {
    -o-transform: translateY(10.325px) translateX(-2.16066px);
  }

  60% {
    -o-transform: translateY(10.5px) translateX(-2.37764px);
  }

  61% {
    -o-transform: translateY(10.675px) translateX(-2.58742px);
  }

  62% {
    -o-transform: translateY(10.85px) translateX(-2.78933px);
  }

  63% {
    -o-transform: translateY(11.025px) translateX(-2.9827px);
  }

  64% {
    -o-transform: translateY(11.2px) translateX(-3.16689px);
  }

  65% {
    -o-transform: translateY(11.375px) translateX(-3.34127px);
  }

  66% {
    -o-transform: translateY(11.55px) translateX(-3.50523px);
  }

  67% {
    -o-transform: translateY(11.725px) translateX(-3.65815px);
  }

  68% {
    -o-transform: translateY(11.9px) translateX(-3.79948px);
  }

  69% {
    -o-transform: translateY(12.075px) translateX(-3.92863px);
  }

  70% {
    -o-transform: translateY(12.25px) translateX(-4.04508px);
  }

  71% {
    -o-transform: translateY(12.425px) translateX(-4.14831px);
  }

  72% {
    -o-transform: translateY(12.6px) translateX(-4.23782px);
  }

  73% {
    -o-transform: translateY(12.775px) translateX(-4.31314px);
  }

  74% {
    -o-transform: translateY(12.95px) translateX(-4.37381px);
  }

  75% {
    -o-transform: translateY(13.125px) translateX(-4.41942px);
  }

  76% {
    -o-transform: translateY(13.3px) translateX(-4.44956px);
  }

  77% {
    -o-transform: translateY(13.475px) translateX(-4.46386px);
  }

  78% {
    -o-transform: translateY(13.65px) translateX(-4.46197px);
  }

  79% {
    -o-transform: translateY(13.825px) translateX(-4.44358px);
  }

  80% {
    -o-transform: translateY(14px) translateX(-4.40839px);
  }

  81% {
    -o-transform: translateY(14.175px) translateX(-4.35615px);
  }

  82% {
    -o-transform: translateY(14.35px) translateX(-4.28661px);
  }

  83% {
    -o-transform: translateY(14.525px) translateX(-4.19959px);
  }

  84% {
    -o-transform: translateY(14.7px) translateX(-4.09491px);
  }

  85% {
    -o-transform: translateY(14.875px) translateX(-3.97242px);
  }

  86% {
    -o-transform: translateY(15.05px) translateX(-3.83201px);
  }

  87% {
    -o-transform: translateY(15.225px) translateX(-3.67362px);
  }

  88% {
    -o-transform: translateY(15.4px) translateX(-3.49718px);
  }

  89% {
    -o-transform: translateY(15.575px) translateX(-3.30269px);
  }

  90% {
    -o-transform: translateY(15.75px) translateX(-3.09017px);
  }

  91% {
    -o-transform: translateY(15.925px) translateX(-2.85966px);
  }

  92% {
    -o-transform: translateY(16.1px) translateX(-2.61124px);
  }

  93% {
    -o-transform: translateY(16.275px) translateX(-2.34504px);
  }

  94% {
    -o-transform: translateY(16.45px) translateX(-2.06119px);
  }

  95% {
    -o-transform: translateY(16.625px) translateX(-1.75989px);
  }

  96% {
    -o-transform: translateY(16.8px) translateX(-1.44133px);
  }

  97% {
    -o-transform: translateY(16.975px) translateX(-1.10577px);
  }

  98% {
    -o-transform: translateY(17.15px) translateX(-0.75349px);
  }

  99% {
    -o-transform: translateY(17.325px) translateX(-0.38478px);
  }

  100% {
    -o-transform: translateY(17.5px) translateX(0.0px);
  }
}

@keyframes snowFall {
  0% {
    transform: translateY(0px) translateX(0px);
  }

  1% {
    transform: translateY(0.175px) translateX(0.38478px);
  }

  2% {
    transform: translateY(0.35px) translateX(0.75349px);
  }

  3% {
    transform: translateY(0.525px) translateX(1.10577px);
  }

  4% {
    transform: translateY(0.7px) translateX(1.44133px);
  }

  5% {
    transform: translateY(0.875px) translateX(1.75989px);
  }

  6% {
    transform: translateY(1.05px) translateX(2.06119px);
  }

  7% {
    transform: translateY(1.225px) translateX(2.34504px);
  }

  8% {
    transform: translateY(1.4px) translateX(2.61124px);
  }

  9% {
    transform: translateY(1.575px) translateX(2.85966px);
  }

  10% {
    transform: translateY(1.75px) translateX(3.09017px);
  }

  11% {
    transform: translateY(1.925px) translateX(3.30269px);
  }

  12% {
    transform: translateY(2.1px) translateX(3.49718px);
  }

  13% {
    transform: translateY(2.275px) translateX(3.67362px);
  }

  14% {
    transform: translateY(2.45px) translateX(3.83201px);
  }

  15% {
    transform: translateY(2.625px) translateX(3.97242px);
  }

  16% {
    transform: translateY(2.8px) translateX(4.09491px);
  }

  17% {
    transform: translateY(2.975px) translateX(4.19959px);
  }

  18% {
    transform: translateY(3.15px) translateX(4.28661px);
  }

  19% {
    transform: translateY(3.325px) translateX(4.35615px);
  }

  20% {
    transform: translateY(3.5px) translateX(4.40839px);
  }

  21% {
    transform: translateY(3.675px) translateX(4.44358px);
  }

  22% {
    transform: translateY(3.85px) translateX(4.46197px);
  }

  23% {
    transform: translateY(4.025px) translateX(4.46386px);
  }

  24% {
    transform: translateY(4.2px) translateX(4.44956px);
  }

  25% {
    transform: translateY(4.375px) translateX(4.41942px);
  }

  26% {
    transform: translateY(4.55px) translateX(4.37381px);
  }

  27% {
    transform: translateY(4.725px) translateX(4.31314px);
  }

  28% {
    transform: translateY(4.9px) translateX(4.23782px);
  }

  29% {
    transform: translateY(5.075px) translateX(4.14831px);
  }

  30% {
    transform: translateY(5.25px) translateX(4.04508px);
  }

  31% {
    transform: translateY(5.425px) translateX(3.92863px);
  }

  32% {
    transform: translateY(5.6px) translateX(3.79948px);
  }

  33% {
    transform: translateY(5.775px) translateX(3.65815px);
  }

  34% {
    transform: translateY(5.95px) translateX(3.50523px);
  }

  35% {
    transform: translateY(6.125px) translateX(3.34127px);
  }

  36% {
    transform: translateY(6.3px) translateX(3.16689px);
  }

  37% {
    transform: translateY(6.475px) translateX(2.9827px);
  }

  38% {
    transform: translateY(6.65px) translateX(2.78933px);
  }

  39% {
    transform: translateY(6.825px) translateX(2.58742px);
  }

  40% {
    transform: translateY(7px) translateX(2.37764px);
  }

  41% {
    transform: translateY(7.175px) translateX(2.16066px);
  }

  42% {
    transform: translateY(7.35px) translateX(1.93717px);
  }

  43% {
    transform: translateY(7.525px) translateX(1.70785px);
  }

  44% {
    transform: translateY(7.7px) translateX(1.47343px);
  }

  45% {
    transform: translateY(7.875px) translateX(1.23461px);
  }

  46% {
    transform: translateY(8.05px) translateX(0.99211px);
  }

  47% {
    transform: translateY(8.225px) translateX(0.74667px);
  }

  48% {
    transform: translateY(8.4px) translateX(0.49901px);
  }

  49% {
    transform: translateY(8.575px) translateX(0.24988px);
  }

  50% {
    transform: translateY(8.75px) translateX(0px);
  }

  51% {
    transform: translateY(8.925px) translateX(-0.24988px);
  }

  52% {
    transform: translateY(9.1px) translateX(-0.49901px);
  }

  53% {
    transform: translateY(9.275px) translateX(-0.74667px);
  }

  54% {
    transform: translateY(9.45px) translateX(-0.99211px);
  }

  55% {
    transform: translateY(9.625px) translateX(-1.23461px);
  }

  56% {
    transform: translateY(9.8px) translateX(-1.47343px);
  }

  57% {
    transform: translateY(9.975px) translateX(-1.70785px);
  }

  58% {
    transform: translateY(10.15px) translateX(-1.93717px);
  }

  59% {
    transform: translateY(10.325px) translateX(-2.16066px);
  }

  60% {
    transform: translateY(10.5px) translateX(-2.37764px);
  }

  61% {
    transform: translateY(10.675px) translateX(-2.58742px);
  }

  62% {
    transform: translateY(10.85px) translateX(-2.78933px);
  }

  63% {
    transform: translateY(11.025px) translateX(-2.9827px);
  }

  64% {
    transform: translateY(11.2px) translateX(-3.16689px);
  }

  65% {
    transform: translateY(11.375px) translateX(-3.34127px);
  }

  66% {
    transform: translateY(11.55px) translateX(-3.50523px);
  }

  67% {
    transform: translateY(11.725px) translateX(-3.65815px);
  }

  68% {
    transform: translateY(11.9px) translateX(-3.79948px);
  }

  69% {
    transform: translateY(12.075px) translateX(-3.92863px);
  }

  70% {
    transform: translateY(12.25px) translateX(-4.04508px);
  }

  71% {
    transform: translateY(12.425px) translateX(-4.14831px);
  }

  72% {
    transform: translateY(12.6px) translateX(-4.23782px);
  }

  73% {
    transform: translateY(12.775px) translateX(-4.31314px);
  }

  74% {
    transform: translateY(12.95px) translateX(-4.37381px);
  }

  75% {
    transform: translateY(13.125px) translateX(-4.41942px);
  }

  76% {
    transform: translateY(13.3px) translateX(-4.44956px);
  }

  77% {
    transform: translateY(13.475px) translateX(-4.46386px);
  }

  78% {
    transform: translateY(13.65px) translateX(-4.46197px);
  }

  79% {
    transform: translateY(13.825px) translateX(-4.44358px);
  }

  80% {
    transform: translateY(14px) translateX(-4.40839px);
  }

  81% {
    transform: translateY(14.175px) translateX(-4.35615px);
  }

  82% {
    transform: translateY(14.35px) translateX(-4.28661px);
  }

  83% {
    transform: translateY(14.525px) translateX(-4.19959px);
  }

  84% {
    transform: translateY(14.7px) translateX(-4.09491px);
  }

  85% {
    transform: translateY(14.875px) translateX(-3.97242px);
  }

  86% {
    transform: translateY(15.05px) translateX(-3.83201px);
  }

  87% {
    transform: translateY(15.225px) translateX(-3.67362px);
  }

  88% {
    transform: translateY(15.4px) translateX(-3.49718px);
  }

  89% {
    transform: translateY(15.575px) translateX(-3.30269px);
  }

  90% {
    transform: translateY(15.75px) translateX(-3.09017px);
  }

  91% {
    transform: translateY(15.925px) translateX(-2.85966px);
  }

  92% {
    transform: translateY(16.1px) translateX(-2.61124px);
  }

  93% {
    transform: translateY(16.275px) translateX(-2.34504px);
  }

  94% {
    transform: translateY(16.45px) translateX(-2.06119px);
  }

  95% {
    transform: translateY(16.625px) translateX(-1.75989px);
  }

  96% {
    transform: translateY(16.8px) translateX(-1.44133px);
  }

  97% {
    transform: translateY(16.975px) translateX(-1.10577px);
  }

  98% {
    transform: translateY(17.15px) translateX(-0.75349px);
  }

  99% {
    transform: translateY(17.325px) translateX(-0.38478px);
  }

  100% {
    transform: translateY(17.5px) translateX(0.0px);
  }
}

@-webkit-keyframes snowFall2 {
  0% {
    -webkit-transform: translateY(0px) translateX(0px);
  }

  1% {
    -webkit-transform: translateY(0.175px) translateX(-0.38478px);
  }

  2% {
    -webkit-transform: translateY(0.35px) translateX(-0.75349px);
  }

  3% {
    -webkit-transform: translateY(0.525px) translateX(-1.10577px);
  }

  4% {
    -webkit-transform: translateY(0.7px) translateX(-1.44133px);
  }

  5% {
    -webkit-transform: translateY(0.875px) translateX(-1.75989px);
  }

  6% {
    -webkit-transform: translateY(1.05px) translateX(-2.06119px);
  }

  7% {
    -webkit-transform: translateY(1.225px) translateX(-2.34504px);
  }

  8% {
    -webkit-transform: translateY(1.4px) translateX(-2.61124px);
  }

  9% {
    -webkit-transform: translateY(1.575px) translateX(-2.85966px);
  }

  10% {
    -webkit-transform: translateY(1.75px) translateX(-3.09017px);
  }

  11% {
    -webkit-transform: translateY(1.925px) translateX(-3.30269px);
  }

  12% {
    -webkit-transform: translateY(2.1px) translateX(-3.49718px);
  }

  13% {
    -webkit-transform: translateY(2.275px) translateX(-3.67362px);
  }

  14% {
    -webkit-transform: translateY(2.45px) translateX(-3.83201px);
  }

  15% {
    -webkit-transform: translateY(2.625px) translateX(-3.97242px);
  }

  16% {
    -webkit-transform: translateY(2.8px) translateX(-4.09491px);
  }

  17% {
    -webkit-transform: translateY(2.975px) translateX(-4.19959px);
  }

  18% {
    -webkit-transform: translateY(3.15px) translateX(-4.28661px);
  }

  19% {
    -webkit-transform: translateY(3.325px) translateX(-4.35615px);
  }

  20% {
    -webkit-transform: translateY(3.5px) translateX(-4.40839px);
  }

  21% {
    -webkit-transform: translateY(3.675px) translateX(-4.44358px);
  }

  22% {
    -webkit-transform: translateY(3.85px) translateX(-4.46197px);
  }

  23% {
    -webkit-transform: translateY(4.025px) translateX(-4.46386px);
  }

  24% {
    -webkit-transform: translateY(4.2px) translateX(-4.44956px);
  }

  25% {
    -webkit-transform: translateY(4.375px) translateX(-4.41942px);
  }

  26% {
    -webkit-transform: translateY(4.55px) translateX(-4.37381px);
  }

  27% {
    -webkit-transform: translateY(4.725px) translateX(-4.31314px);
  }

  28% {
    -webkit-transform: translateY(4.9px) translateX(-4.23782px);
  }

  29% {
    -webkit-transform: translateY(5.075px) translateX(-4.14831px);
  }

  30% {
    -webkit-transform: translateY(5.25px) translateX(-4.04508px);
  }

  31% {
    -webkit-transform: translateY(5.425px) translateX(-3.92863px);
  }

  32% {
    -webkit-transform: translateY(5.6px) translateX(-3.79948px);
  }

  33% {
    -webkit-transform: translateY(5.775px) translateX(-3.65815px);
  }

  34% {
    -webkit-transform: translateY(5.95px) translateX(-3.50523px);
  }

  35% {
    -webkit-transform: translateY(6.125px) translateX(-3.34127px);
  }

  36% {
    -webkit-transform: translateY(6.3px) translateX(-3.16689px);
  }

  37% {
    -webkit-transform: translateY(6.475px) translateX(-2.9827px);
  }

  38% {
    -webkit-transform: translateY(6.65px) translateX(-2.78933px);
  }

  39% {
    -webkit-transform: translateY(6.825px) translateX(-2.58742px);
  }

  40% {
    -webkit-transform: translateY(7px) translateX(-2.37764px);
  }

  41% {
    -webkit-transform: translateY(7.175px) translateX(-2.16066px);
  }

  42% {
    -webkit-transform: translateY(7.35px) translateX(-1.93717px);
  }

  43% {
    -webkit-transform: translateY(7.525px) translateX(-1.70785px);
  }

  44% {
    -webkit-transform: translateY(7.7px) translateX(-1.47343px);
  }

  45% {
    -webkit-transform: translateY(7.875px) translateX(-1.23461px);
  }

  46% {
    -webkit-transform: translateY(8.05px) translateX(-0.99211px);
  }

  47% {
    -webkit-transform: translateY(8.225px) translateX(-0.74667px);
  }

  48% {
    -webkit-transform: translateY(8.4px) translateX(-0.49901px);
  }

  49% {
    -webkit-transform: translateY(8.575px) translateX(-0.24988px);
  }

  50% {
    -webkit-transform: translateY(8.75px) translateX(0px);
  }

  51% {
    -webkit-transform: translateY(8.925px) translateX(0.24988px);
  }

  52% {
    -webkit-transform: translateY(9.1px) translateX(0.49901px);
  }

  53% {
    -webkit-transform: translateY(9.275px) translateX(0.74667px);
  }

  54% {
    -webkit-transform: translateY(9.45px) translateX(0.99211px);
  }

  55% {
    -webkit-transform: translateY(9.625px) translateX(1.23461px);
  }

  56% {
    -webkit-transform: translateY(9.8px) translateX(1.47343px);
  }

  57% {
    -webkit-transform: translateY(9.975px) translateX(1.70785px);
  }

  58% {
    -webkit-transform: translateY(10.15px) translateX(1.93717px);
  }

  59% {
    -webkit-transform: translateY(10.325px) translateX(2.16066px);
  }

  60% {
    -webkit-transform: translateY(10.5px) translateX(2.37764px);
  }

  61% {
    -webkit-transform: translateY(10.675px) translateX(2.58742px);
  }

  62% {
    -webkit-transform: translateY(10.85px) translateX(2.78933px);
  }

  63% {
    -webkit-transform: translateY(11.025px) translateX(2.9827px);
  }

  64% {
    -webkit-transform: translateY(11.2px) translateX(3.16689px);
  }

  65% {
    -webkit-transform: translateY(11.375px) translateX(3.34127px);
  }

  66% {
    -webkit-transform: translateY(11.55px) translateX(3.50523px);
  }

  67% {
    -webkit-transform: translateY(11.725px) translateX(3.65815px);
  }

  68% {
    -webkit-transform: translateY(11.9px) translateX(3.79948px);
  }

  69% {
    -webkit-transform: translateY(12.075px) translateX(3.92863px);
  }

  70% {
    -webkit-transform: translateY(12.25px) translateX(4.04508px);
  }

  71% {
    -webkit-transform: translateY(12.425px) translateX(4.14831px);
  }

  72% {
    -webkit-transform: translateY(12.6px) translateX(4.23782px);
  }

  73% {
    -webkit-transform: translateY(12.775px) translateX(4.31314px);
  }

  74% {
    -webkit-transform: translateY(12.95px) translateX(4.37381px);
  }

  75% {
    -webkit-transform: translateY(13.125px) translateX(4.41942px);
  }

  76% {
    -webkit-transform: translateY(13.3px) translateX(4.44956px);
  }

  77% {
    -webkit-transform: translateY(13.475px) translateX(4.46386px);
  }

  78% {
    -webkit-transform: translateY(13.65px) translateX(4.46197px);
  }

  79% {
    -webkit-transform: translateY(13.825px) translateX(4.44358px);
  }

  80% {
    -webkit-transform: translateY(14px) translateX(4.40839px);
  }

  81% {
    -webkit-transform: translateY(14.175px) translateX(4.35615px);
  }

  82% {
    -webkit-transform: translateY(14.35px) translateX(4.28661px);
  }

  83% {
    -webkit-transform: translateY(14.525px) translateX(4.19959px);
  }

  84% {
    -webkit-transform: translateY(14.7px) translateX(4.09491px);
  }

  85% {
    -webkit-transform: translateY(14.875px) translateX(3.97242px);
  }

  86% {
    -webkit-transform: translateY(15.05px) translateX(3.83201px);
  }

  87% {
    -webkit-transform: translateY(15.225px) translateX(3.67362px);
  }

  88% {
    -webkit-transform: translateY(15.4px) translateX(3.49718px);
  }

  89% {
    -webkit-transform: translateY(15.575px) translateX(3.30269px);
  }

  90% {
    -webkit-transform: translateY(15.75px) translateX(3.09017px);
  }

  91% {
    -webkit-transform: translateY(15.925px) translateX(2.85966px);
  }

  92% {
    -webkit-transform: translateY(16.1px) translateX(2.61124px);
  }

  93% {
    -webkit-transform: translateY(16.275px) translateX(2.34504px);
  }

  94% {
    -webkit-transform: translateY(16.45px) translateX(2.06119px);
  }

  95% {
    -webkit-transform: translateY(16.625px) translateX(1.75989px);
  }

  96% {
    -webkit-transform: translateY(16.8px) translateX(1.44133px);
  }

  97% {
    -webkit-transform: translateY(16.975px) translateX(1.10577px);
  }

  98% {
    -webkit-transform: translateY(17.15px) translateX(0.75349px);
  }

  99% {
    -webkit-transform: translateY(17.325px) translateX(0.38478px);
  }

  100% {
    -webkit-transform: translateY(17.5px) translateX(0.0px);
  }
}

@-moz-keyframes snowFall2 {
  0% {
    -moz-transform: translateY(0px) translateX(0px);
  }

  1% {
    -moz-transform: translateY(0.175px) translateX(-0.38478px);
  }

  2% {
    -moz-transform: translateY(0.35px) translateX(-0.75349px);
  }

  3% {
    -moz-transform: translateY(0.525px) translateX(-1.10577px);
  }

  4% {
    -moz-transform: translateY(0.7px) translateX(-1.44133px);
  }

  5% {
    -moz-transform: translateY(0.875px) translateX(-1.75989px);
  }

  6% {
    -moz-transform: translateY(1.05px) translateX(-2.06119px);
  }

  7% {
    -moz-transform: translateY(1.225px) translateX(-2.34504px);
  }

  8% {
    -moz-transform: translateY(1.4px) translateX(-2.61124px);
  }

  9% {
    -moz-transform: translateY(1.575px) translateX(-2.85966px);
  }

  10% {
    -moz-transform: translateY(1.75px) translateX(-3.09017px);
  }

  11% {
    -moz-transform: translateY(1.925px) translateX(-3.30269px);
  }

  12% {
    -moz-transform: translateY(2.1px) translateX(-3.49718px);
  }

  13% {
    -moz-transform: translateY(2.275px) translateX(-3.67362px);
  }

  14% {
    -moz-transform: translateY(2.45px) translateX(-3.83201px);
  }

  15% {
    -moz-transform: translateY(2.625px) translateX(-3.97242px);
  }

  16% {
    -moz-transform: translateY(2.8px) translateX(-4.09491px);
  }

  17% {
    -moz-transform: translateY(2.975px) translateX(-4.19959px);
  }

  18% {
    -moz-transform: translateY(3.15px) translateX(-4.28661px);
  }

  19% {
    -moz-transform: translateY(3.325px) translateX(-4.35615px);
  }

  20% {
    -moz-transform: translateY(3.5px) translateX(-4.40839px);
  }

  21% {
    -moz-transform: translateY(3.675px) translateX(-4.44358px);
  }

  22% {
    -moz-transform: translateY(3.85px) translateX(-4.46197px);
  }

  23% {
    -moz-transform: translateY(4.025px) translateX(-4.46386px);
  }

  24% {
    -moz-transform: translateY(4.2px) translateX(-4.44956px);
  }

  25% {
    -moz-transform: translateY(4.375px) translateX(-4.41942px);
  }

  26% {
    -moz-transform: translateY(4.55px) translateX(-4.37381px);
  }

  27% {
    -moz-transform: translateY(4.725px) translateX(-4.31314px);
  }

  28% {
    -moz-transform: translateY(4.9px) translateX(-4.23782px);
  }

  29% {
    -moz-transform: translateY(5.075px) translateX(-4.14831px);
  }

  30% {
    -moz-transform: translateY(5.25px) translateX(-4.04508px);
  }

  31% {
    -moz-transform: translateY(5.425px) translateX(-3.92863px);
  }

  32% {
    -moz-transform: translateY(5.6px) translateX(-3.79948px);
  }

  33% {
    -moz-transform: translateY(5.775px) translateX(-3.65815px);
  }

  34% {
    -moz-transform: translateY(5.95px) translateX(-3.50523px);
  }

  35% {
    -moz-transform: translateY(6.125px) translateX(-3.34127px);
  }

  36% {
    -moz-transform: translateY(6.3px) translateX(-3.16689px);
  }

  37% {
    -moz-transform: translateY(6.475px) translateX(-2.9827px);
  }

  38% {
    -moz-transform: translateY(6.65px) translateX(-2.78933px);
  }

  39% {
    -moz-transform: translateY(6.825px) translateX(-2.58742px);
  }

  40% {
    -moz-transform: translateY(7px) translateX(-2.37764px);
  }

  41% {
    -moz-transform: translateY(7.175px) translateX(-2.16066px);
  }

  42% {
    -moz-transform: translateY(7.35px) translateX(-1.93717px);
  }

  43% {
    -moz-transform: translateY(7.525px) translateX(-1.70785px);
  }

  44% {
    -moz-transform: translateY(7.7px) translateX(-1.47343px);
  }

  45% {
    -moz-transform: translateY(7.875px) translateX(-1.23461px);
  }

  46% {
    -moz-transform: translateY(8.05px) translateX(-0.99211px);
  }

  47% {
    -moz-transform: translateY(8.225px) translateX(-0.74667px);
  }

  48% {
    -moz-transform: translateY(8.4px) translateX(-0.49901px);
  }

  49% {
    -moz-transform: translateY(8.575px) translateX(-0.24988px);
  }

  50% {
    -moz-transform: translateY(8.75px) translateX(0px);
  }

  51% {
    -moz-transform: translateY(8.925px) translateX(0.24988px);
  }

  52% {
    -moz-transform: translateY(9.1px) translateX(0.49901px);
  }

  53% {
    -moz-transform: translateY(9.275px) translateX(0.74667px);
  }

  54% {
    -moz-transform: translateY(9.45px) translateX(0.99211px);
  }

  55% {
    -moz-transform: translateY(9.625px) translateX(1.23461px);
  }

  56% {
    -moz-transform: translateY(9.8px) translateX(1.47343px);
  }

  57% {
    -moz-transform: translateY(9.975px) translateX(1.70785px);
  }

  58% {
    -moz-transform: translateY(10.15px) translateX(1.93717px);
  }

  59% {
    -moz-transform: translateY(10.325px) translateX(2.16066px);
  }

  60% {
    -moz-transform: translateY(10.5px) translateX(2.37764px);
  }

  61% {
    -moz-transform: translateY(10.675px) translateX(2.58742px);
  }

  62% {
    -moz-transform: translateY(10.85px) translateX(2.78933px);
  }

  63% {
    -moz-transform: translateY(11.025px) translateX(2.9827px);
  }

  64% {
    -moz-transform: translateY(11.2px) translateX(3.16689px);
  }

  65% {
    -moz-transform: translateY(11.375px) translateX(3.34127px);
  }

  66% {
    -moz-transform: translateY(11.55px) translateX(3.50523px);
  }

  67% {
    -moz-transform: translateY(11.725px) translateX(3.65815px);
  }

  68% {
    -moz-transform: translateY(11.9px) translateX(3.79948px);
  }

  69% {
    -moz-transform: translateY(12.075px) translateX(3.92863px);
  }

  70% {
    -moz-transform: translateY(12.25px) translateX(4.04508px);
  }

  71% {
    -moz-transform: translateY(12.425px) translateX(4.14831px);
  }

  72% {
    -moz-transform: translateY(12.6px) translateX(4.23782px);
  }

  73% {
    -moz-transform: translateY(12.775px) translateX(4.31314px);
  }

  74% {
    -moz-transform: translateY(12.95px) translateX(4.37381px);
  }

  75% {
    -moz-transform: translateY(13.125px) translateX(4.41942px);
  }

  76% {
    -moz-transform: translateY(13.3px) translateX(4.44956px);
  }

  77% {
    -moz-transform: translateY(13.475px) translateX(4.46386px);
  }

  78% {
    -moz-transform: translateY(13.65px) translateX(4.46197px);
  }

  79% {
    -moz-transform: translateY(13.825px) translateX(4.44358px);
  }

  80% {
    -moz-transform: translateY(14px) translateX(4.40839px);
  }

  81% {
    -moz-transform: translateY(14.175px) translateX(4.35615px);
  }

  82% {
    -moz-transform: translateY(14.35px) translateX(4.28661px);
  }

  83% {
    -moz-transform: translateY(14.525px) translateX(4.19959px);
  }

  84% {
    -moz-transform: translateY(14.7px) translateX(4.09491px);
  }

  85% {
    -moz-transform: translateY(14.875px) translateX(3.97242px);
  }

  86% {
    -moz-transform: translateY(15.05px) translateX(3.83201px);
  }

  87% {
    -moz-transform: translateY(15.225px) translateX(3.67362px);
  }

  88% {
    -moz-transform: translateY(15.4px) translateX(3.49718px);
  }

  89% {
    -moz-transform: translateY(15.575px) translateX(3.30269px);
  }

  90% {
    -moz-transform: translateY(15.75px) translateX(3.09017px);
  }

  91% {
    -moz-transform: translateY(15.925px) translateX(2.85966px);
  }

  92% {
    -moz-transform: translateY(16.1px) translateX(2.61124px);
  }

  93% {
    -moz-transform: translateY(16.275px) translateX(2.34504px);
  }

  94% {
    -moz-transform: translateY(16.45px) translateX(2.06119px);
  }

  95% {
    -moz-transform: translateY(16.625px) translateX(1.75989px);
  }

  96% {
    -moz-transform: translateY(16.8px) translateX(1.44133px);
  }

  97% {
    -moz-transform: translateY(16.975px) translateX(1.10577px);
  }

  98% {
    -moz-transform: translateY(17.15px) translateX(0.75349px);
  }

  99% {
    -moz-transform: translateY(17.325px) translateX(0.38478px);
  }

  100% {
    -moz-transform: translateY(17.5px) translateX(0.0px);
  }
}

@-o-keyframes snowFall2 {
  0% {
    -o-transform: translateY(0px) translateX(0px);
  }

  1% {
    -o-transform: translateY(0.175px) translateX(-0.38478px);
  }

  2% {
    -o-transform: translateY(0.35px) translateX(-0.75349px);
  }

  3% {
    -o-transform: translateY(0.525px) translateX(-1.10577px);
  }

  4% {
    -o-transform: translateY(0.7px) translateX(-1.44133px);
  }

  5% {
    -o-transform: translateY(0.875px) translateX(-1.75989px);
  }

  6% {
    -o-transform: translateY(1.05px) translateX(-2.06119px);
  }

  7% {
    -o-transform: translateY(1.225px) translateX(-2.34504px);
  }

  8% {
    -o-transform: translateY(1.4px) translateX(-2.61124px);
  }

  9% {
    -o-transform: translateY(1.575px) translateX(-2.85966px);
  }

  10% {
    -o-transform: translateY(1.75px) translateX(-3.09017px);
  }

  11% {
    -o-transform: translateY(1.925px) translateX(-3.30269px);
  }

  12% {
    -o-transform: translateY(2.1px) translateX(-3.49718px);
  }

  13% {
    -o-transform: translateY(2.275px) translateX(-3.67362px);
  }

  14% {
    -o-transform: translateY(2.45px) translateX(-3.83201px);
  }

  15% {
    -o-transform: translateY(2.625px) translateX(-3.97242px);
  }

  16% {
    -o-transform: translateY(2.8px) translateX(-4.09491px);
  }

  17% {
    -o-transform: translateY(2.975px) translateX(-4.19959px);
  }

  18% {
    -o-transform: translateY(3.15px) translateX(-4.28661px);
  }

  19% {
    -o-transform: translateY(3.325px) translateX(-4.35615px);
  }

  20% {
    -o-transform: translateY(3.5px) translateX(-4.40839px);
  }

  21% {
    -o-transform: translateY(3.675px) translateX(-4.44358px);
  }

  22% {
    -o-transform: translateY(3.85px) translateX(-4.46197px);
  }

  23% {
    -o-transform: translateY(4.025px) translateX(-4.46386px);
  }

  24% {
    -o-transform: translateY(4.2px) translateX(-4.44956px);
  }

  25% {
    -o-transform: translateY(4.375px) translateX(-4.41942px);
  }

  26% {
    -o-transform: translateY(4.55px) translateX(-4.37381px);
  }

  27% {
    -o-transform: translateY(4.725px) translateX(-4.31314px);
  }

  28% {
    -o-transform: translateY(4.9px) translateX(-4.23782px);
  }

  29% {
    -o-transform: translateY(5.075px) translateX(-4.14831px);
  }

  30% {
    -o-transform: translateY(5.25px) translateX(-4.04508px);
  }

  31% {
    -o-transform: translateY(5.425px) translateX(-3.92863px);
  }

  32% {
    -o-transform: translateY(5.6px) translateX(-3.79948px);
  }

  33% {
    -o-transform: translateY(5.775px) translateX(-3.65815px);
  }

  34% {
    -o-transform: translateY(5.95px) translateX(-3.50523px);
  }

  35% {
    -o-transform: translateY(6.125px) translateX(-3.34127px);
  }

  36% {
    -o-transform: translateY(6.3px) translateX(-3.16689px);
  }

  37% {
    -o-transform: translateY(6.475px) translateX(-2.9827px);
  }

  38% {
    -o-transform: translateY(6.65px) translateX(-2.78933px);
  }

  39% {
    -o-transform: translateY(6.825px) translateX(-2.58742px);
  }

  40% {
    -o-transform: translateY(7px) translateX(-2.37764px);
  }

  41% {
    -o-transform: translateY(7.175px) translateX(-2.16066px);
  }

  42% {
    -o-transform: translateY(7.35px) translateX(-1.93717px);
  }

  43% {
    -o-transform: translateY(7.525px) translateX(-1.70785px);
  }

  44% {
    -o-transform: translateY(7.7px) translateX(-1.47343px);
  }

  45% {
    -o-transform: translateY(7.875px) translateX(-1.23461px);
  }

  46% {
    -o-transform: translateY(8.05px) translateX(-0.99211px);
  }

  47% {
    -o-transform: translateY(8.225px) translateX(-0.74667px);
  }

  48% {
    -o-transform: translateY(8.4px) translateX(-0.49901px);
  }

  49% {
    -o-transform: translateY(8.575px) translateX(-0.24988px);
  }

  50% {
    -o-transform: translateY(8.75px) translateX(0px);
  }

  51% {
    -o-transform: translateY(8.925px) translateX(0.24988px);
  }

  52% {
    -o-transform: translateY(9.1px) translateX(0.49901px);
  }

  53% {
    -o-transform: translateY(9.275px) translateX(0.74667px);
  }

  54% {
    -o-transform: translateY(9.45px) translateX(0.99211px);
  }

  55% {
    -o-transform: translateY(9.625px) translateX(1.23461px);
  }

  56% {
    -o-transform: translateY(9.8px) translateX(1.47343px);
  }

  57% {
    -o-transform: translateY(9.975px) translateX(1.70785px);
  }

  58% {
    -o-transform: translateY(10.15px) translateX(1.93717px);
  }

  59% {
    -o-transform: translateY(10.325px) translateX(2.16066px);
  }

  60% {
    -o-transform: translateY(10.5px) translateX(2.37764px);
  }

  61% {
    -o-transform: translateY(10.675px) translateX(2.58742px);
  }

  62% {
    -o-transform: translateY(10.85px) translateX(2.78933px);
  }

  63% {
    -o-transform: translateY(11.025px) translateX(2.9827px);
  }

  64% {
    -o-transform: translateY(11.2px) translateX(3.16689px);
  }

  65% {
    -o-transform: translateY(11.375px) translateX(3.34127px);
  }

  66% {
    -o-transform: translateY(11.55px) translateX(3.50523px);
  }

  67% {
    -o-transform: translateY(11.725px) translateX(3.65815px);
  }

  68% {
    -o-transform: translateY(11.9px) translateX(3.79948px);
  }

  69% {
    -o-transform: translateY(12.075px) translateX(3.92863px);
  }

  70% {
    -o-transform: translateY(12.25px) translateX(4.04508px);
  }

  71% {
    -o-transform: translateY(12.425px) translateX(4.14831px);
  }

  72% {
    -o-transform: translateY(12.6px) translateX(4.23782px);
  }

  73% {
    -o-transform: translateY(12.775px) translateX(4.31314px);
  }

  74% {
    -o-transform: translateY(12.95px) translateX(4.37381px);
  }

  75% {
    -o-transform: translateY(13.125px) translateX(4.41942px);
  }

  76% {
    -o-transform: translateY(13.3px) translateX(4.44956px);
  }

  77% {
    -o-transform: translateY(13.475px) translateX(4.46386px);
  }

  78% {
    -o-transform: translateY(13.65px) translateX(4.46197px);
  }

  79% {
    -o-transform: translateY(13.825px) translateX(4.44358px);
  }

  80% {
    -o-transform: translateY(14px) translateX(4.40839px);
  }

  81% {
    -o-transform: translateY(14.175px) translateX(4.35615px);
  }

  82% {
    -o-transform: translateY(14.35px) translateX(4.28661px);
  }

  83% {
    -o-transform: translateY(14.525px) translateX(4.19959px);
  }

  84% {
    -o-transform: translateY(14.7px) translateX(4.09491px);
  }

  85% {
    -o-transform: translateY(14.875px) translateX(3.97242px);
  }

  86% {
    -o-transform: translateY(15.05px) translateX(3.83201px);
  }

  87% {
    -o-transform: translateY(15.225px) translateX(3.67362px);
  }

  88% {
    -o-transform: translateY(15.4px) translateX(3.49718px);
  }

  89% {
    -o-transform: translateY(15.575px) translateX(3.30269px);
  }

  90% {
    -o-transform: translateY(15.75px) translateX(3.09017px);
  }

  91% {
    -o-transform: translateY(15.925px) translateX(2.85966px);
  }

  92% {
    -o-transform: translateY(16.1px) translateX(2.61124px);
  }

  93% {
    -o-transform: translateY(16.275px) translateX(2.34504px);
  }

  94% {
    -o-transform: translateY(16.45px) translateX(2.06119px);
  }

  95% {
    -o-transform: translateY(16.625px) translateX(1.75989px);
  }

  96% {
    -o-transform: translateY(16.8px) translateX(1.44133px);
  }

  97% {
    -o-transform: translateY(16.975px) translateX(1.10577px);
  }

  98% {
    -o-transform: translateY(17.15px) translateX(0.75349px);
  }

  99% {
    -o-transform: translateY(17.325px) translateX(0.38478px);
  }

  100% {
    -o-transform: translateY(17.5px) translateX(0.0px);
  }
}

@keyframes snowFall2 {
  0% {
    transform: translateY(0px) translateX(0px);
  }

  1% {
    transform: translateY(0.175px) translateX(-0.38478px);
  }

  2% {
    transform: translateY(0.35px) translateX(-0.75349px);
  }

  3% {
    transform: translateY(0.525px) translateX(-1.10577px);
  }

  4% {
    transform: translateY(0.7px) translateX(-1.44133px);
  }

  5% {
    transform: translateY(0.875px) translateX(-1.75989px);
  }

  6% {
    transform: translateY(1.05px) translateX(-2.06119px);
  }

  7% {
    transform: translateY(1.225px) translateX(-2.34504px);
  }

  8% {
    transform: translateY(1.4px) translateX(-2.61124px);
  }

  9% {
    transform: translateY(1.575px) translateX(-2.85966px);
  }

  10% {
    transform: translateY(1.75px) translateX(-3.09017px);
  }

  11% {
    transform: translateY(1.925px) translateX(-3.30269px);
  }

  12% {
    transform: translateY(2.1px) translateX(-3.49718px);
  }

  13% {
    transform: translateY(2.275px) translateX(-3.67362px);
  }

  14% {
    transform: translateY(2.45px) translateX(-3.83201px);
  }

  15% {
    transform: translateY(2.625px) translateX(-3.97242px);
  }

  16% {
    transform: translateY(2.8px) translateX(-4.09491px);
  }

  17% {
    transform: translateY(2.975px) translateX(-4.19959px);
  }

  18% {
    transform: translateY(3.15px) translateX(-4.28661px);
  }

  19% {
    transform: translateY(3.325px) translateX(-4.35615px);
  }

  20% {
    transform: translateY(3.5px) translateX(-4.40839px);
  }

  21% {
    transform: translateY(3.675px) translateX(-4.44358px);
  }

  22% {
    transform: translateY(3.85px) translateX(-4.46197px);
  }

  23% {
    transform: translateY(4.025px) translateX(-4.46386px);
  }

  24% {
    transform: translateY(4.2px) translateX(-4.44956px);
  }

  25% {
    transform: translateY(4.375px) translateX(-4.41942px);
  }

  26% {
    transform: translateY(4.55px) translateX(-4.37381px);
  }

  27% {
    transform: translateY(4.725px) translateX(-4.31314px);
  }

  28% {
    transform: translateY(4.9px) translateX(-4.23782px);
  }

  29% {
    transform: translateY(5.075px) translateX(-4.14831px);
  }

  30% {
    transform: translateY(5.25px) translateX(-4.04508px);
  }

  31% {
    transform: translateY(5.425px) translateX(-3.92863px);
  }

  32% {
    transform: translateY(5.6px) translateX(-3.79948px);
  }

  33% {
    transform: translateY(5.775px) translateX(-3.65815px);
  }

  34% {
    transform: translateY(5.95px) translateX(-3.50523px);
  }

  35% {
    transform: translateY(6.125px) translateX(-3.34127px);
  }

  36% {
    transform: translateY(6.3px) translateX(-3.16689px);
  }

  37% {
    transform: translateY(6.475px) translateX(-2.9827px);
  }

  38% {
    transform: translateY(6.65px) translateX(-2.78933px);
  }

  39% {
    transform: translateY(6.825px) translateX(-2.58742px);
  }

  40% {
    transform: translateY(7px) translateX(-2.37764px);
  }

  41% {
    transform: translateY(7.175px) translateX(-2.16066px);
  }

  42% {
    transform: translateY(7.35px) translateX(-1.93717px);
  }

  43% {
    transform: translateY(7.525px) translateX(-1.70785px);
  }

  44% {
    transform: translateY(7.7px) translateX(-1.47343px);
  }

  45% {
    transform: translateY(7.875px) translateX(-1.23461px);
  }

  46% {
    transform: translateY(8.05px) translateX(-0.99211px);
  }

  47% {
    transform: translateY(8.225px) translateX(-0.74667px);
  }

  48% {
    transform: translateY(8.4px) translateX(-0.49901px);
  }

  49% {
    transform: translateY(8.575px) translateX(-0.24988px);
  }

  50% {
    transform: translateY(8.75px) translateX(0px);
  }

  51% {
    transform: translateY(8.925px) translateX(0.24988px);
  }

  52% {
    transform: translateY(9.1px) translateX(0.49901px);
  }

  53% {
    transform: translateY(9.275px) translateX(0.74667px);
  }

  54% {
    transform: translateY(9.45px) translateX(0.99211px);
  }

  55% {
    transform: translateY(9.625px) translateX(1.23461px);
  }

  56% {
    transform: translateY(9.8px) translateX(1.47343px);
  }

  57% {
    transform: translateY(9.975px) translateX(1.70785px);
  }

  58% {
    transform: translateY(10.15px) translateX(1.93717px);
  }

  59% {
    transform: translateY(10.325px) translateX(2.16066px);
  }

  60% {
    transform: translateY(10.5px) translateX(2.37764px);
  }

  61% {
    transform: translateY(10.675px) translateX(2.58742px);
  }

  62% {
    transform: translateY(10.85px) translateX(2.78933px);
  }

  63% {
    transform: translateY(11.025px) translateX(2.9827px);
  }

  64% {
    transform: translateY(11.2px) translateX(3.16689px);
  }

  65% {
    transform: translateY(11.375px) translateX(3.34127px);
  }

  66% {
    transform: translateY(11.55px) translateX(3.50523px);
  }

  67% {
    transform: translateY(11.725px) translateX(3.65815px);
  }

  68% {
    transform: translateY(11.9px) translateX(3.79948px);
  }

  69% {
    transform: translateY(12.075px) translateX(3.92863px);
  }

  70% {
    transform: translateY(12.25px) translateX(4.04508px);
  }

  71% {
    transform: translateY(12.425px) translateX(4.14831px);
  }

  72% {
    transform: translateY(12.6px) translateX(4.23782px);
  }

  73% {
    transform: translateY(12.775px) translateX(4.31314px);
  }

  74% {
    transform: translateY(12.95px) translateX(4.37381px);
  }

  75% {
    transform: translateY(13.125px) translateX(4.41942px);
  }

  76% {
    transform: translateY(13.3px) translateX(4.44956px);
  }

  77% {
    transform: translateY(13.475px) translateX(4.46386px);
  }

  78% {
    transform: translateY(13.65px) translateX(4.46197px);
  }

  79% {
    transform: translateY(13.825px) translateX(4.44358px);
  }

  80% {
    transform: translateY(14px) translateX(4.40839px);
  }

  81% {
    transform: translateY(14.175px) translateX(4.35615px);
  }

  82% {
    transform: translateY(14.35px) translateX(4.28661px);
  }

  83% {
    transform: translateY(14.525px) translateX(4.19959px);
  }

  84% {
    transform: translateY(14.7px) translateX(4.09491px);
  }

  85% {
    transform: translateY(14.875px) translateX(3.97242px);
  }

  86% {
    transform: translateY(15.05px) translateX(3.83201px);
  }

  87% {
    transform: translateY(15.225px) translateX(3.67362px);
  }

  88% {
    transform: translateY(15.4px) translateX(3.49718px);
  }

  89% {
    transform: translateY(15.575px) translateX(3.30269px);
  }

  90% {
    transform: translateY(15.75px) translateX(3.09017px);
  }

  91% {
    transform: translateY(15.925px) translateX(2.85966px);
  }

  92% {
    transform: translateY(16.1px) translateX(2.61124px);
  }

  93% {
    transform: translateY(16.275px) translateX(2.34504px);
  }

  94% {
    transform: translateY(16.45px) translateX(2.06119px);
  }

  95% {
    transform: translateY(16.625px) translateX(1.75989px);
  }

  96% {
    transform: translateY(16.8px) translateX(1.44133px);
  }

  97% {
    transform: translateY(16.975px) translateX(1.10577px);
  }

  98% {
    transform: translateY(17.15px) translateX(0.75349px);
  }

  99% {
    transform: translateY(17.325px) translateX(0.38478px);
  }

  100% {
    transform: translateY(17.5px) translateX(0.0px);
  }
}

/* Tornado */
@-webkit-keyframes translateTornado1 {
    0% {
      -webkit-transform: translateY(0);
    }

    25% {
      -webkit-transform: translateX(4px);
    }

    75% {
      -webkit-transform: translateX(-4px);
    }

    100% {
      -webkit-transform: translateX(0);
    }
  }

@-webkit-keyframes translateTornado2 {
  0% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateX(2px);
  }

  75% {
    -webkit-transform: translateX(-2px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes translateTornado3 {
  0% {
   -webkit-transform: translateY(0);
  }

  25% {
   -webkit-transform: translateX(8px);
  }

  75% {
   -webkit-transform: translateX(-8px);
  }

  100% {
   -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes translateTornado4 {
  0% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateX(6px);
  }

  75% {
    -webkit-transform: translateX(-6px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes translateTornado5 {
  0% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateX(10px);
  }

  75% {
    -webkit-transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-webkit-keyframes translateTornado6 {
  0% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateX(6px);
  }

  75% {
    -webkit-transform: translateX(-6px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes translateTornado1 {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateX(4px);
  }

  75% {
    -moz-transform: translateX(-4px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-moz-keyframes translateTornado2 {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateX(2px);
  }

  75% {
    -moz-transform: translateX(-2px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-moz-keyframes translateTornado3 {
  0% {
   -moz-transform: translateY(0);
  }

  25% {
   -moz-transform: translateX(8px);
  }

  75% {
   -moz-transform: translateX(-8px);
  }

  100% {
   -moz-transform: translateX(0);
  }
}

@-moz-keyframes translateTornado4 {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateX(6px);
  }

  75% {
    -moz-transform: translateX(-6px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-moz-keyframes translateTornado5 {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateX(10px);
  }

  75% {
    -moz-transform: translateX(-10px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-moz-keyframes translateTornado6 {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateX(6px);
  }

  75% {
    -moz-transform: translateX(-6px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes translateTornado1 {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateX(4px);
  }

  75% {
    -o-transform: translateX(-4px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@-o-keyframes translateTornado2 {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateX(2px);
  }

  75% {
    -o-transform: translateX(-2px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@-o-keyframes translateTornado3 {
  0% {
   -o-transform: translateY(0);
  }

  25% {
   -o-transform: translateX(8px);
  }

  75% {
   -o-transform: translateX(-8px);
  }

  100% {
   -o-transform: translateX(0);
  }
}

@-o-keyframes translateTornado4 {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateX(6px);
  }

  75% {
    -o-transform: translateX(-6px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@-o-keyframes translateTornado5 {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateX(10px);
  }

  75% {
    -o-transform: translateX(-10px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@-o-keyframes translateTornado6 {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateX(6px);
  }

  75% {
    -o-transform: translateX(-6px);
  }

  100% {
    -o-transform: translateX(0);
  }
}
@keyframes translateTornado1 {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateX(4px);
  }

  75% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes translateTornado2 {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateX(2px);
  }

  75% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes translateTornado3 {
  0% {
   transform: translateY(0);
  }

  25% {
   transform: translateX(8px);
  }

  75% {
   transform: translateX(-8px);
  }

  100% {
   transform: translateX(0);
  }
}

@keyframes translateTornado4 {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateX(6px);
  }

  75% {
    transform: translateX(-6px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes translateTornado5 {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes translateTornado6 {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateX(6px);
  }

  75% {
    transform: translateX(-6px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes translateFog {
  0% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateX(2.5px);
  }

  75% {
    -webkit-transform: translateX(-2.5px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes translateFog {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateX(2.5px);
  }

  75% {
    -moz-transform: translateX(-2.5px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes translateFog {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateX(2.5px);
  }

  75% {
    -o-transform: translateX(-2.5px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes translateFog {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateX(2.5px);
  }

  75% {
    transform: translateX(-2.5px);
  }

  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes fillOpacityFog {
  0% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
}

@-moz-keyframes fillOpacityFog {
  0% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
}

@-o-keyframes fillOpacityFog {
  0% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
}

@keyframes fillOpacityFog {
  0% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }

  50% {
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  100% {
    fill-opacity: 0.5;
    stroke-opacity: 0.5;
  }
}


@-webkit-keyframes translateSunrise {
  0% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-16.002px);
  }

  100% {
    -webkit-transform: translateY(-16.002px);
  }
}

@-moz-keyframes translateSunrise {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateY(-16.002px);
  }

  100% {
    -moz-transform: translateY(-16.002px);
  }
}

@-o-keyframes translateSunrise {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateY(-16.002px);
  }

  100% {
    -o-transform: translateY(-16.002px);
  }
}

@keyframes translateSunrise {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-16.002px);
  }

  100% {
    transform: translateY(-16.002px);
  }
}

@-webkit-keyframes translateSunset {
  0% {
    -webkit-transform: translateY(-16px);
  }

  25% {
    -webkit-transform: translateY(-4px);
  }

  100% {
    -webkit-transform: translateY(-4px);
  }
}

@-moz-keyframes translateSunset {
  0% {
    -moz-transform: translateY(-16px);
  }

  25% {
    -moz-transform: translateY(-4px);
  }

  100% {
    -moz-transform: translateY(-4px);
  }
}

@-o-keyframes translateSunset {
  0% {
    -o-transform: translateY(-16px);
  }

  25% {
    -o-transform: translateY(-4px);
  }

  100% {
    -o-transform: translateY(-4px);
  }
}

@keyframes translateSunset {
  0% {
    transform: translateY(-16px);
  }

  25% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(-4px);
  }
}

@-webkit-keyframes translateArrowDown {
  0% {
    -webkit-transform: translateY(2px);
  }

  100% {
    -webkit-transform: translateY(5px);
  }
}

@-moz-keyframes translateArrowDown {
  0% {
    -moz-transform: translateY(2px);
  }

  100% {
    -moz-transform: translateY(5px);
  }
}

@-o-keyframes translateArrowDown {
  0% {
    -o-transform: translateY(2px);
  }

  100% {
    -o-transform: translateY(5px);
  }
}

@keyframes translateArrowDown {
  0% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(5px);
  }
}

@-webkit-keyframes translateArrowUp {
  0% {
    -webkit-transform: translateY(-2.002px);
  }

  100% {
    -webkit-transform: translateY(-4.998px);
  }
}

@-moz-keyframes translateArrowUp {
  0% {
    -moz-transform: translateY(-2.002px);
  }

  100% {
    -moz-transform: translateY(-4.998px);
  }
}

@-o-keyframes translateArrowUp {
  0% {
    -o-transform: translateY(-2.002px);
  }

  100% {
    -o-transform: translateY(-4.998px);
  }
}

@keyframes translateArrowUp {
  0% {
    transform: translateY(-2.002px);
  }

  100% {
    transform: translateY(-4.998px);
  }
}

@-webkit-keyframes translateWind {
  0% {
    -webkit-transform: translateY(0);
  }

  25% {
    -webkit-transform: translateX(4.998px);
  }

  75% {
    -webkit-transform: translateX(-4.998px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes translateWind {
  0% {
    -moz-transform: translateY(0);
  }

  25% {
    -moz-transform: translateX(4.998px);
  }

  75% {
    -moz-transform: translateX(-4.998px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes translateWind {
  0% {
    -o-transform: translateY(0);
  }

  25% {
    -o-transform: translateX(4.998px);
  }

  75% {
    -o-transform: translateX(-4.998px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

@keyframes translateWind {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateX(4.998px);
  }

  75% {
    transform: translateX(-4.998px);
  }

  100% {
    transform: translateX(0);
  }
}
