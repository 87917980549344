//Scaffolding

html {
    font-size: $html-font-size;
}

@media(max-width:991px) {
    html {
        font-size: $html-font-size - 2!important;
    }
}
html,
body {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    min-height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


[tabindex='-1']:focus {
    outline: none;
}
select,
button,
textarea,
input {
    vertical-align: baseline;
}
html,
body {
    &[dir=rtl],
    &[dir=ltr] {
        unicode-bidi: embed
    }
}
bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override;
}
bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override;
}
.mat-card.settings-panel {
    position: fixed;
    bottom: 6px;
    right: 6px;
    width: 300px;
    z-index: 9;
}
body .mat-card {
    color: $font-color;
}


.height-full {
    height: 100%;
}