@use '~@angular/material' as mat;
.mat-tab-label {
	color: rgba(mat.get-color-from-palette($foreground, base), 0.87);
	align-items: center;
	display: flex;
	justify-content: center;
}

.app-dark .mat-tab-label {
	color: mat.get-color-from-palette($dark-foreground, base);
}